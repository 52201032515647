import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import User from "../../tools/userInfo";
import Swal from "../../tools/customAlert";
import { update } from "../../tools/zustandUpdate";
import { useGoogleLogin } from "@react-oauth/google";

import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

import Apple from "../../img/common/apple-ico.svg";
import Google from "../../img/common/google-ico.svg";
import Facebook from "../../img/common/facebook-ico.svg";

import { CustModal } from "./commonElements";
import createAxiosInstance from "../../api/axiosConfig";
import useAuthStore from "../../tools/useAuthStore";

interface LoginProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface InfoValue {
  id: string;
  pw: string;
}

const Login = ({ open, setOpen }: LoginProps) => {
  const { api, setToken, setTotalInfo } = User();
  const { setAccessToken } = useAuthStore();
  const axiosInstance = createAxiosInstance(api);
  const [infoValue, setInfoValue] = useState<InfoValue>({
    id: localStorage.getItem("id") || "",
    pw: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [findPage, setFindPage] = useState("login");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
    setFindPage("login");
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInfoValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const googleLogin = useGoogleLogin({
    scope: "email profile",
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: `${location.origin}/auth/google`,
    state: "/",
  });

  const loginAxios = () => {
    axiosInstance
      .post(`/login`, { email: infoValue.id, password: infoValue.pw })
      .then(({ data }) => {
        if (data.message === "success") {
          setToken(data.data.token);
          setAccessToken(data.data.token);
          setOpen(false);
          Swal.fire({
            title: "Login Success",
            icon: "success",
            allowOutsideClick: false,
            timer: 2000,
            didClose: () => {
              update(api, data.data.token, setTotalInfo);
              if (location.pathname === "/welcome") location.replace("/");
              if (location.pathname === "/cart") location.replace("/");
            },
          });
        }
      })
      .catch(({ response }) => {
        console.error(response); // 오류 디버깅용
        let errorMessage =
          response?.data?.message || "An unexpected error occurred.";

        if (response.data.message === "User Not Found") {
          Swal.fire({
            title: "User Not Found",
            icon: "error",
            allowOutsideClick: false,
            timer: 2000,
          });
        }

        Swal.fire({
          title: errorMessage,
          icon: "error",
          allowOutsideClick: false,
          timer: 2000,
        });
      });
  };

  const [visibleDiv, setVisibleDiv] = useState("logIn");
  const toggleDiv = () => {
    setVisibleDiv((prevVisibleDiv) =>
      prevVisibleDiv === "logIn" ? "findPW" : "logIn"
    );
  };

  return (
    <CustModal open={open} setOpen={setOpen} onClose={handleClose}>
      <div className="login">
        <div key={findPage === "pw" ? "forgot-password" : "login"}>
          {findPage !== "pw" ? (
            <>
              <h3 className="tit">Log in</h3>
              <span
                className="btn close material-symbols-rounded"
                onClick={handleClose}
              >
                close
              </span>
              <div className="login-wrap">
                <form>
                  <FormControl sx={{ width: 1 }}>
                    <TextField
                      onChange={handleChange}
                      id="idInput"
                      name="id"
                      value={infoValue.id}
                      label="Email"
                      type="email"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          loginAxios();
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ width: 1, mt: 2 }}>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      onChange={handleChange}
                      id="outlined-adornment-password"
                      autoComplete="current-password"
                      name="pw"
                      inputProps={{ spellCheck: "false" }}
                      value={infoValue.pw}
                      type={showPassword ? "text" : "password"}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          loginAxios();
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <span className="material-symbols-rounded">
                                visibility
                              </span>
                            ) : (
                              <span className="material-symbols-rounded">
                                visibility_off
                              </span>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <div
                    className="btn find-pw"
                    onClick={() => {
                      setFindPage("pw");
                    }}
                  >
                    Forgot Password?
                  </div>
                  <div
                    className="btn style01 lg login spc"
                    onClick={() => loginAxios()}
                  >
                    <span>Log in</span>
                  </div>
                </form>
                <div className="signup-area">
                  <p>Don't have an account?</p>
                  <div
                    className="btn"
                    onClick={() => {
                      setOpen(false);
                      location.href = "/signup";
                    }}
                  >
                    Signup!
                  </div>
                </div>
                <p className="or">or</p>
                <div className="sns-area">
                  <img
                    src={Google}
                    alt="Google login"
                    className="btn"
                    onClick={() => googleLogin()}
                  />
                  <img src={Facebook} alt="Faceboook login" />
                  <img src={Apple} alt="Apple login" />
                </div>
              </div>
            </>
          ) : (
            <div className="h-[600px]">
              <h4 className="tit">Forgot your Password?</h4>
              <span
                className="btn close material-symbols-rounded"
                onClick={handleClose}
              >
                close
              </span>
              <div className="find-pw-wrap">
                <TextField label="Email" fullWidth />
                <p>
                  Please provide the email address you used to register with. We
                  will send you an email at that address to allow you to reset
                  your password.
                </p>
                <div
                  className="btn style01 lg reset-pw spc"
                  onClick={() => {
                    Swal.fire({
                      title: "Password Reset Email Sent",
                      icon: "success",
                      timer: 1000,
                      didClose: () => {
                        setFindPage("login");
                      },
                      customClass: {
                        popup: "swal-custom-popup",
                      },
                    });
                  }}
                >
                  Reset Password
                </div>
                <div
                  className="btn back"
                  onClick={() => {
                    setFindPage("login");
                  }}
                >
                  Back to Login
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* 비번찾기 */}
    </CustModal>
  );
};

export default Login;

// h
