import { useState } from "react";

//  img
import Power from "../../img/common/USA_Power_lottery.png";
import Mega from "../../img/common/USA_Mega_lottery.png";
import Slider from "react-slick";

// style
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Subscription } from "./draws";

import { useLotto } from "../../App";
import amountMaker from "../../tools/amountMaker";
import ShowNums from "../common/showNums";
import User from "../../tools/userInfo";
import createAxiosInstance from "../../api/axiosConfig";
import Swal from "../../tools/customAlert";

interface DrawSubProps {
  data: Subscription[];
  getSubs: () => void;
}

const DrawSubs = ({ data, getSubs }: DrawSubProps) => {
  const { lottery } = useLotto();
  const { api, token } = User();
  const axiosInstance = createAxiosInstance(api);

  const matchPriceList = data.map((item) => {
    return {
      ...item,
      lottery: lottery.filter((lot) => lot.type === item.productCode),
    };
  });

  const errorTextMatcher = (error: string) => {
    switch (error) {
      case "UR":
        return "Account Suspended";
        break;
      case "PF":
        return "Payment Failed";
        break;
      case "SE":
        return "System Error";
        break;
      case "IB":
        return "Insufficient Balance";
        break;
      case "ES":
        return "Limit Exceeded";
        break;
      case "OT":
        return "Otehr Error";
        break;
    }
  };

  // Slick slide
  // Slick slide
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  // Slick slide
  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr next" onClick={onClick}>
        <span className="material-symbols-rounded">arrow_forward_ios</span>
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr prev" onClick={onClick}>
        <span
          className="material-symbols-rounded"
          style={{ marginRight: "-6px" }}
        >
          arrow_back_ios
        </span>
      </div>
    );
  };

  const SliderSettings = {
    // dots: true,
    // dotsClass: "custom-dots",
    arrow: true,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    className: "syndicate-list",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768, // 화면 크기 반응형
        settings: {
          slidesToShow: 1, // 보여줄 슬라이드 개수
        },
      },
    ],
  };

  const tagMatcher = (code: string) => {
    switch (code) {
      case "GUA":
        return <p className="tag guaranteed">Guaranteed</p>;
        break;
      case "PIC":
        return <p className="tag one-less">Pic 1 Less</p>;
        break;
      case "ST6":
        return <p className="tag system">System 6</p>;
        break;
      case "ST7":
        return <p className="tag system">System 7</p>;
        break;
      case "ST8":
        return <p className="tag system">System 8</p>;
        break;
      case "ST9":
        return <p className="tag system">System 9</p>;
        break;
      case "STX":
        return <p className="tag system">System 10</p>;
        break;
      default:
        return <p className="tag syndicate">Standard</p>;
    }
  };

  const hanldeSubPatcher = (idx: number, option: string) => {
    const data = { status: option };

    if (option === "C") {
      Swal.fire({
        icon: "question",
        title: "Are you sure you want to cancel your subscription?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          axiosInstance
            .patch(`/subscriptions/${idx}`, data)
            .then((res) => {
              getSubs();
              window.location.reload();
            })
            .catch((error) =>
              Swal.fire({
                icon: "error",
                title: error.response.data?.message,
                didClose: () => window.location.reload(),
              })
            );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          return;
        }
      });
    } else {
      axiosInstance
        .patch(`/subscriptions/${idx}`, data)
        .then((res) => getSubs())
        .catch((error) =>
          Swal.fire({
            icon: "error",
            title: error.response.data?.message,
          })
        );
    }
  };

  const hasProblen = data.filter((it) => it.reason !== null);

  return (
    <div className="draw-syndicate-wrap">
      <div className="tit-wrap">
        <h4 className="tit">
          Subscription List
          {hasProblen.length >= 1 && (
            <span className="issue">( has Issue {hasProblen.length} )</span>
          )}
        </h4>

        <p>{data.length} / 5</p>
      </div>

      <Slider {...SliderSettings}>
        {matchPriceList.map((it, idx) => {
          const reward = amountMaker(it.lottery[0].jackpot);
          const code = it.productCode;
          const sysCode = it.systemCode;
          return (
            <div
              className={it.reason !== null ? "item issue" : "item"}
              key={idx}
            >
              <div className="name-tag-wrap">
                <p className="name">
                  #{idx + 1} &nbsp;
                  {it.productName}
                </p>
                <div className="tag-wrap">
                  <p className="tag subscription">subscription</p>
                  {it.multiplier === "Y" && (
                    <p className="tag multiplier">Multiplier</p>
                  )}
                  {tagMatcher(it.systemCode)}
                </div>
              </div>

              <div className="count-wrap">
                <img
                  src={it.productCode === "PB" ? Power : Mega}
                  alt={`${
                    it.productCode === "PB" ? "Power Logo" : "Mega Logo"
                  }`}
                />
                <div className="txt-wrap">
                  {/* Error 표시 부분 */}
                  {it.reason !== null && (
                    <p className="error">{errorTextMatcher(it.reason)}</p>
                  )}

                  <p className="prize">
                    {reward.mark} {reward.amount} {reward.unit}
                  </p>
                  <p className="count">
                    <span className="tit">Count : </span>
                    <span className="remain">{it.cycle} </span>/ {it.draws}
                  </p>
                  <p className="type">
                    {it.draws === 999
                      ? "Stop at Jackpot"
                      : "Rolling Subscription"}{" "}
                    &nbsp;
                    <span className="start-date">
                      ({it.createdAt.split(" ")[0]}~)
                    </span>
                  </p>
                </div>
              </div>
              {/* 차후 수정 부분 ( 번호 확인 ) */}
              {/* {it.nums.map((it, idx) => (
                  <div key={idx}>
                    <ShowNums nums={it.num} code={code} sysCode={sysCode} />
                  </div>
                ))} */}

              <div className="btn-wrap">
                {/* pause 됐을 때는 Restore 버튼으로 변경 */}
                <div
                  className="btn style01 sm"
                  onClick={() => hanldeSubPatcher(it.idx, "C")}
                >
                  Cancel
                </div>
                {/* pause 됐을 때는 Restore 버튼으로 변경 */}
                {it.reason === null ? (
                  <div
                    className="btn style01 sm"
                    onClick={() => hanldeSubPatcher(it.idx, "S")}
                  >
                    Pause
                  </div>
                ) : (
                  <div
                    className="btn style01 sm"
                    onClick={() => hanldeSubPatcher(it.idx, "R")}
                  >
                    Restore
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default DrawSubs;
