// tools
import { useEffect } from "react";

// constants
import User from "../tools/userInfo";
import { useLotto } from "../App";

// ui
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Lottie from "lottie-react";

// comp
import Login from "../components/common/login";

// img
import Shine from "../img/sub/shine.json";
import {
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface Info {
  email: string;
  password: string;
  gender: string;
  firstName: string;
  lastName: string;
  phone: string;
  birth: string;
  unit: string;
  number: string;
  post: string;
  address: string;
  city: string;
  country: string;
  notifyEmail: string;
  notifySms: string;
  notifyPush: string;
  provider: string;
}

interface Props {
  info: Info;
}

const SignUpComplete = ({ info }: Props) => {
  const { open, setOpen } = useLotto();

  const { token } = User();

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollUp();
    if (token) {
      location.replace("/");
    }
  }, []);

  return (
    <div className="signup-wrap complete">
      <div className="sub-top-area">
        <Lottie animationData={Shine} className="signup-shine" loop={true} />
        <span className="material-symbols-rounded">check_circle</span>
        <h3 className="tit">Success !</h3>
        <p className="sub-tit">
          Congratulations! <br />
          Your Account has been created.
        </p>
      </div>

      <form>
        <h2>Your informations</h2>

        <div className="input-wrap">
          <label htmlFor="">Email</label>
          <p>{info.email}</p>
        </div>
        <div className="input-wrap">
          <label htmlFor="">Gender</label>
          <p>{info.gender}</p>
        </div>

        <div className="input-wrap">
          <label htmlFor="">Name</label>
          <p>{info.firstName}</p>
          <p>{info.lastName}</p>
        </div>

        <div className="input-wrap">
          <label htmlFor="">Phone</label>
          <p>{info.phone}</p>
        </div>
        <div className="input-label-wrap">
          <label htmlFor="">Date of Birth</label>
          <div className="input-wrap">
            <p>{info.birth}</p>
          </div>
        </div>
        <div className="input-label-wrap">
          <label htmlFor="">Address</label>
          <div className="input-wrap">
            <p>{info.unit}</p>
            <p>{info.number}</p>
            <p>{info.number}</p>
          </div>
          <div className="input-wrap">
            <p>{info.address}</p>
          </div>
          <div className="input-wrap">
            <p>{info.city}</p>
            <p> {info.country}</p>
          </div>
        </div>
        <div className="input-label-wrap">
          <label htmlFor="">Notifications</label>
          <div className="input-wrap">
            <FormControlLabel
              control={<Checkbox checked={info.notifySms === "R"} />}
              label="SMS Notifications"
              disabled
            />
            <FormControlLabel
              control={<Checkbox checked={info.notifyEmail === "R"} />}
              label="Email Notifications"
              disabled
            />
            <FormControlLabel
              control={<Checkbox checked={info.notifyPush === "R"} />}
              label="App Push"
              disabled
            />
          </div>
        </div>
      </form>
      <div className="btn-wrap">
        <div className="btn style01 md" onClick={() => (location.href = "/")}>
          <span className="material-symbols-rounded">chevron_left</span>
          Home
        </div>
        <div className="btn style01 md" onClick={() => setOpen(true)}>
          Log In
          <span className="material-symbols-rounded">chevron_right</span>
        </div>
      </div>
    </div>
  );
};

export default SignUpComplete;
