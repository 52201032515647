// tools
import { Dispatch, SetStateAction, useEffect, useState } from "react";

// ui
import { Checkbox, FormControlLabel, Radio, TextField } from "@mui/material";

// img
import Mastercard from "../../img/sub/mastercard-ico.svg";
import Visa from "../../img/sub/visa-ico.svg";
import PayPal from "../../img/sub/paypal-ico.svg";
import Paysafe from "../../img/sub/paysafe-ico.svg";
import { NewCard } from "../../pages/mypage";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

const PayMethod = ({ setOpen, open }: ModalContent) => {
  const methods = ["card", "addCard", "paypal", "paysafe"];
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [picked, setPicked] = useState(false);
  const [newCard, setNewCard] = useState<NewCard>({
    type: "",
    number: null,
    name: "",
    exp: "",
    cvv: null,
    check: "N",
  });

  const handleExpand = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? prevIndex : index));
  };

  const handleCardData = (key: string, value: string | number) => {
    setNewCard((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (!open) {
      setExpandedIndex(0);
      setNewCard({
        type: "",
        number: null,
        name: "",
        exp: "",
        cvv: null,
        check: "N",
      });
    }
  }, [open]);

  return (
    <div className="pay-method">
      <div className="modal-tit-wrap">
        <h3 className="tit">Payment Method</h3>
        <span
          className="btn material-symbols-rounded"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>
      <div className="modal-cont-wrap">
        <p className="subtit">Why Not Secure Your Payment?</p>
        <p className="subtxt">
          Securely store your card and enjoy faster checkouts. You can change or
          remove your card information whenever you choose.
        </p>

        <div className="payment-method">
          {/* 등록된 카드가 있을때 */}
          {methods.map((method, index) => {
            return (
              <div
                key={index}
                className={`item  ${expandedIndex === index ? "open" : ""}`}
                onClick={() => handleExpand(index)}
              >
                {/* 등록된 카드 있을 때 */}
                {method === "card" && (
                  <div className="card">
                    <div className="top-area">
                      <Radio
                        value="a"
                        name="radio-buttons"
                        checked={expandedIndex === index}
                        onClick={() => setExpandedIndex(0)}
                        readOnly
                      />
                      <img src={Visa} alt="Visa" className="icon" />
                      <p className="txt">5217 29xx xxxx x084</p>
                      <p className="expired">EXPIRED</p>
                    </div>
                    {expandedIndex === index && (
                      <div className="bottom-area">
                        <div className="card-info">
                          <div className="info">
                            <p className="tit">CARD HOLDER</p>
                            <p className="txt">KIM GU SONG</p>
                          </div>
                          <div className="info">
                            <p className="tit">Expries</p>
                            <p className="txt">11/24</p>
                          </div>
                        </div>
                        <span className="material-symbols-rounded btn">
                          delete
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {/* 신규카드추가 */}
                {method === "addCard" && (
                  <div className="add-card">
                    <div className="top-area">
                      <Radio
                        value="a"
                        name="radio-buttons"
                        checked={expandedIndex === index}
                        onClick={() => setExpandedIndex(1)}
                        readOnly
                      />
                      <span className="material-symbols-rounded icon">
                        add_card
                      </span>
                      <p className="txt">Add New Card</p>
                    </div>
                    {expandedIndex === index && (
                      <div className="bottom-area">
                        <div className="provider-wrap">
                          <div
                            className={`provider ${
                              newCard.type === "VISA" && "picked"
                            }`}
                            onClick={() => handleCardData("type", "VISA")}
                          >
                            <img src={Visa} alt="Visa" />
                          </div>
                          <div
                            className={`provider ${
                              newCard.type === "MASTER" && "picked"
                            }`}
                            onClick={() => handleCardData("type", "MASTER")}
                          >
                            <img src={Mastercard} alt="Mastercard" />
                          </div>
                        </div>
                        <form>
                          <div className="input-wrap">
                            <TextField
                              label="Card Number"
                              fullWidth
                              name="number"
                              value={newCard.number}
                              onChange={({ target }) => {
                                const { value } = target;
                                const formattedValue = value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                handleCardData("number", formattedValue);
                              }}
                            />
                          </div>

                          <div className="input-wrap">
                            <TextField
                              label="Card Holder Name"
                              placeholder="Name as it appears on your card"
                              fullWidth
                              name="name"
                              value={newCard.name}
                              onChange={({ target }) =>
                                handleCardData("name", target.value)
                              }
                            />
                          </div>

                          <div className="input-wrap">
                            <TextField
                              label="Expiration Date"
                              placeholder="MM/YYYY"
                              fullWidth
                              name="exp"
                              value={newCard.exp}
                              onChange={({ target }) => {
                                let { value } = target;
                                // 숫자와 '/'만 허용
                                value = value.replace(/[^0-9/]/g, "");

                                // 길이 제한 (7자리 MM/YYYY)
                                if (value.length > 7) return;

                                // 자동으로 '/' 추가
                                if (
                                  value.length === 2 &&
                                  !value.includes("/")
                                ) {
                                  value += "/";
                                }

                                handleCardData("exp", value);
                              }}
                            />

                            <TextField
                              label="CVV"
                              placeholder="123"
                              fullWidth
                              name="cvv"
                              value={newCard.cvv}
                              onChange={({ target }) => {
                                const { value } = target;
                                const formattedValue = value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                handleCardData("cvv", formattedValue);
                              }}
                            />
                          </div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newCard.check === "Y"}
                                onClick={() => {
                                  if (newCard.check === "N") {
                                    setNewCard((prev) => ({
                                      ...prev,
                                      check: "Y",
                                    }));
                                  } else {
                                    setNewCard((prev) => ({
                                      ...prev,
                                      check: "N",
                                    }));
                                  }
                                }}
                              />
                            }
                            label="Securely stroe for futrue use"
                          />
                        </form>
                        <div className="btn style01 md spc">Add $ 1163.00</div>
                      </div>
                    )}
                  </div>
                )}

                {/* 페이팔 */}
                {method === "paypal" && (
                  <div className="paypal">
                    <div className="top-area">
                      <Radio
                        value="a"
                        name="radio-buttons"
                        checked={expandedIndex === index}
                        onClick={() => setExpandedIndex(2)}
                        readOnly
                      />
                      <img src={PayPal} alt="PayPal" className="icon" />
                      <p className="txt">Add PayPal</p>
                    </div>
                    {expandedIndex === index && (
                      <div className="bottom-area">
                        <div className="btn style01 md">
                          <img
                            src={PayPal}
                            alt="PayPal"
                            style={{ width: "100px" }}
                          />
                        </div>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Securely stroe for futrue use"
                        />
                      </div>
                    )}
                  </div>
                )}

                {/* 페이세이프티 */}
                {method === "paysafe" && (
                  <div className="paysafe">
                    <div className="top-area">
                      <Radio
                        value="a"
                        name="radio-buttons"
                        checked={expandedIndex === index}
                        onClick={() => setExpandedIndex(3)}
                        readOnly
                      />
                      <img src={Paysafe} alt="Paysafe" className="icon" />
                      <p className="txt">Add Paysafecard</p>
                    </div>
                    {expandedIndex === index && (
                      <div className="bottom-area">
                        <div className="btn style01 md">
                          <img
                            src={Paysafe}
                            alt="Paysafe"
                            style={{ width: "100px" }}
                          />
                        </div>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Securely stroe for futrue use"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="notice-area">
          <p>
            Rest assured that your payment details are securely encrypted. You
            can manage, update, or remove your card details anytime in your
            account settings.
          </p>
        </div>
      </div>
      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
          >
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayMethod;
