import axios from "axios";
import { create } from "zustand";
import User from "./userInfo";

interface AuthState {
  accessToken: string;
  isLoading: boolean;
  setAccessToken: (token: string) => void;
  clearAccessToken: () => void;
}

const useAuthStore = create<AuthState>((set, get) => ({
  accessToken: "",
  isLoading: true,
  setAccessToken: (token: string) => {
    set({ accessToken: token });
    // User의 setToken 호출
    const userStore = User.getState(); // User 상태 가져오기
    userStore.setToken(token); // setToken 호출
  },
  clearAccessToken: () => set({ accessToken: "", isLoading: false }),
}));

export default useAuthStore;
