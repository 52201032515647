// tools
import { useState, useEffect } from "react";
import User from "../../tools/userInfo";

// ui
import { CircularProgress } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import useMediaQuery from "@mui/material/useMediaQuery";

// type
import type { Pie } from "../../pages/stat";
import createAxiosInstance from "../../api/axiosConfig";

interface Combination {
  id: number;
  value: number;
  label: string;
}

interface List {
  drawnum: number;
  sequenceCount: number;
  sequence: string | null;
  numbers: string;
  playdate: string;
}

const Consecutive = ({ type, month }: Pie) => {
  const { api } = User();
  const axiosInstance = createAxiosInstance(api);
  const [combination, setCombination] = useState<Combination[]>([
    { id: 1, value: 0, label: "No Consecutive" },
    {
      id: 2,
      value: 0,
      label: "2",
    },
    {
      id: 3,
      value: 0,
      label: "3",
    },
    { id: 4, value: 0, label: "4" },
    {
      id: 5,
      value: 0,
      label: "5",
    },
    {
      id: 6,
      value: 0,
      label: "6",
    },
  ]);
  const [list, setList] = useState<List[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const dataAxios = () => {
    setLoading(true);
    const Type = type === "mega" ? "mm" : "pb";
    const today = new Date();
    const startDate = new Date(today);
    startDate.setMonth(today.getMonth() - Number(month));

    const toMonth =
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1;
    const sMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1;

    const start = `${startDate.getFullYear()}-${sMonth}-${startDate.getDate()}`;
    const end = `${today.getFullYear()}-${toMonth}-${today.getDate()}`;

    axiosInstance
      .get(`/statistics/sequential?type=${Type}&sDate=${start}&eDate=${end}`)
      .then(({ data }) => {
        const copiedCombi = [...combination];
        for (let i = 0; i < data.data.combination.length; i++) {
          const item = copiedCombi[i];
          item.value = data.data.combination[i];
          copiedCombi[i] = { ...item };
        }
        setCombination(copiedCombi);
        setTotal(data.data.totalCount);
        setList(data.data.data);

        setTimeout(() => setLoading(false), 500);
      });
  };

  useEffect(() => dataAxios(), [month, type]);

  const matches = useMediaQuery("(max-width:480px)");
  return (
    <div className="consecutive">
      {/* Chart-L */}
      <div className="item">
        <h4 className="tit">Consecutive Numbers Analysis</h4>
        <div className="table-wrap">
          <table>
            <tr>
              <th>Consecutive</th>
              <th>Ratio Graph</th>
              <th>Frequency</th>
              <th>Winning Probability</th>
            </tr>

            {combination.map((item) => (
              <tr key={item.id}>
                <td>{item.label}</td>
                <td>
                  <div
                    className="bar"
                    style={{
                      width: `${((item.value / total) * 100).toFixed(1)}%`,
                    }}
                  />
                </td>
                <td>{item.value} times</td>
                <td>{((item.value / total) * 100).toFixed(1)}%</td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      <div className="item">
        <h4 className="tit">Pie Chart of Consecutive Numbers Combination</h4>
        <PieChart
          margin={
            matches
              ? { top: 0, bottom: 150, left: 0, right: 0 }
              : { top: 0, bottom: 0, left: 0, right: 250 }
          }
          series={[
            {
              data: combination,
            },
          ]}
          height={350}
          slotProps={
            matches
              ? {
                  legend: {
                    position: { vertical: "bottom", horizontal: "left" },
                    padding: 0,
                    itemMarkWidth: 20,
                    itemMarkHeight: 2,
                    markGap: 3,
                    itemGap: 1,
                  },
                }
              : {
                  legend: {
                    position: { vertical: "middle", horizontal: "right" },
                    padding: 0,
                    itemMarkWidth: 20,
                    itemMarkHeight: 2,
                    markGap: 3,
                    itemGap: 2,
                    labelStyle: {
                      fill: "white",
                    },
                  },
                }
          }
        />
      </div>

      <div className="item">
        <h4 className="tit">Consecutive Number Analysis Table</h4>
        <div className="table-wrap">
          <table>
            <tr>
              <th>Draw Number</th>
              <th>Winning Numbers</th>
              {combination.map((item) => (
                <th key={item.id}>{item.label}</th>
              ))}
            </tr>

            {loading ? (
              <tr>
                <td colSpan={8}>
                  <CircularProgress />
                </td>
              </tr>
            ) : (
              list.map((item) => {
                const numbers = item.sequence ? item.sequence.split(",") : [];
                const ball = item.numbers.split(",");

                return (
                  <tr key={item.drawnum}>
                    <td>#{item.drawnum}</td>
                    <td>
                      <div className="ball-wrap">
                        {ball.map((num, index) =>
                          index === ball.length - 1 ? (
                            <div
                              key={`${item.drawnum}_${num}_${index}`}
                              className={`ball ${
                                type === "mega" ? "mega" : "power"
                              }`}
                            >
                              {num}
                            </div>
                          ) : (
                            <div
                              key={`${item.drawnum}_${num}_${index}`}
                              className="ball"
                            >
                              {num}
                            </div>
                          )
                        )}
                      </div>
                    </td>

                    {combination.map((com, index) =>
                      index === item.sequenceCount - 1 ? (
                        <td key={com.id}>
                          <span className="material-symbols-rounded">
                            check
                          </span>
                        </td>
                      ) : (
                        <td key={com.id} />
                      )
                    )}
                  </tr>
                );
              })
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Consecutive;
