import { useState, useEffect, useRef } from "react";
import User from "../../tools/userInfo";
import { GameMenuInfo } from "../../pages/stat";
import BarGame from "./barGame";
import Swal from "../../tools/customAlert";
import { customAlphabet } from "nanoid";
import { RandomNumber } from "../../tools/random";
import createAxiosInstance from "../../api/axiosConfig";
import { BallSet } from "../../types/ball";

// types
interface Nums {
  idx: number;
  method: string;
  num: string;
}

interface GameData {
  idx: number;
  productCode: string;
  methodCode: string;
  systemCode: string;
  ticketQty: number;
  nums: Nums[];
}

interface BarChart {
  type: string;
  menu: string;
  month: string;
  tab: string;
  gameType: GameMenuInfo[];
}

interface Ball {
  number: number;
  count: number;
}

interface Data {
  total: number;
  white: Ball[];
  special: Ball[];
}

/** RandomNums */
function generateUniqueNumbers(
  count: number,
  maxNumber: number,
  excluded: Set<string> = new Set()
): string[] {
  const usedNumbers = new Set(excluded);
  const result: string[] = [];
  while (result.length < count) {
    const randomNum = RandomNumber(maxNumber).toString();
    if (!usedNumbers.has(randomNum)) {
      result.push(randomNum);
      usedNumbers.add(randomNum);
    }
  }
  return result;
}

/** Bll counter */
function getWhiteBallCount(code: string | undefined): number {
  if (!code) return 5;

  // PIC=4개 + 마지막 'G'
  if (code === "PIC") return 4;
  // GUA=5개 + special='G'
  if (code === "GUA") return 5;
  // 예시: STX=10개, ST9=9개, ST6=6개 ...
  if (code === "STX") return 10;
  if (code === "ST9") return 9;
  if (code === "ST8") return 8;
  if (code === "ST7") return 7;
  if (code === "ST6") return 6;

  // 그 외(Standard, CHN 등) 5개
  return 5;
}

// 3) 전체 랜덤(전부 비웠거나 전부 찼을 때)
function makeAllRandom(
  selectMode: GameMenuInfo | undefined,
  type: string
): { wballs: string[]; sball: string } {
  const whiteCount = getWhiteBallCount(selectMode?.code);
  let newWhites = generateUniqueNumbers(whiteCount, BallSet[type].white);

  // 기본 스페셜: 1개 랜덤
  let newSpecial = RandomNumber(BallSet[type].special).toString();

  // PIC
  if (selectMode?.code === "PIC") {
    // PIC=4개 + 마지막 'G'
    newWhites.push("G");
    // 스페셜은 랜덤(또는 0)
    newSpecial = RandomNumber(BallSet[type].special).toString();
  }

  // GUA => white=5, special='G'
  if (selectMode?.code === "GUA") {
    newSpecial = "G";
  }

  // STX, ST9, ST6 => whiteCount개 + 특별공 1개 랜덤
  if (selectMode?.code?.startsWith("ST")) {
    newSpecial = RandomNumber(BallSet[type].special).toString();
  }

  return { wballs: newWhites, sball: newSpecial };
}

// 4) 부분 랜덤(선택된 건 유지 0만 랜덤)
function makePartialRandom(
  selectWball: string[] | undefined,
  selectSball: string | undefined | null,
  selectMode: GameMenuInfo | undefined,
  type: string
): { wballs: string[]; sball: string } {
  // 1) whiteCount
  const whiteCount = getWhiteBallCount(selectMode?.code);

  // 2) 기존 white 배열 없으면 일단 0으로 채운 기본 배열 생성
  let oldW = selectWball ? [...selectWball] : Array(whiteCount).fill("0");

  // PIC
  if (selectMode?.code === "PIC") {
    if (oldW.length < 5) {
      oldW = [...Array(4).fill("0"), "G"];
    }
  } else {
    // STX, ST9, ST6 => whiteCount만큼
    if (oldW.length < whiteCount) {
      oldW = [...oldW, ...Array(whiteCount - oldW.length).fill("0")];
    }
  }

  // 3) 이미 선택된 번호들(0, G 제외)을 중복 방지 set에 넣음
  const chosen = oldW.filter((val) => val !== "0" && val !== "G");
  const usedSet = new Set<string>(chosen);

  // 4) '0'만 새 번호 채우기
  const newW = oldW.map((ball) => {
    if (ball === "0") {
      const [unique] = generateUniqueNumbers(1, BallSet[type].white, usedSet);
      usedSet.add(unique);
      return unique;
    }
    return ball; // 이미 선택된 자리 or 'G'
  });

  // 5) 스페셜 처리
  let newS = selectSball || "0";

  // GUA => 스페셜='G'
  if (selectMode?.code === "GUA") {
    newS = "G";
  } else if (newS === "0") {
    // 아직 스페셜 안 뽑혔으면 랜덤
    newS = RandomNumber(BallSet[type].special).toString();
  }

  return { wballs: newW, sball: newS };
}

const Bar = ({ type, menu, month, tab, gameType }: BarChart) => {
  const { api } = User();

  const axiosInstance = createAxiosInstance(api);
  const BallType = type === "power" ? "Power Ball" : "Mega Ball";

  // for game
  const [selectWball, setSelectWball] = useState<string[] | undefined>();
  const [selectSball, setSelectSball] = useState<string | undefined | null>();

  const [selectMode, setSelectMode] = useState<GameMenuInfo | undefined>();
  const [selectedGame, setSelectedGame] = useState<GameMenuInfo | null>(null);
  const [gameList, setGameList] = useState<GameData[]>([]);
  const [isActive, setIsActive] = useState<number>(0);
  const [isSlide, setIsSlide] = useState<boolean>(false);
  const [isAuto, setIsAuto] = useState<boolean>(false);
  const [reset, setReset] = useState<string>("0,0,0,0,0,0");

  const wBallRule = selectMode?.regular;
  const sBallRule = selectMode?.special;
  const gameLength = gameList.length;

  const [list, setList] = useState<Data>({
    total: 0,
    white: [],
    special: [],
  });
  const [compareList, setCompareList] = useState<Data>({
    total: 0,
    white: [],
    special: [],
  });

  const auto = () => {
    const { wballs, sball } = makeAllRandom(selectMode, type);
    setSelectWball(wballs);
    setSelectSball(sball);
  };

  /** 자동 선택 */
  const quickPlay = (target: number): void => {
    if (isAuto) return;

    const updatedGameList = gameList.map((it) => {
      if (it.idx === target) {
        return {
          ...it,
          nums: it.nums.map((numItem) => ({ ...numItem, method: "A" })),
        };
      }
      return it;
    });
    setGameList(updatedGameList);

    // 비어있거나 풀게임 찾기

    // GUA
    const isGuaEmptyPattern =
      selectMode?.code === "GUA" &&
      selectWball?.length === 5 &&
      selectWball.every((ball) => ball === "0") &&
      selectSball === "G";

    // PIC
    const isPicEmptyPattern =
      selectMode?.code === "PIC" &&
      selectWball?.length === 5 &&
      selectWball.slice(0, 4).every((ball) => ball === "0") &&
      selectWball[4] === "G" &&
      selectSball === "0";

    // 나머지 게임
    const emptyWball = selectWball?.every((ball) => ball === "0");
    const fullWball = selectWball?.every((ball) => ball !== "0");
    const emptySball = !selectSball || selectSball === "0";
    const fullSball = selectSball && selectSball !== "0";

    // Empty Game
    const allEmpty =
      (emptyWball && emptySball) || isPicEmptyPattern || isGuaEmptyPattern;

    // Full GAME
    const allFull = fullWball && fullSball;

    if (allEmpty || allFull) {
      const autoIn = setInterval(() => {
        setIsAuto(true);
        auto();
      }, 100);
      setTimeout(() => {
        setIsAuto(false);
        clearInterval(autoIn);
      }, 1000);
    } else {
      const { wballs, sball } = makePartialRandom(
        selectWball,
        selectSball,
        selectMode,
        type
      );
      setSelectWball(wballs);
      setSelectSball(sball);
    }
  };

  /** 게임 삭제 ( 현재 미사용 ) */
  const handleDelete = (target: number) => {
    if (isAuto) return;
    const updateList = gameList.filter((it) => it.idx !== target);
    setGameList(updateList);
  };

  /** 번호 비우기 */
  const handleClear = (target: number) => {
    if (isAuto) return;
    const resetNum = reset.split(",");
    const sBall = resetNum.pop();

    const updatedList = gameList.map((it) => {
      if (it.idx === target) {
        return {
          ...it,
          nums: it.nums.map((numObj) => ({
            ...numObj,
            num: reset,
          })),
        };
      }
      return it;
    });

    setGameList(updatedList);
    setSelectWball(resetNum);
    setSelectSball(sBall);
  };

  /** 번호 수동 선택 */
  const handlePick = (number: string, isSpecial: boolean) => {
    if (!gameList[isActive]) return;
    if (isSpecial) {
      setSelectSball((prevSball) => (prevSball === number ? "0" : number));
    } else {
      setSelectWball((prevWballs) => {
        if (prevWballs) {
          const existingIndex = prevWballs.indexOf(number);
          if (existingIndex !== -1) {
            // 이미 들어있는 번호 클릭 => 0으로 돌림
            const updatedWballs = [...prevWballs];
            updatedWballs[existingIndex] = "0";
            return updatedWballs;
          }
          // 0 있는 자리 중 첫 번째를 number로 교체
          const zeroIndex = prevWballs.indexOf("0");
          if (zeroIndex !== -1) {
            const updatedWballs = [...prevWballs];
            updatedWballs[zeroIndex] = number;
            return updatedWballs;
          }
          return prevWballs;
        }
        // prevWballs가 없으면 새 배열
        return [number];
      });
    }
  };

  useEffect(() => {
    if (wBallRule !== undefined) {
      if (selectMode?.code === "PIC") {
        const newWballs = Array(wBallRule).fill("0");
        newWballs.push("G");
        setSelectWball(newWballs);
      } else {
        const newWballs = Array(wBallRule).fill("0");
        setSelectWball(newWballs);
      }
    }

    if (sBallRule !== undefined) {
      const newSball = sBallRule === 1 ? "0" : "G";
      setSelectSball(newSball);
    }
  }, [wBallRule, sBallRule]);

  useEffect(() => {
    if (!gameList[isActive]) return;
    setGameList((prevGameList) => {
      const updatedGameList = [...prevGameList];
      const updatedNums = [...(selectWball || []), selectSball || "0"];
      updatedGameList[isActive].nums[0].num = updatedNums.join(",");
      return updatedGameList;
    });
  }, [selectWball, selectSball]);

  useEffect(() => {
    if (gameList[isActive]) {
      const balls = gameList[isActive].nums[0].num.split(",");
      const w = balls.slice(0, -1);
      const s = balls.pop();
      setSelectWball(w);
      setSelectSball(s);
    }
  }, [isActive]);

  /** 시스템 변경시 */
  const handleSelectChange = (selected: GameMenuInfo) => {
    Swal.fire({
      title: "This will reset the current games.",
      text: "Proceed with change?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const randomKeyNum = customAlphabet("1234567890", 12);
        const newNums =
          selected.code === "GUA"
            ? "0,0,0,0,0,G"
            : selected.code === "PIC"
            ? "0,0,0,0,G,0"
            : selected.code === "CHN"
            ? "0,0,0,0,0,0"
            : selected.code.includes("ST")
            ? Array(selected.regular + selected.special)
                .fill("0")
                .join(",")
            : "";

        setReset(newNums);

        const game = {
          idx: Number(randomKeyNum()),
          productCode: type === "mega" ? "MM" : "PB",
          methodCode: selected.code === "CHN" ? "STD" : "SYS",
          systemCode: selected.code,
          ticketQty: selected.games,
          nums: [
            {
              idx: Number(randomKeyNum()),
              method: "M",
              num: newNums,
            },
          ],
        };

        setGameList([game]);
        setSelectedGame(selected);
        setSelectMode(selected);
      }
    });
  };

  /** 게임 초기화 */
  const initGameList = () => {
    const standardGameInfo = gameType.find((game) => game.name === "Standard");
    if (standardGameInfo) {
      setSelectedGame(standardGameInfo);
      setSelectMode(standardGameInfo);
      const randomKeyNum = customAlphabet("1234567890", 12);
      const standardGame = {
        idx: Number(randomKeyNum()),
        productCode: type === "mega" ? "MM" : "PB",
        methodCode: "STD",
        systemCode: "CHN",
        ticketQty: 1,
        nums: [
          {
            idx: Number(randomKeyNum()),
            method: "M",
            num: "0,0,0,0,0,0",
          },
        ],
      };
      setGameList([standardGame]);
    }
  };

  useEffect(() => {
    initGameList();
  }, [gameType]);

  /** 새 게임 추가 */
  const addGame = () => {
    if (isAuto) return;

    const isCheck = gameList.map((it) =>
      it.nums.map((numObj) => numObj.num.split(","))
    );
    const isUnComplete = isCheck.some((numsArray) =>
      numsArray.some((numList) => numList.includes("0"))
    );

    if (!selectedGame) return;

    if (isUnComplete) {
      setIsSlide(false);
      return;
    } else {
      setIsSlide(true);
      const randomKeyNum = customAlphabet("1234567890", 12);
      const newNums =
        selectedGame.code === "GUA"
          ? "0,0,0,0,0,G"
          : selectedGame.code === "PIC"
          ? "0,0,0,0,G,0"
          : selectedGame.code === "CHN"
          ? "0,0,0,0,0,0"
          : selectedGame.code.includes("ST")
          ? Array(selectedGame.regular + selectedGame.special)
              .fill("0")
              .join(",")
          : "";

      const game = {
        idx: Number(randomKeyNum()),
        productCode: type === "mega" ? "MM" : "PB",
        methodCode: selectedGame.code === "CHN" ? "STD" : "SYS",
        systemCode: selectedGame.code,
        ticketQty: selectedGame.games,
        nums: [
          {
            idx: Number(randomKeyNum()),
            method: "M",
            num: newNums,
          },
        ],
      };

      setGameList((prevList) => [...prevList, game]);
    }
  };

  /** 날짜 선택 */
  function getAdjustedDateRange(option: string): string[] {
    const today = new Date();
    let adjustedDate = new Date(today);

    switch (option) {
      case "1":
        adjustedDate.setMonth(today.getMonth() - 1);
        break;
      case "2":
        adjustedDate.setMonth(today.getMonth() - 2);
        break;
      case "3":
        adjustedDate.setMonth(today.getMonth() - 3);
        break;
      case "4":
        adjustedDate.setMonth(today.getMonth() - 4);
        break;
      case "5":
        adjustedDate.setMonth(today.getMonth() - 5);
        break;
      case "12":
        adjustedDate.setFullYear(today.getFullYear() - 1);
        break;
      case "36":
        adjustedDate.setFullYear(today.getFullYear() - 3);
        break;
      case "60":
        adjustedDate.setFullYear(today.getFullYear() - 5);
        break;
      default:
        return [];
    }

    const formatDate = (date: Date) => date.toISOString().split("T")[0];
    return [formatDate(today), formatDate(adjustedDate)];
  }

  const selectedDates = getAdjustedDateRange(month);

  const selectCompare = (target: string) => {
    if (target === "frequency") {
      dataAxios();
    }
  };

  /** API */
  const dataAxios = () => {
    const nowDate = selectedDates[0];
    const curDate = selectedDates[1];

    axiosInstance
      .get(
        `/statistics/${tab}?type=${
          type === "power" ? "pb" : "mm"
        }&sDate=${curDate}&eDate=${nowDate}`
      )
      .then(({ data }) => {
        const Data = data.data;
        if (menu === "frequency") {
          setList({
            total: Data.total_drawnum_all,
            white: Data.white_all,
            special: Data.power_all,
          });

          setCompareList({
            total: Data.total_drawnum_filtered,
            white: Data.white_filtered,
            special: Data.power_filtered,
          });
        } else {
          const white: Ball[] = [];
          const special: Ball[] = [];

          for (let i = 0; i < Data.white_all.length; i++) {
            white.push({
              number: Data.white_all[i].number,
              count: Data.white_all[i].timesince,
            });
          }

          for (let i = 0; i < Data.power_all.length; i++) {
            special.push({
              number: Data.power_all[i].number,
              count: Data.power_all[i].timesince,
            });
          }

          setList({
            total: Data.total_drawnum_all,
            white,
            special,
          });
        }
      });
  };

  useEffect(() => {
    dataAxios();
  }, [type, tab]);

  useEffect(() => {
    selectCompare(menu);
  }, [month]);

  return (
    <>
      <div className="bars">
        <div className="item">
          <div className="tit-wrap">
            <h4>White Ball</h4>
            {menu === "frequency" && (
              <div className="label">
                <div className="bar"></div>
                <span>Total</span>
                <div className="bar compare"></div>
                <span className="compare">{month} month</span>
              </div>
            )}
          </div>

          <table>
            <colgroup>
              <col width="56px" />
              <col width="" />
              <col width="92px" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>
                  {menu === "frequency"
                    ? "Frequency Graph"
                    : "Appearence Graph"}
                </th>
                <th>{menu === "frequency" ? "Action" : "Action"}</th>
              </tr>
            </thead>
            <tbody>
              {list.white && list.white.length > 0 ? (
                list.white.map((item) => {
                  const max = list.white.reduce((prev, value) =>
                    prev.count >= value.count ? prev : value
                  );
                  const maxCompare = compareList.white.reduce(
                    (prev, value) => (prev.count >= value.count ? prev : value),
                    { count: 0 }
                  );
                  const compareItem = compareList.white.find(
                    (compare) => compare.number === item.number
                  );

                  return (
                    <tr key={item.number}>
                      <td>
                        <div className="ball-wrap">
                          <div className="ball">{item.number}</div>
                        </div>
                      </td>
                      <td>
                        <div className="td-wrap">
                          <div
                            className="bar"
                            style={{
                              width: `${(item.count / max.count) * 100}%`,
                            }}
                          />
                          <p>{((item.count / list.total) * 100).toFixed(1)}%</p>
                        </div>
                        <p className="times">
                          {menu === "frequency"
                            ? `${item.count} times`
                            : item.count
                            ? `Spotted ${item.count} draws ago`
                            : "last draw"}
                        </p>
                        {menu === "frequency" && (
                          <div className="td-wrap compare">
                            <div
                              className="bar"
                              style={{
                                width: `${
                                  (compareItem
                                    ? compareItem.count / maxCompare.count
                                    : 0) * 100
                                }%`,
                              }}
                            />
                            <p className="compare">
                              {`${(
                                (compareItem
                                  ? compareItem.count / compareList.total
                                  : 0) * 100
                              ).toFixed(1)}%`}
                            </p>
                          </div>
                        )}
                        {menu === "frequency" && (
                          <p className="times compare">
                            {compareItem
                              ? `${compareItem.count} times`
                              : "0 times"}
                          </p>
                        )}
                      </td>
                      <td>
                        <button
                          className={`btn style01 sm ${
                            selectWball?.includes(String(item.number)) &&
                            "white"
                          }`}
                          onClick={() =>
                            handlePick(item.number.toString(), false)
                          }
                        >
                          Select
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3}>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="item">
          <div className="tit-wrap">
            <h4 className={type === "power" ? "power" : "mega"}>{BallType}</h4>
            {menu === "frequency" && (
              <div className="label">
                <div
                  className={type === "power" ? "power bar" : "mega bar"}
                ></div>
                <span className={type === "power" ? "power" : "mega"}>
                  Total
                </span>
                <div className="bar compare"></div>
                <span className="compare">{month} month</span>
              </div>
            )}
          </div>

          <table>
            <colgroup>
              <col width="56px" />
              <col width="" />
              <col width="92px" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>
                  {menu === "frequency"
                    ? "Frequency Graph"
                    : "Appearence Graph"}
                </th>
                <th>{menu === "frequency" ? "Action" : "Action"}</th>
              </tr>
            </thead>
            <tbody>
              {list.special && list.special.length > 0 ? (
                list.special.map((item) => {
                  const max = list.special.reduce((prev, value) =>
                    prev.count >= value.count ? prev : value
                  );
                  const maxCompare = compareList.special.reduce(
                    (prev, value) => (prev.count >= value.count ? prev : value),
                    { count: 0 }
                  );
                  const compareItem = compareList.special.find(
                    (compare) => compare.number === item.number
                  );

                  return (
                    <tr key={item.number}>
                      <td>
                        <div className="ball-wrap">
                          <div className={`ball ${type}`}>{item.number}</div>
                        </div>
                      </td>
                      <td>
                        <div className="td-wrap">
                          <div
                            className={
                              type === "power" ? "power bar" : "mega bar"
                            }
                            style={{
                              width: `${(item.count / max.count) * 100}%`,
                            }}
                          />
                          <p>{((item.count / list.total) * 100).toFixed(1)}%</p>
                        </div>
                        <p className="times">
                          {menu === "frequency"
                            ? `${item.count} times`
                            : item.count
                            ? `Spotted ${item.count} draws ago`
                            : "last draw"}
                        </p>
                        {menu === "frequency" && (
                          <div className="td-wrap compare">
                            <div
                              className={
                                type === "power" ? "power bar" : "mega bar"
                              }
                              style={{
                                width: `${
                                  (compareItem
                                    ? compareItem.count / maxCompare.count
                                    : 0) * 100
                                }%`,
                              }}
                            />
                            <p>
                              {`${(
                                (compareItem
                                  ? compareItem.count / compareList.total
                                  : 0) * 100
                              ).toFixed(1)}%`}
                            </p>
                          </div>
                        )}
                        {menu === "frequency" && (
                          <p className="times compare">
                            {compareItem
                              ? `${compareItem.count} times`
                              : "0 times"}
                          </p>
                        )}
                      </td>
                      <td>
                        {selectMode?.special !== 0 && (
                          <button
                            className={`btn style01 sm ${
                              selectSball === String(item.number) && "white"
                            }`}
                            onClick={() =>
                              handlePick(item.number.toString(), true)
                            }
                          >
                            Select
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3}>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="statObs"></div>

      <BarGame
        type={type}
        gameType={gameType}
        selectedGame={selectedGame}
        gameList={gameList}
        isActive={isActive}
        isSlide={isSlide}
        gameLength={gameLength}
        isAuto={isAuto}
        handleSelectChange={handleSelectChange}
        addGame={addGame}
        setIsActive={setIsActive}
        handlePick={handlePick}
        handleDelete={handleDelete}
        handleClear={handleClear}
        quickPlay={quickPlay}
        initGameList={initGameList}
      />
    </>
  );
};

export default Bar;
