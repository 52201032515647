// tools
import { ReactNode, useEffect } from "react";
import { Dialog } from "@mui/material";
import { useTheme } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dayjs } from "dayjs";

interface CustModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: (value: string) => void;
  children: ReactNode;
}

const CustDatePicker = ({
  onChange,
  target,
  readOnly,
}: {
  onChange: (event: Dayjs | null) => void;
  target: string[];
  readOnly: boolean;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        readOnly={readOnly}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": { top: "-8px" },
        }}
        onChange={onChange}
        shouldDisableDate={(date) => {
          const converted = date.format("YYYY-MM-DD");
          const allow = !target.includes(converted);

          return allow;
        }}
      />
    </LocalizationProvider>
  );
};

const CustModal = ({ open, onClose, children }: CustModalProps) => {
  const theme = useTheme(); // useTheme 훅 사용하여 테마 객체 가져오기
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); //반응형 커스터 마이징, 600이하일때 작동

  return (
    <Dialog
      onClose={onClose}
      fullScreen={fullScreen}
      open={open}
      keepMounted
      // disableScrollLock
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          backgroundColor: "rgba(0,0,0, 0.01)",
          backdropFilter: "blur(24px)",
          border: "1px solid rgba(255,254,241, 0.1)",
          borderRadius: "16px",
          // 반응형 디자인
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            margin: "0px",
            border: "0",
            borderRadius: "0",
            //backgroundColor: "red",
          },
          [theme.breakpoints.up("md")]: {
            width: "60%",
            margin: "20px auto",
            //backgroundColor: "green",
          },
          [theme.breakpoints.up("lg")]: {
            width: "40%",
            margin: "20px auto",
            //backgroundColor: "blue",
          },
        },
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(4px)",
        },
      }}
    >
      <div className="cust-modal">{children}</div>
    </Dialog>
  );
};

export { CustDatePicker, CustModal };
