// tools
import { useState } from "react";
import { Link } from "react-router-dom";
import { MagazineBarTab } from "../../constants/magazineMenu";

interface TabMenu {
  id: number;
  tab: string;
}

const Magazine = () => {
  const [selectedTab, setSelectedTab] = useState("All");

  return (
    <section className="magazine-area">
      <Link className="tit-wrap btn" to="/magazine">
        <h3 className="tit">Magazine</h3>
        <span className="material-symbols-rounded">chevron_right</span>
      </Link>

      <ul className="tab style02">
        {MagazineBarTab.map((it: TabMenu) => {
          return (
            <li
              key={it.id}
              className={selectedTab === it.tab ? "current" : ""}
              onClick={() => setSelectedTab(it.tab)}
            >
              {it.tab}
            </li>
          );
        })}
      </ul>

      <div className="magazine-item-wrap">
        {selectedTab === "All"
          ? dummyMagazine.slice(0, 8).map((it, idx) => (
              <div className="item" key={idx}>
                <div className="img-wrap">
                  <img src={it.thumbnail} alt="" />
                </div>
                <div className="txt-wrap">
                  <div className="tag">{it.type}</div>
                  <h4 className="tit">
                    Feature : The Most Affordable Rolex Watches
                  </h4>
                  <p className="txt">
                    If you keep a close eye on watch prices like we do, you
                    won't be surprised to learn how affordable some Rolexes
                  </p>
                </div>
              </div>
            ))
          : dummyMagazine
              .filter((it) => it.type === selectedTab)
              .slice(0, 8)
              .map((it, idx) => (
                <div className="item" key={idx}>
                  <div className="img-wrap">
                    <img src={it.thumbnail} alt="" />
                  </div>
                  <div className="txt-wrap">
                    <div className="tag">{it.type}</div>
                    <h4 className="tit">
                      Feature : The Most Affordable Rolex Watches
                    </h4>
                    <p className="txt">
                      If you keep a close eye on watch prices like we do, you
                      won't be surprised to learn how affordable some Rolexes
                    </p>
                  </div>
                </div>
              ))}
      </div>
    </section>
  );
};

export default Magazine;

const dummyMagazine = [
  {
    type: "Tech",
    title: "iphone 16 you can buy it",
    content: "go",
    thumbnail:
      "https://image.zdnet.co.kr/2024/03/21/06842797429d4961a31a6d6ac707d977.jpg",
  },

  {
    type: "Cars",
    title: "car",
    content: "car",
    thumbnail:
      "https://cdn.pixabay.com/photo/2023/07/29/16/42/car-8157299_1280.jpg",
  },
  {
    type: "Cars",
    title: "Do you know ferrari",
    content: "yep",
    thumbnail:
      "https://www.noblesse.com/shop/data/m/editor_new/2022/04/09/8a8691d24f060be01.jpg",
  },
  {
    type: "Watches",
    title: "watch",
    content: "watch",
    thumbnail:
      "https://cdn.pixabay.com/photo/2017/03/24/19/19/rolex-2171961_1280.jpg",
  },
  {
    type: "Watches",
    title: "expensive Watch",
    content: "go",
    thumbnail: "https://img.fruugo.com/product/3/50/779272503_max.jpg",
  },
  {
    type: "Tech",
    title: "tech",
    content: "tech",
    thumbnail:
      "https://cdn.pixabay.com/photo/2017/08/03/11/22/laptop-2575689_1280.jpg",
  },
  {
    type: "Yachts",
    title: "yacht",
    content: "yacht",
    thumbnail:
      "https://moranyachts.imgix.net/wp-content/uploads/Luxury-yacht-Lurssen-Marguertie-for-charter-0402.jpg?auto=format&fit=crop&fm=webp%26nbsp%3B2x&h=400&ixlib=php-3.1.0&w=720&s=a462b7d3deba37b227fc0133a501baac",
  },
  {
    type: "Life",
    title: "life",
    content: "life",
    thumbnail:
      "https://cdnweb01.wikitree.co.kr/webdata/editor/202302/11/img_20230211200502_bf47008d.webp",
  },
  {
    type: "Real Estate",
    title: "real estatate",
    content: "real estatates",
    thumbnail:
      "https://irp.cdn-website.com/bf4fe880/dms3rep/multi/estate+vs+a+house+-+Ranger+Ridge.jpeg",
  },
  {
    type: "Real Estate",
    title: "real estatate",
    content: "real estatates",
    thumbnail:
      "https://cdn.pixabay.com/photo/2018/08/23/00/56/home-3625018_1280.jpg",
  },
  {
    type: "Watches",
    title: "rwww",
    content: "www",
    thumbnail:
      "https://static.helioswatchstore.com/media/easyslide/TB-2_1.webp",
  },
  {
    type: "Cars",
    title: "123",
    content: "123",
    thumbnail:
      "https://img.danawa.com/cp_images/service/33/4682015/85177047.jpg",
  },
  {
    type: "Real Estate",
    title: "real estatate",
    content: "real estatates",
    thumbnail:
      "https://prod.rockmedialibrary.com/api/public/content/414643d39a84416884c98a479e8fdfe4?v=b93e4cd6",
  },

  {
    type: "Techs",
    title: "real estatate",
    content: "real estatates",
    thumbnail:
      "https://www.nvidia.com/content/dam/en-zz/Solutions/geforce/ada/rtx-4090/geforce-ada-4090-web-og-1200x630.jpg",
  },

  {
    type: "Lifes",
    title: "life",
    content: "life",
    thumbnail:
      "https://blog.kakaocdn.net/dn/D6Krz/btsfcNZWCdT/xgbZAIFUQrbRHltTJ9P12k/img.png",
  },
  {
    type: "Lifes",
    title: "life",
    content: "life",
    thumbnail:
      "https://cdn.pixabay.com/photo/2017/08/26/09/02/design-2682515_1280.jpg",
  },
  {
    type: "Yachts",
    title: "life",
    content: "life",
    thumbnail:
      "https://www.thesun.co.uk/wp-content/uploads/2022/04/KH-MOBILE-IMAGE-YACHT.jpg?strip=all&w=750&h=352&crop=1",
  },
  {
    type: "Yachts",
    title: "life",
    content: "life",
    thumbnail:
      "https://t3.ftcdn.net/jpg/03/80/28/28/360_F_380282819_bjauC6fkUfVQN6EFxVyzpkiiZcdVZFC1.jpg",
  },
  {
    type: "Watches",
    title: "life",
    content: "life",
    thumbnail:
      "https://magazine.chrono24.com/cdn-cgi/image/f=auto,metadata=none,fit=cover,q=65,w=1190,h=595,dpr=2.0/2022/04/Rolex-Opinion-21-scaled.jpg",
  },
  {
    type: "Watches",
    title: "life",
    content: "life",
    thumbnail:
      "https://cdn-images.farfetch-contents.com/22/17/34/37/22173437_51927556_600.jpg",
  },
  {
    type: "Watches",
    title: "life",
    content: "life",
    thumbnail:
      "https://blog.kakaocdn.net/dn/diaayX/btqNU16pySj/IeKaA1w4CkKVSELLQzgq7K/img.png",
  },
  {
    type: "Cars",
    title: "life",
    content: "life",
    thumbnail:
      "https://i.pinimg.com/736x/14/be/07/14be075d5f0f3c3a78ba2274a174f4dd.jpg",
  },
  {
    type: "Cars",
    title: "life",
    content: "life",
    thumbnail:
      "https://chevrolet.com.ph/wp-content/uploads/2024/04/5-Reasons-to-Get-a-Sports-Cars-in-the-Philippines.jpg",
  },
  {
    type: "Cars",
    title: "life",
    content: "life",
    thumbnail:
      "https://media.wired.com/photos/592675f6cefba457b079a0cd/master/pass/SCG003S-FRONTTA.jpg",
  },
  {
    type: "Cars",
    title: "life",
    content: "life",
    thumbnail:
      "https://d2hucwwplm5rxi.cloudfront.net/wp-content/uploads/2022/09/23074716/sports-vs-supercarss-cover-230920221250.jpg",
  },
  {
    type: "Cars",
    title: "life",
    content: "life",
    thumbnail:
      "https://uploads.audi-mediacenter.com/system/production/media/70272/images/3c92d2acbf6ab5f85be8006f854786f0f0ff36be/A1813681_web_2880.jpg?1698341967",
  },
  {
    type: "Cars",
    title: "life",
    content: "life",
    thumbnail:
      "https://di-uploads-pod12.dealerinspire.com/universitydodgeram/uploads/2018/07/2018-Dodge-Challenger-SRT-Demon-University-Dodge.jpg",
  },
];
