import { useEffect, useState } from "react";
import Swal from "../../tools/customAlert";
import { BallSet } from "../../types/ball";
import { SystemMenu, SystemNums } from "./myNumber";

interface SystemProps {
  type: string;
  token: string;
  selectMenu: SystemMenu | null;
  id: number;
  nums: {
    num: number[];
    special: number[];
    method: string;
    systemInfo: SystemMenu;
  };
  game: SystemNums[];
  playing: boolean;
  setGame: React.Dispatch<React.SetStateAction<SystemNums[]>>;
  handleClear: () => void;
  handleDelete: () => void;
  handleQuickPick: () => void;
}

const SysTicket = ({
  type,
  token,
  selectMenu,
  id,
  nums,
  game,
  playing,
  setGame,
  handleClear,
  handleDelete,
  handleQuickPick,
}: SystemProps) => {
  // stat
  const [showNumbers, SetShowNumbers] = useState(false);
  const [ballEditOpen, setBallEditOpen] = useState<boolean>(false);
  const [checkFull, setCheckFull] = useState<boolean>(false);
  const [fullList, setFullList] = useState<number[][]>([]);

  // constant
  const white = nums.num;
  const special = nums.special;

  // useEffect
  useEffect(() => {
    fullGame();
  }, [nums]);

  // func
  const fullGame = () => {
    if (!nums.num.includes(0) && !nums.special.includes(0)) {
      setCheckFull(true);
      setFullList(getCombinations(nums.num, 5));
    } else {
      setCheckFull(false);
    }
  };

  /** 시스템 숫자 생성기 */
  const getCombinations = (arr: number[], selectNumber: number) => {
    const results: any[] = [];

    if (selectNumber === 1) return arr.map((value) => [value]);

    arr.forEach((fixed, index, origin) => {
      const rest = origin.slice(index + 1);
      const combinations = getCombinations(rest, selectNumber - 1);
      const attached = combinations.map((combination) => [
        fixed,
        ...combination,
      ]);
      results.push(...attached);
    });

    return results;
  };

  const ballMaker = () => {
    return Array.from({ length: BallSet[type].white }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => handlePick(id - 1, ballNum, false, game, setGame)}
          className={`ball ${white.includes(ballNum) ? "picked" : ""}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  const specialMaker = () => {
    return Array.from({ length: BallSet[type].special }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => handlePick(id - 1, ballNum, true, game, setGame)}
          className={`ball ${special.includes(ballNum) ? "picked" : ""}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  const handlePick = (
    gameIndex: number,
    ballNum: number,
    isSpecial: boolean,
    game: SystemNums[],
    setGame: React.Dispatch<React.SetStateAction<SystemNums[]>>
  ) => {
    setGame((prevGame) => {
      const updatedGame = [...prevGame];
      const currentGame = { ...updatedGame[gameIndex] };

      if (!isSpecial) {
        const index = currentGame.num.indexOf(ballNum);

        if (index !== -1) {
          currentGame.num[index] = 0;
        } else {
          if (
            currentGame.num.filter((n) => n !== 0).length <
            currentGame.systemInfo.regular
          ) {
            const emptyIndex = currentGame.num.indexOf(0);
            if (emptyIndex !== -1) {
              currentGame.num[emptyIndex] = ballNum;
            }
            // 0을 제외한 숫자를 따로 정렬하고, 남은 자리를 0으로 채워서 배열 길이를 유지
            const sortedNumbers = currentGame.num.filter((n) => n !== 0);
            // .sort((a, b) => a - b);
            currentGame.num = [
              ...sortedNumbers,
              ...Array(currentGame.num.length - sortedNumbers.length).fill(0),
            ];
          } else {
            Swal.fire({
              title: "Maximum number of balls selected",
              text: `You can only select ${currentGame.systemInfo.regular} balls.`,
              icon: "warning",
              allowOutsideClick: false,
            });
            return prevGame;
          }
        }
      } else {
        const index = currentGame.special.indexOf(ballNum);

        if (index !== -1) {
          currentGame.special[index] = 0;
        } else {
          if (
            currentGame.special.filter((n) => n !== 0).length <
            currentGame.systemInfo.special
          ) {
            const emptyIndex = currentGame.special.indexOf(0);
            if (emptyIndex !== -1) {
              currentGame.special[emptyIndex] = ballNum;
            }
          } else {
            Swal.fire({
              title: "Maximum number of balls selected",
              text: `You can only select ${currentGame.systemInfo.special} balls.`,
              icon: "warning",
              allowOutsideClick: false,
            });
            return prevGame;
          }
        }
      }

      updatedGame[gameIndex] = currentGame;
      return updatedGame;
    });
  };

  return (
    <>
      <div className="item">
        <div className="ticket-tit-wrap">
          <p>
            {nums.systemInfo.name} #{id}
          </p>
          <div className="btn-wrap">
            <span
              className={`btn material-symbols-rounded ${
                playing ? "disabled" : ""
              }`}
              onClick={() => handleQuickPick()}
            >
              bolt
            </span>
            <span
              className={`btn material-symbols-rounded ${
                playing ? "disabled" : ""
              }`}
              onClick={handleClear}
            >
              mop
            </span>

            <span
              className={`btn material-symbols-rounded ${
                playing ? "disabled" : ""
              }`}
              onClick={handleDelete}
            >
              delete
            </span>
          </div>
        </div>

        <div className="picked-ball-wrap">
          <div
            className="ball-wrap"
            onClick={() => setBallEditOpen(!ballEditOpen)}
          >
            {white.map((it, idx) => (
              <div key={idx} className="ball">
                {it === 0 ? "" : it}
              </div>
            ))}

            {nums.systemInfo.code === "PIC" && <div className="ball">G</div>}

            {nums.systemInfo.code === "GUA" && (
              <>
                <div style={{ textAlign: "center" }}>+</div>
                <div className={`ball ${type === "mega" ? "mega" : "power"}`}>
                  G
                </div>
              </>
            )}
            {nums.systemInfo.special > 0 && (
              <>
                {nums.systemInfo.code === "GUA" ? (
                  <div className="ball"></div>
                ) : (
                  <div className={`ball ${type === "mega" ? "mega" : "power"}`}>
                    {special.map((sp) => (sp === 0 ? "" : sp)).join(",")}
                  </div>
                )}
              </>
            )}
            <span
              className={`material-symbols-rounded ${ballEditOpen && "open"}`}
            >
              keyboard_arrow_down
            </span>
          </div>

          {/* Edit */}
          <div className={`vertical-trans ${ballEditOpen ? "open" : "close"}`}>
            <div className="edit-ball-wrap">
              <div className="ball-count">
                <div className="tit-count-wrap">
                  <p className="tit">Ball</p>
                  <p className="count">
                    <span>{white.filter((it) => it !== 0).length}</span> of{" "}
                    {nums.systemInfo.regular}
                  </p>
                </div>
              </div>

              <div className="ball-wrap">{ballMaker()}</div>

              {nums.systemInfo.special > 0 && (
                <>
                  <div className="tit-count-wrap" style={{ marginTop: "12px" }}>
                    <p className="mega">
                      {type === "mega" ? "Mega" : "Power"} ball
                    </p>
                    <p className="count">
                      <span>{special.filter((it) => it !== 0).length}</span> of
                      {nums.systemInfo.special}
                    </p>
                  </div>
                  <div
                    className={`ball-wrap ${
                      type === "mega" ? "mega" : "power"
                    }`}
                  >
                    {specialMaker()}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 번호 다 골랐을때만 보여주기 */}
      {checkFull && (
        <div className="show-number">
          <div
            className={`btn style02 show ${showNumbers ? "open " : ""}`}
            onClick={() => {
              SetShowNumbers(!showNumbers);
            }}
          >
            <span>{showNumbers ? "Hide numbers" : "Show numbers"}</span>
            <span className="material-symbols-rounded">
              keyboard_arrow_down
            </span>
          </div>
          {nums.systemInfo.code !== "GUA" ? (
            nums.systemInfo.code === "PIC" ? (
              // Pick 1 Less
              <div
                className={`vertical-trans ${showNumbers ? "open" : "close"}`}
              >
                <div className="number-wrap">
                  {Array.from(
                    {
                      length: BallSet[type].white - nums.num.slice(0, 4).length,
                    },
                    (_, idx) => {
                      // 제외된 숫자를 기준으로 순회
                      const excludedNumbers = nums.num.slice(0, 4);
                      const availableNumber = idx + 1;
                      if (excludedNumbers.includes(availableNumber)) {
                        return null;
                      }
                      return (
                        <div className="number" key={availableNumber}>
                          <p className="">#{idx + 1}</p>
                          <div className="ball-wrap">
                            {/* 고정된 4개 번호 */}
                            {nums.num.slice(0, 4).map((num, idx) => (
                              <div className="ball" key={`fixed-${idx}`}>
                                {num}
                              </div>
                            ))}
                            {/* 현재 순회 중인 번호 추가 */}
                            <div className="ball">{availableNumber}</div>
                            {/* 특별 공 */}
                            <div
                              className={`ball ${
                                type === "mega" ? "mega" : "power"
                              }`}
                            >
                              {nums.special[0]}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : (
              // System
              <div
                className={`vertical-trans ${showNumbers ? "open" : "close"}`}
              >
                <div className="number-wrap">
                  {fullList.map((it, idx) => (
                    <div className="number" key={idx}>
                      <p className="">#{idx + 1}</p>
                      <div className="ball-wrap">
                        {it.slice(0, 5).map((num: any, idx: any) => (
                          <div className="ball" key={idx}>
                            {num}
                          </div>
                        ))}

                        <div
                          className={`ball ${
                            type === "mega" ? "mega" : "power"
                          }`}
                        >
                          {nums.special[0]}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          ) : (
            // GUA
            <div className={`vertical-trans ${showNumbers ? "open" : "close"}`}>
              <div className="number-wrap">
                {Array.from(
                  { length: BallSet[type].special },
                  (_, specialIdx) => (
                    <div className="number" key={specialIdx}>
                      <p className="">#{specialIdx + 1}</p>
                      <div className="ball-wrap">
                        {fullList[0].map((num, idx) => (
                          <div className="ball" key={idx}>
                            {num}
                          </div>
                        ))}
                        <div
                          className={`ball ${
                            type === "mega" ? "mega" : "power"
                          }`}
                        >
                          {specialIdx + 1}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {/* 번호 다 골랐을때만 보여주기 - 끝 */}
    </>
  );
};

export default SysTicket;
