// import axios from "axios";
// import useAuthStore from "../tools/useAuthStore";

// const createAxiosInstance = (api: string, token: string | null) => {
//   const { accessToken } = useAuthStore.getState();

//   return axios.create({
//     baseURL: api,
//     headers: {
//       "ngrok-skip-browser-warning": "69420",
//       Authorization: "bearer " + token,
//     },
//   });
// };

// export default createAxiosInstance;

import axios from "axios";
import Swal from "../tools/customAlert";

import User from "../tools/userInfo";
import { reset } from "../tools/zustandUpdate";

let isRefreshing = false; // 토큰 갱신 중인지 상태 추적
let failedQueue: Array<{ resolve: Function; reject: Function }> = []; // 실패한 요청 대기열

// 실패한 요청 대기열 처리
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// 토큰 갱신
const refreshAccessToken = async (api: string): Promise<string> => {
  if (isRefreshing) {
    // 이미 갱신 중이면 대기
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    });
  }

  isRefreshing = true;

  try {
    const response = await axios.post(
      `${api}/refresh`,
      {},
      { withCredentials: true }
    );

    const newToken = response.data.data.token;

    // 상태 업데이트
    User.getState().setToken(newToken);
    processQueue(null, newToken);
    return newToken;
  } catch (error) {
    processQueue(error, null);
    throw error;
  } finally {
    isRefreshing = false;
  }
};

// Axios 인스턴스 생성
const createAxiosInstance = (api: string) => {
  const axiosInstance = axios.create({
    baseURL: api,
    headers: {
      "ngrok-skip-browser-warning": "69420",
    },
  });

  // 요청 인터셉터
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = User.getState().token;
      if (token) {
        config.headers["Authorization"] = `bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // 응답 인터셉터
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // 401 에러 처리
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // 재시도 플래그 설정
        try {
          const newToken = await refreshAccessToken(api); // 새 토큰 요청
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`; // 새 토큰 설정

          return axiosInstance(originalRequest); // 요청 재시도
        } catch (refreshError) {
          Swal.fire({
            title: "Please Login again",
            icon: "warning",
            allowOutsideClick: false,
            didClose: () => {
              location.href = "/";
            },
          }).then(() => {
            const setToken = User.getState().setToken;
            const setTotalInfo = User.getState().setTotalInfo;
            setToken("");
            reset(setTotalInfo);
          });

          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxiosInstance;

//
