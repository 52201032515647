export interface Ball {
  white: number;
  special: number;
}

export interface BallSet {
  [key: string]: Ball;
  mega: Ball;
  power: Ball;
}

export const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
};

export interface Nums {
  num: number[];
  method: string;
  q: boolean;
}
