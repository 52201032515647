import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// img
import Google from "../img/common/google-ico.svg";
import Facebook from "../img/common/facebook-ico.svg";
import Apple from "../img/common/apple-ico.svg";

// tools
import User from "../tools/userInfo";
import NumberOnly from "../tools/numberOnly";
import { useEffect, useState } from "react";
import Swal from "../tools/customAlert";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { Dayjs } from "dayjs";
import { FormHelperText, OutlinedInput } from "@mui/material";
import SignupComplete from "./signUpComplete";
import createAxiosInstance from "../api/axiosConfig";
import { useLotto } from "../App";
import MetaTag from "../tools/SEOMetaTag";
import { maxWidth } from "@mui/system";

interface Info {
  email: string;
  confirm: string;
  password: string;
  gender: string;
  firstName: string;
  lastName: string;
  phone: string;
  birth: string;
  unit: string;
  number: string;
  post: string;
  address: string;
  city: string;
  country: string;
  notifyEmail: string;
  notifySms: string;
  notifyPush: string;
  provider: string;
}

interface CheckInfo {
  email: boolean;
  confirm: boolean;
  password: boolean;
  firstName: boolean;
  lastName: boolean;
  phone: boolean;
  post: boolean;
  birth: boolean;
  gender: boolean;
  unit: boolean;
  number: boolean;
  address: boolean;
  city: boolean;
  country: boolean;
}

const Signup = () => {
  const { api, token } = User();
  const axiosInstance = createAxiosInstance(api);
  const { isPc } = useLotto();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [check, setCheck] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [info, setInfo] = useState<Info>({
    email: "",
    confirm: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    birth: "",
    gender: "",
    unit: "",
    number: "",
    address: "",
    post: "",
    city: "",
    country: "",
    notifyEmail: "R",
    notifySms: "R",
    notifyPush: "R",
    provider: "",
  });

  const [checkList, setCheckList] = useState<CheckInfo>({
    email: false,
    confirm: false,
    password: false,
    firstName: false,
    lastName: false,
    phone: false,
    birth: false,
    gender: false,
    unit: false,
    number: false,
    address: false,
    city: false,
    post: false,
    country: false,
  });

  const [focused, setFocused] = useState({
    email: false,
    confirm: false,
    password: false,
    firstName: false,
    lastName: false,
    phone: false,
    birth: false,
    gender: false,
    unit: false,
    number: false,
    address: false,
    city: false,
    post: false,
    country: false,
  });

  console.log(info);

  const [social, setSocial] = useState(false);
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleData = (key: string, value: string) => {
    if (key === "number" || key === "phone") {
      setInfo((prev) => ({ ...prev, [key]: NumberOnly(value) }));
    } else {
      setInfo((prev) => ({ ...prev, [key]: value }));
    }
  };

  console.log(social);

  const confirm = document.getElementById("confirm") as HTMLInputElement;

  // password regex
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,21}$/;
  // console.log(regex.test(info.password));

  // 유효성 검사 함수
  const [errors, setErrors] = useState({
    email: false,
    confirm: false,
    password: false,
    firstName: false,
    lastName: false,
    phone: false,
    birth: false,
    gender: false,
    unit: false,
    number: false,
    address: false,
    city: false,
    post: false,
    country: false,
  });

  // 유효성 검사 함수
  const validateInputs = (kind: string) => {
    if (kind === "base") {
      const emailValid = social || info.email.length > 1;
      const confirmValid =
        info.confirm.trim().length > 1 && info.email === info.confirm;
      const passwordValid = social || regex.test(info.password);
      const genderValid = ["male", "female", "other"].includes(info.gender);
      const firstNameValid = info.firstName.length > 2;
      const lastNameValid = info.lastName.length > 2;
      const phoneValid = info.phone.length > 1;
      const birthValid = info.birth.length > 1;
      const addressValid = info.address.length > 1;
      const unitValid = info.unit.length > 1;
      const numberValid = info.number.length > 1;
      const cityValid = info.city.length > 1;
      const countryValid = info.country.length > 1;
      const postValid = info.post.length > 1;

      setErrors({
        email: !emailValid,
        confirm: !confirmValid,
        password: !passwordValid,
        gender: !genderValid,
        firstName: !firstNameValid,
        lastName: !lastNameValid,
        phone: !phoneValid,
        birth: !birthValid,
        address: !addressValid,
        unit: !unitValid,
        number: !numberValid,
        city: !cityValid,
        country: !countryValid,
        post: !postValid,
      });

      return (
        emailValid &&
        confirmValid &&
        passwordValid &&
        genderValid &&
        firstNameValid &&
        lastNameValid &&
        phoneValid &&
        birthValid &&
        addressValid &&
        unitValid &&
        numberValid &&
        cityValid &&
        countryValid &&
        postValid
      );
    } else {
      const emailValid = social || info.email.length > 1;
      const genderValid = ["male", "female", "other"].includes(info.gender);
      const firstNameValid = info.firstName.length > 2;
      const lastNameValid = info.lastName.length > 2;
      const phoneValid = info.phone.length > 1;
      const birthValid = info.birth.length > 1;
      const addressValid = info.address.length > 1;
      const unitValid = info.unit.length > 1;
      const numberValid = info.number.length > 1;
      const cityValid = info.city.length > 1;
      const countryValid = info.country.length > 1;
      const postValid = info.post.length > 1;

      setErrors({
        email: !emailValid,
        confirm: false, // 소셜 로그인 시 기본값
        password: false, // 소셜 로그인 시 기본값
        gender: !genderValid,
        firstName: !firstNameValid,
        lastName: !lastNameValid,
        phone: !phoneValid,
        birth: !birthValid,
        address: !addressValid,
        unit: !unitValid,
        number: !numberValid,
        city: !cityValid,
        country: !countryValid,
        post: !postValid,
      });

      return (
        emailValid &&
        genderValid &&
        firstNameValid &&
        lastNameValid &&
        phoneValid &&
        birthValid &&
        addressValid &&
        unitValid &&
        numberValid &&
        cityValid &&
        countryValid &&
        postValid
      );
    }
  };

  const validateField = (key: keyof typeof info) => {
    if (key === "email" || key === "confirm") {
      // 이메일과 확인 이메일 비교
      const emailMatch = info.email === info.confirm;
      setErrors((prev) => ({
        ...prev,
        confirm: key === "confirm" ? !emailMatch : prev.confirm,
        email:
          key === "email"
            ? !emailMatch || info.email.trim() === ""
            : prev.email,
      }));
    } else {
      // 일반 필드 유효성 검사
      setErrors((prev) => ({
        ...prev,
        [key]: info[key].trim() === "",
      }));
    }
  };

  const handleFocus = (key: keyof typeof focused) => {
    setFocused((prev) => ({ ...prev, [key]: true }));
  };

  const handleBlur = (key: keyof typeof focused) => {
    validateField(key);
    setFocused((prev) => ({ ...prev, [key]: false }));
  };

  useEffect(() => {
    if (info.email.length === 0) {
      setEmailError(false);
    }

    if (info.phone.length !== 0 && info.phone.length !== 10) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }, [info.email, info.phone]);

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        navigate("/welcome", { replace: true });
      }, 2000);
    }
  }, [token]);

  useEffect(() => {
    const socialCheck = sessionStorage.getItem("social");
    if (socialCheck) {
      const parse = JSON.parse(socialCheck);
      setSocial(true);
      setInfo((prev) => ({
        ...prev,
        ...parse,
      }));
      setTimeout(() => sessionStorage.clear(), 50);
    }
  }, []);

  /** sign check 2 */
  const checkout = () => {
    setCheck(true);
    if (agree) {
      if (validateInputs("base")) {
        register();
      } else {
        Swal.fire({
          title: "Please meet all the input requirements.",
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } else {
      Swal.fire({
        title: "You must agree to the terms and conditions.",
        icon: "error",
        allowOutsideClick: false,
      });
    }
  };

  const register = () => {
    axiosInstance
      .post(`/register`, info)
      .then(({ data }) => {
        if (data.message === "success") {
          navigate(`/welcome`);
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          allowOutsideClick: false,
        });
      });
  };

  const googleLogin = useGoogleLogin({
    scope: "email profile",
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: `${location.origin}/auth/google`,
    state: location.href,
  });

  const isSocial = () => {
    if (info.email) {
      return info.email;
    } else {
      ("Email");
    }
  };

  const socialCheckout = () => {
    console.log("hi");

    setCheck(true);
    if (agree) {
      if (validateInputs("social")) {
        socialRegister();
      } else {
        Swal.fire({
          title: "Please meet all the input requirements.",
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } else {
      Swal.fire({
        title: "You must agree to the terms and conditions.",
        icon: "error",
        allowOutsideClick: false,
      });
    }
  };

  const socialRegister = () => {
    axiosInstance
      .post(`/register`, info)
      .then(({ data }) => {
        if (data.message === "success") {
          navigate(`/welcome`);
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          allowOutsideClick: false,
        });
      });
  };

  return (
    <>
      <MetaTag title="Sign Up" path="signup" />

      {location.pathname === "/signup" ? (
        <div className="signup-wrap" style={{ maxWidth: "720px" }}>
          <div className="sub-top-area">
            <h3 className="tit">Welcome to the Lottery Cluster!</h3>
            {/* <p className="sub-tit">Sign up with your email to get started!</p> */}
          </div>

          <div className="sns-area">
            <h4>Signup with</h4>
            <div className="sns-wrap">
              {(info.provider === "" || info.provider === "google") && (
                <img
                  src={Google}
                  alt="Google login"
                  className="btn"
                  onClick={() => {
                    googleLogin();
                  }}
                />
              )}

              {(info.provider === "" || info.provider === "facebook") && (
                <img src={Facebook} className="btn" alt="Facebook login" />
              )}
              {(info.provider === "" || info.provider === "apple") && (
                <img src={Apple} className="btn" alt="Apple login" />
              )}
            </div>
          </div>
          {info.provider === "" && <p className="or">or</p>}
          <form autoComplete="off">
            <div className="input-wrap">
              <TextField
                type="email"
                label="Email"
                sx={{
                  width: "100%",
                }}
                onChange={({ target }) => {
                  handleData("email", target.value);
                  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                    target.value
                  );
                  setEmailError(!isValidEmail);
                }}
                disabled={social}
                value={isSocial()}
                error={emailError || (!info.email && errors.email)}
                helperText={
                  !info.email && errors.email
                    ? "Email is required."
                    : emailError
                    ? "Please enter a valid email address."
                    : ""
                }
                onFocus={() => handleFocus("email")}
                onBlur={() => handleBlur("email")}
              />
            </div>
            {!social && (
              <div className="input-wrap">
                <TextField
                  id="confirm"
                  type="email"
                  label="Confirm email"
                  fullWidth
                  value={info.confirm}
                  onChange={({ target }) => {
                    handleData("confirm", target.value);
                    setErrors((prev) => ({ ...prev, confirm: false }));
                  }}
                  error={errors.confirm}
                  helperText={
                    errors.confirm && !info.confirm.trim()
                      ? "Email is required."
                      : errors.confirm && info.confirm !== info.email
                      ? "Email is do not matching"
                      : ""
                  }
                  onFocus={() => handleFocus("confirm")}
                  onBlur={() => {
                    if (!info.confirm.trim()) {
                      // 입력값이 없을 때
                      setErrors((prev) => ({ ...prev, confirm: true }));
                    } else if (info.confirm !== info.email) {
                      // 입력값이 이메일과 매칭되지 않을 때
                      setErrors((prev) => ({ ...prev, confirm: true }));
                    } else {
                      // 입력값이 유효할 때 에러 해제
                      setErrors((prev) => ({ ...prev, confirm: false }));
                    }
                  }}
                />
              </div>
            )}
            {!social && (
              <FormControl fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  onChange={({ target }) => {
                    handleData("password", target.value);
                  }}
                  id="password"
                  name="pw"
                  error={errors.password}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <span className="material-symbols-rounded">
                          visibility
                        </span>
                      ) : (
                        <span className="material-symbols-rounded">
                          visibility_off
                        </span>
                      )}
                    </IconButton>
                  }
                  label="Password"
                  onFocus={() => handleFocus("password")}
                  onBlur={() => {
                    // 비밀번호 유효성 검사
                    const isPasswordValid = regex.test(info.password);
                    setErrors((prev) => ({
                      ...prev,
                      password: !isPasswordValid || info.password.trim() === "",
                    }));
                  }}
                />
                {errors.password && (
                  <FormHelperText error>
                    {info.password.trim() === ""
                      ? "Password is required."
                      : "Password is invalid. Min. 8 chars. Must include uppercase, lowercase, and number."}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            <FormControl component="fieldset" error={!checkList.gender}>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={info.gender}
                onChange={({ target }) => handleData("gender", target.value)}
                onFocus={() => handleFocus("gender")}
                onBlur={() => handleBlur("gender")}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio checked={info.gender === "male"} />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio checked={info.gender === "female"} />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio checked={info.gender === "other"} />}
                  label="Ohter"
                />
              </RadioGroup>
              <FormHelperText>
                {errors.gender && check ? "Please select your gender." : ""}
              </FormHelperText>
            </FormControl>

            <div className="input-wrap">
              <TextField
                label="First Name"
                value={info.firstName}
                onChange={({ target }) => handleData("firstName", target.value)}
                fullWidth
                onFocus={() => handleFocus("firstName")}
                onBlur={() => {
                  // 유효성 검사
                  const isValid = info.firstName.trim().length > 1;
                  setErrors((prev) => ({
                    ...prev,
                    firstName: !isValid,
                  }));
                }}
                error={errors.firstName}
                helperText={
                  errors.firstName && "First name must be a minimum of 2 char."
                }
              />
              <TextField
                label="Last Name"
                onChange={({ target }) => handleData("lastName", target.value)}
                value={info.lastName}
                fullWidth
                onFocus={() => handleFocus("lastName")}
                onBlur={() => {
                  // 유효성 검사
                  const isValid = info.lastName.trim().length > 1;
                  setErrors((prev) => ({
                    ...prev,
                    lastName: !isValid,
                  }));
                }}
                error={errors.lastName}
                helperText={
                  errors.lastName && "Last name must be a minimum of 2 char."
                }
              />
            </div>
            <div className="input-wrap">
              <TextField
                type="tel"
                label="Phone Number"
                fullWidth
                value={info.phone}
                onChange={({ target }) => {
                  handleData("phone", target.value);
                }}
                onBlur={() => {
                  // 전화번호 유효성 검사
                  const isValidPhone = /^[0-9]{10}$/.test(info.phone);
                  setErrors((prev) => ({
                    ...prev,
                    phone: !isValidPhone,
                  }));
                }}
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                error={errors.phone}
                helperText={
                  errors.phone
                    ? "The phone number format is invalid. Please try again."
                    : ""
                }
              />
            </div>
            <div className="input-label-wrap">
              <FormControl fullWidth error={errors.birth}>
                <label htmlFor="birth">Date of Birth</label>
                <div className="input-wrap">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        ...(errors.birth && {
                          border: "0.5px solid red",
                          borderRadius: "4px",
                        }),
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          onBlur: () => {
                            // 포커스를 벗어날 때 유효성 검사
                            const isValidBirth = info.birth.trim() !== ""; // 비어 있지 않은지 확인
                            setErrors((prev) => ({
                              ...prev,
                              birth: !isValidBirth,
                            }));
                          },
                        },
                      }}
                      onChange={(e: Dayjs | null) => {
                        if (e) {
                          handleData("birth", e.format("YYYYMMDD"));
                          setErrors((prev) => ({
                            ...prev,
                            birth: false, // 날짜가 선택되면 에러 해제
                          }));
                        } else {
                          setErrors((prev) => ({
                            ...prev,
                            birth: true, // 선택된 날짜가 없으면 에러 설정
                          }));
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <FormHelperText>
                  {errors.birth
                    ? "Invalid Birth Date. Please select a valid date."
                    : ""}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="input-label-wrap">
              <label htmlFor="">Address</label>
              <div className="input-wrap">
                <TextField
                  label="Unit/Flat"
                  fullWidth
                  value={info.unit}
                  onChange={({ target }) => handleData("unit", target.value)}
                  onFocus={() => handleFocus("unit")}
                  onBlur={() => handleBlur("unit")}
                  error={errors.unit}
                  helperText={errors.unit && "Required field."}
                />
                <TextField
                  type="tel"
                  label="Street Number"
                  fullWidth
                  value={info.number}
                  onChange={({ target }) => handleData("number", target.value)}
                  onFocus={() => handleFocus("number")}
                  onBlur={() => handleBlur("number")}
                  error={errors.number}
                  helperText={errors.number && "Required field."}
                />
                <TextField
                  type="tel"
                  label="Post Code"
                  fullWidth
                  value={info.post}
                  onChange={({ target }) => handleData("post", target.value)}
                  onFocus={() => handleFocus("post")}
                  onBlur={() => handleBlur("post")}
                  error={errors.post}
                  helperText={errors.post && "Required field."}
                />
              </div>
              <div className="input-wrap">
                <TextField
                  label="Address"
                  fullWidth
                  value={info.address}
                  onChange={({ target }) => handleData("address", target.value)}
                  onFocus={() => handleFocus("address")}
                  onBlur={() => handleBlur("address")}
                  error={errors.address}
                  helperText={errors.address && "Required field."}
                />
              </div>
              <div className="input-wrap">
                <TextField
                  label="City / Suburb"
                  fullWidth
                  value={info.city}
                  onChange={({ target }) => handleData("city", target.value)}
                  onFocus={() => handleFocus("city")}
                  onBlur={() => handleBlur("city")}
                  error={errors.city}
                  helperText={errors.city && "Required field."}
                />
                <FormControl fullWidth error={errors.country}>
                  <InputLabel id="demo-simple-select-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Country"
                    value={info.country}
                    onChange={({ target }) =>
                      handleData("country", target.value)
                    }
                    onFocus={() => handleFocus("country")}
                    onBlur={() => handleBlur("country")}
                    error={errors.country}
                  >
                    <MenuItem value="AU">Australia</MenuItem>
                    <MenuItem value="NZ">New Zealand</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "#f44336" }}>
                    {errors.country && "Select your country"}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="input-label-wrap">
              <label htmlFor="">Notifications</label>
              <div className="input-wrap">
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={({ target }) =>
                        handleData("notifyEmail", target.checked ? "R" : "N")
                      }
                    />
                  }
                  label="Email Notifications"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={({ target }) =>
                        handleData("notifySms", target.checked ? "R" : "N")
                      }
                    />
                  }
                  label="SMS Notifications"
                />
                {!isPc && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        onChange={({ target }) =>
                          handleData("notifyPush", target.checked ? "R" : "N")
                        }
                      />
                    }
                    label="App Push"
                  />
                )}
              </div>
            </div>
            <div className="input-wrap agree">
              <FormControlLabel
                value=""
                control={
                  <Checkbox
                    value={agree}
                    onChange={({ target }) => setAgree(target.checked)}
                  />
                }
                label={
                  <p>
                    I have read The Lottery Cluster’s{" "}
                    <a href="/privacy" target="_blank">
                      privacy policy
                    </a>{" "}
                    and agree to the{" "}
                    <a href="/terms" target="_blank">
                      terms and conditions
                    </a>
                    .
                  </p>
                }
              />
            </div>
            <p style={{ textAlign: "center", marginBottom: "8px" }}>
              To continue, please confirm you agree to our terms and conditions.
            </p>
            <div
              className="btn style01 md spc"
              style={{ width: "100%" }}
              onClick={() => {
                if (social) {
                  setCheck(true);
                  socialCheckout();
                } else {
                  setCheck(true);
                  checkout();
                }
              }}
            >
              <span>Create account Now</span>
            </div>
          </form>
        </div>
      ) : location.pathname === "/welcome" ? (
        <SignupComplete info={info} />
      ) : (
        <></>
      )}
    </>
  );
};
export default Signup;
