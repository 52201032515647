// import createAxiosInstance from "../api/axiosConfig";

// // tools
// import type { Info } from "./userInfo";

// export const update = (
//   api: string,
//   token: string,
//   setTotalInfo: (info: Info) => void
// ) => {
//   const axiosInstance = createAxiosInstance(api);

//   axiosInstance
//     .get(`/auth/me`)
//     .then(({ data }) => {
//       if (data.message) {
//         setTotalInfo(data.data);
//       }
//     })
//     .catch((response) => {
//       console.log(response);
//     });
// };

// export const reset = (setTotalInfo: (info: Info) => void) => {
//   setTotalInfo({
//     address: "",
//     birth: "",
//     cash: 0,
//     city: "",
//     country: "",
//     createdAt: "",
//     email: "",
//     firstName: "",
//     gender: "",
//     lastName: "",
//     level: 0,
//     emailAlarm: "",
//     smsAlarm: "",
//     pushAlarm: "",
//     number: "",
//     post: "",
//     phone: "",
//     unit: "",
//     provider: "",
//     prize: 0,
//     totalPrize: 0,
//     weeklySpendLimit: 0,
//     remaining: 0,
//     cart: 0,
//     verifiedAt: null,
//     emailVerifiedAt: null,
//     pending: "0.00",
//     suspendEndDate: null,
//   });
// };

// tools/userUtils.ts
import createAxiosInstance from "../api/axiosConfig";
import type { Info } from "./userInfo";

// 유저 정보를 업데이트하는 함수
export const update = (
  api: string,
  token: string,
  setTotalInfo: (info: Info) => void
) => {
  const axiosInstance = createAxiosInstance(api);

  axiosInstance
    .get(`/auth/me`)
    .then(({ data }) => {
      if (data.message) {
        setTotalInfo(data.data); // 상태 업데이트
      }
    })
    .catch((error) => {
      console.error("User Info Update Failed:", error); // 에러 처리
    });
};

// 유저 정보를 초기화하는 함수
export const reset = (setTotalInfo: (info: Info) => void) => {
  setTotalInfo({
    address: "",
    birth: "",
    cash: 0,
    city: "",
    country: "",
    createdAt: "",
    email: "",
    firstName: "",
    gender: "",
    lastName: "",
    level: 0,
    emailAlarm: "",
    smsAlarm: "",
    pushAlarm: "",
    number: null,
    post: "",
    phone: "",
    unit: null,
    provider: "",
    prize: 0,
    totalPrize: 0,
    weeklySpendLimit: 0,
    remaining: 0,
    cart: 0,
    verifiedAt: null,
    emailVerifiedAt: null,
    pending: "0.00",
    suspendEndDate: null,
  });
};
