// tools
import { useState, useEffect } from "react";
import User from "../../tools/userInfo";
import amountMaker from "../../tools/amountMaker";

// ui
import {
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  useMediaQuery,
} from "@mui/material";

// components
import NoData from "../common/noData";
import DrawSubs from "./drawSubs";

//  img
import PowerBallLogo from "../../img/common/USA_Power_lottery.png";
import MegaBallLogo from "../../img/common/USA_Mega_lottery.png";
import createAxiosInstance from "../../api/axiosConfig";
import dayjs, { Dayjs } from "dayjs";
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Swal from "../../tools/customAlert";
import ReceiptModal from "./receipt_modal";
import ShowNumMatch from "./showNumMatching";
import { formatPurchaseDate } from "../../tools/formatDate";
// import { isType } from "@babel/types";

interface Nums {
  idx: number;
  num: string;
}

export interface List {
  playdate: string; // 추첨 날짜
  purchased: string; // 결제 날짜
  cost: string; // 총 가격
  productCode: string; // 게임 종류
  productName: string; // 게임 제목
  nums: Nums[]; // 게임 리스트
  drawnum: number; // 회차
  methodCode: string; // Standard Or Syndicate 구분
  systemCode: string; // QPK, CHK - STD / System 종류 - SYN
  gameCnt: number; // 게임 갯수
  jackpot: number; // 총 당첨금
  prize: string; // 실제 당첨금
  multiplier: string; // 멀티플라이어 유무
  status: string; // 추첨 상태 (pending, 당첨, 낙첨, 취소)
  idx: number; // 고유 번호
  serial: string; // 시리얼 번호
  syndicateIdx?: string; // 신디케이트 고유번호
  unitPrice?: string; // 신디케이트 티켓 개당 가격
  subscription: number; // 값 존재시 총 구독 회차
  subscriptionCycle: number; // 몇회차 구독 결제
  ticketPath: string; // 실물 티켓 주소
  totalPrize: string; // 실제 당첨금
  multiplierValue: number; // 멀티 배수
  exchange: string; // 환율
  winningNumbers: string; // 해당 회차 당첨번호
}

export interface Subscription {
  idx: number;
  productCode: string;
  productName: string;
  methodCode: string;
  systemCode: string;
  draws: number;
  cycle: number;
  multiplier: string;
  amount: string;
  reason: string | null;
  status: string;
  createdAt: string;
  nums: Nums[];
}

export interface CustomDatePickerProps {
  label: string;
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
}

const SelectTypeFilter = [
  { code: "all", title: "All Lottery" },
  { code: "pb", title: "USA Power Lottery" },
  { code: "mm", title: "USA Mega Lottery" },
];

const Draws = () => {
  const { api, token, info } = User();
  const axiosInstance = createAxiosInstance(api);

  const [type, setType] = useState<string>("all");
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(30, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [list, setList] = useState<List[]>([]);
  const [subList, setSubList] = useState<Subscription[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);

  /** handle Start */
  const handleStartDateChange = (date: Dayjs | null) => {
    if (!date || (endDate && date.isAfter(endDate, "day"))) {
      Swal.fire({
        icon: "warning",
        title: "The start date cannot be later than the end date.",
        didClose: () => setStartDate(dayjs().subtract(30, "day")),
      });
      return;
    }

    if (endDate && date.add(1, "year").isBefore(endDate, "day")) {
      Swal.fire({
        icon: "warning",
        title: "The date range cannot exceed one year.",
        didClose: () => setStartDate(dayjs().subtract(30, "day")),
      });
      return;
    }

    setStartDate(date);
  };

  /** handle End */
  const handleEndDateChange = (date: Dayjs | null) => {
    if (!date || (startDate && date.isBefore(startDate, "day"))) {
      Swal.fire({
        icon: "warning",
        title: "The end date cannot be earlier than the start date.",
        didClose: () => setEndDate(dayjs()),
      });
      return;
    }

    if (startDate && startDate.add(1, "year").isBefore(date, "day")) {
      Swal.fire({
        icon: "warning",
        title: "The date range cannot exceed one year.",
        didClose: () => setEndDate(dayjs()),
      });
      return;
    }

    setEndDate(date);
  };

  const fetchData = async (currentPage: number) => {
    setIsLoading(true);
    try {
      const isType = type === "all" ? "" : type === "pb" ? "PB" : "MM";
      const sDate = startDate?.format("YYYY-MM-DD");
      const eDate = endDate?.format("YYYY-MM-DD");
      const endpoint = `/orders?type=${isType}`;

      const res = await axiosInstance.get(endpoint, {
        params: {
          sDate,
          eDate,
          page: currentPage,
        },
      });
      const { data, last_page } = res.data.data;
      if (currentPage === 1) {
        setList(data);
      } else {
        setList((prevList) => [...prevList, ...data]);
      }

      setMaxPage(last_page);
      setHasMoreData(currentPage < last_page);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreData = () => {
    if (isLoading || !hasMoreData) return;

    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setPage(1);
      setHasMoreData(true);
      await Promise.all([fetchData(1), getSubs()]);
    };

    fetchInitialData();
  }, [type, startDate, endDate]);

  const getSubs = () => {
    axiosInstance
      .get("/subscriptions")
      .then((res) => setSubList(res.data.data))
      .catch((error) => console.error(error));
  };

  // date Picker
  const CustomDay = (props: PickersDayProps<Dayjs>) => {
    const { day, ...other } = props;

    // 오늘 날짜 체크 (스타일 변경을 위해 남겨둠)
    const isToday = day.isSame(dayjs(), "day");

    return <PickersDay {...other} day={day} />;
  };

  const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
    label,
    value,
    onChange,
  }) => {
    return (
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        shouldDisableDate={() => false}
        slots={{
          day: (dayProps) => <CustomDay {...dayProps} />,
        }}
        slotProps={{
          textField: {
            error: false,
          },
        }}
      />
    );
  };

  return (
    <div className="draw">
      {subList.length > 0 && <DrawSubs data={subList} getSubs={getSubs} />}
      <div className="draw-wrap">
        <div className="filter-wrap">
          <div className="filter">
            <h4 className="tit">Filter by Game</h4>
            <FormControl fullWidth>
              <InputLabel id="game">Select a Game</InputLabel>
              <Select labelId="game" id="" label="Select a Game" value={type}>
                {SelectTypeFilter.map((it, idx) => (
                  <MenuItem
                    key={idx}
                    value={it.code}
                    onClick={() => setType(it.code)}
                  >
                    {it.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="filter">
            <h4 className="tit">Search by Date Range</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="datepicker-wrap">
                {/* Start date */}
                <CustomDatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                {/* End date */}
                <CustomDatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </LocalizationProvider>
          </div>
        </div>

        <div className="draw-list">
          {/* {isLoading ? (
            Array.from({ length: 5 }).map((_, idx) => (
              <Skeleton
                key={idx}
                className="item"
                variant="rectangular"
                width="100%"
                height={172}
                style={{ marginBottom: "16px" }}
              />
            ))
          ) : list.length > 0 ? (
            list.map((item) => <Item key={item.idx} data={item} />)
          ) : (
            <NoData />
          )} */}
          {list.length > 0 ? (
            list.map((item) => <Item key={item.idx} data={item} />)
          ) : (
            <NoData />
          )}
        </div>
        {/* <Pagination
          count={page.max}
          page={page.current}
          siblingCount={1}
          size="medium"
          onChange={(_, value) => {
            fetchData(value);
          }}
        /> */}
      </div>
      <div
        className={`loadmoreWrap btn style01 md load-more ${
          hasMoreData ? "" : "stop"
        }`}
        onClick={loadMoreData}
      >
        {isLoading ? (
          <div className="loader"></div>
        ) : hasMoreData ? (
          "Load more"
        ) : (
          "No more items to load"
        )}
      </div>
    </div>
  );
};

export default Draws;

const Item = ({ data }: { data: List }) => {
  const { api } = User();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [onReceipt, setOnReceipt] = useState(false);

  const winningNums = data?.winningNumbers?.split(",");
  const winningSp = winningNums !== undefined ? winningNums.pop() : null;

  const game = data.productCode === "MM" ? "mega" : "power";
  const reward = amountMaker(data.jackpot);

  const imageSource = () => {
    location.href = `/ticket?code=${data.idx}`;
  };

  const goGame = (type: string) => {
    if (type === "PB") {
      window.location.href = "/power?Q";
    } else {
      window.location.href = "/mega?Q";
    }
  };

  const rankMatcher = (wBall: number, sBall: number) => {
    if (wBall === 0 && sBall === 1) {
      return " - Tier 9";
    } else if (wBall === 1 && sBall === 1) {
      return " - Tier 8";
    } else if (wBall === 2 && sBall === 1) {
      return " - Tier 7";
    } else if (wBall === 3 && sBall === 0) {
      return " - Tier 6";
    } else if (wBall === 3 && sBall === 1) {
      return " - Tier 5";
    } else if (wBall === 4 && sBall === 0) {
      return " - Tier 4";
    } else if (wBall === 4 && sBall === 1) {
      return " - Tier 3";
    } else if (wBall === 5 && sBall === 0) {
      return " - Tier 2";
    } else if (wBall === 5 && sBall === 1) {
      return " - Tier 1";
    } else {
      return;
    }
  };

  return (
    <div className="item">
      <div className="summary">
        <div className="name-tag-wrap">
          <p className="name">{data.productName}</p>
          <div className="tag-wrap">
            {data.methodCode === "SYN" && (
              <p className="tag syndicate">Syndicate</p>
            )}
            {data.methodCode === "STD" && (
              <p className="tag standard">Standard</p>
            )}
            {data.systemCode === "PIC" && (
              <p className="tag one-less">Pick 1 Less</p>
            )}
            {data.systemCode === "GUA" && (
              <p className="tag guaranteed">Guaranteed</p>
            )}
            {data.systemCode === "STX" && (
              <p className="tag system">System 10</p>
            )}
            {data.systemCode === "ST9" && (
              <p className="tag system">System 9</p>
            )}
            {data.systemCode === "ST8" && (
              <p className="tag system">System 8</p>
            )}
            {data.systemCode === "ST7" && (
              <p className="tag system">System 7</p>
            )}
            {data.systemCode === "ST6" && (
              <p className="tag system">System 6</p>
            )}
            {data.multiplier === "Y" && (
              <p className="tag multiplier">Multiplier</p>
            )}
            {data.subscription !== null && (
              <p className="tag subscription">Subscription</p>
            )}
          </div>
          <div className="item-date">{formatPurchaseDate(data.purchased)}</div>
        </div>

        <div className="prize-result-wrap">
          <img
            src={data.productCode == "MM" ? MegaBallLogo : PowerBallLogo}
            alt={
              data.productCode == "MM"
                ? "USA Mega Lottery"
                : "USA Power Lottery"
            }
            className="game-logo"
          />
          <p className="prize">
            <span className="unit">{reward.mark} </span>
            {/* {loading ? (
              <Skeleton variant="rounded" width={300} height={50} />
            ) : (
              reward.amount
            )} */}
            {reward.amount}
            <span className="unit"> {reward.unit}</span>
          </p>

          <div className="result">
            {data.status === "P" ? (
              <p>Draw Pending</p>
            ) : data.status === "N" ? (
              <p>No win</p>
            ) : (
              <p className="win">
                Win
                <span>$ {Number(data.totalPrize) * Number(data.exchange)}</span>
              </p>
            )}
          </div>
          <div
            className="btn style01 md"
            onClick={() => goGame(data.productCode)}
          >
            PLAY AGAIN
          </div>
        </div>
      </div>

      {!open && (
        <div className="btn style02" onClick={() => setOpen((prev) => !prev)}>
          <span>See full draw details</span>
          <span className="material-symbols-rounded">expand_more</span>
        </div>
      )}

      {open && (
        <div className="detail">
          {data.status !== "P" && (
            <div className="latest-winning-number">
              <p className="tit">Winning Numbers</p>
              <div className="ball-wrap">
                {winningNums?.map((it, idx) => (
                  <div
                    key={idx}
                    className={`ball  ${
                      idx === 5
                        ? data.productCode === "PB"
                          ? "power"
                          : "mega"
                        : ""
                    }`}
                  >
                    {it}
                  </div>
                ))}
                <div
                  className={`ball ${
                    data.productCode === "PB" ? "power" : "mega"
                  }`}
                >
                  {winningSp}
                </div>
              </div>
              <p className="multi">Multiplier {data.multiplierValue}x</p>
            </div>
          )}

          <div className="myball-list">
            {data.methodCode !== "STD" && data.systemCode !== null
              ? data.nums.map((it, idx) => {
                  const numbers = it.num.split(",");
                  const sp = numbers.pop();
                  return (
                    <div key={it.idx}>
                      <p className="game-num">Game #{idx + 1}</p>
                      <div className="ball-wrap">
                        {numbers?.map((it, idx, arr) => (
                          <div key={idx} className={`ball`}>
                            {it}
                          </div>
                        ))}
                        <div className={`ball ${game}`}>{sp}</div>
                      </div>
                      <ShowNumMatch
                        nums={it.num}
                        code={data.productCode}
                        sysCode={data.systemCode}
                        winningNums={data.winningNumbers}
                      />
                    </div>
                  );
                })
              : data.nums.map((it, idx) => {
                  const numbers = it.num.split(",");
                  const sp = numbers.pop();
                  const winnings = data?.winningNumbers?.split(",");
                  const winSp = winnings?.pop();

                  const matchedRegular = numbers.filter((num) =>
                    winnings?.includes(String(num))
                  ).length;

                  const matchedSpecial = winSp === sp ? 1 : 0;

                  return (
                    <div key={it.idx}>
                      <p className="game-num">
                        Game #{idx + 1}
                        {data.status !== "P" && (
                          <span>
                            {rankMatcher(matchedRegular, matchedSpecial)}{" "}
                          </span>
                        )}
                      </p>
                      <div className="ball-wrap">
                        {numbers?.map((it, idx, arr) => (
                          <div
                            key={idx}
                            className={`ball ${
                              data.status !== "P" &&
                              winningNums &&
                              winningNums.includes(it) &&
                              "matching"
                            }`}
                          >
                            {it}
                          </div>
                        ))}
                        <div
                          className={`ball ${game} ${
                            data.status !== "P" &&
                            winningSp &&
                            sp === winningSp &&
                            "matching"
                          }`}
                        >
                          {sp}
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>

          <ul className="draw-info">
            <li>Total: ${data.cost}</li>
            <li>
              Draw:&nbsp;
              {/* {new Date(data.playdate).toLocaleString("en-AU")} */}
              {formatPurchaseDate(data.playdate)}
            </li>

            <li>
              Purchase:&nbsp;
              {/* {new Date(data.purchased).toLocaleString("en-AU")} */}
              {formatPurchaseDate(data.purchased)}
            </li>
            {data.subscription !== null && data.subscriptionCycle !== null && (
              <li>
                ( {data.subscriptionCycle} / {data.subscription} draw )
              </li>
            )}
          </ul>

          <div className="btn-wrap">
            {/* <div className="btn style02" onClick={() => imageSource()}>
              <span>View ticket</span>
            </div> */}
            <div className="btn style02" onClick={() => setOnReceipt(true)}>
              <span>View receipt</span>
            </div>
          </div>

          <div className="btn style02" onClick={() => setOpen((prev) => !prev)}>
            <span>Hide draw details</span>
            <span className="material-symbols-rounded">expand_less</span>
          </div>

          {onReceipt && (
            <ReceiptModal setOnReceipt={setOnReceipt} data={data} />
          )}
        </div>
      )}
    </div>
  );
};
