// tools
import { Dispatch, SetStateAction, useEffect, useState } from "react";

// ui
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import User from "../../tools/userInfo";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
  notificationFetch: (type: string, status: string) => void;
}

const AppNotification = ({ setOpen, notificationFetch }: ModalContent) => {
  const { info } = User();
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue(info.pushAlarm);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="notification sms">
      <div className="modal-tit-wrap">
        <h3 className="tit">App Push Notification</h3>
      </div>

      <div className="modal-cont-wrap">
        <FormControl>
          <RadioGroup
            aria-labelledby="email-option"
            name="email-option"
            value={selectedValue}
            onChange={handleChange}
            sx={{ width: "fit-contents", margin: "0 auto" }}
          >
            <div className="input-wrap">
              <FormControlLabel
                value="S"
                control={<Radio />}
                label="Subscribe to all news and benefits"
              />
              {selectedValue === "S" && (
                <p className="subtxt">
                  Get the latest updates and offers via app notifications
                </p>
              )}
            </div>
            <div className="input-wrap">
              <FormControlLabel
                value="R"
                control={<Radio />}
                label="Receive updates on Lotterys and Syndicates"
              />
              {selectedValue === "R" && (
                <p className="subtxt">
                  Receive updates on the USA Lotterys and Syndicates, so you
                  never miss the latest jackpot news
                </p>
              )}
            </div>

            <div className="input-wrap">
              <FormControlLabel
                value="A"
                control={<Radio />}
                label="Account-related notifications only"
              />
              {selectedValue === "A" && (
                <p className="subtxt">
                  Only receive notifications about your account, such as
                  security updates or profile changes
                </p>
              )}
            </div>
            <div className="input-wrap">
              <FormControlLabel value="N" control={<Radio />} label="None" />
              {selectedValue === "N" && (
                <p className="subtxt">
                  No SMS notifications will be sent to you. You can always
                  change this later
                </p>
              )}
            </div>
          </RadioGroup>
        </FormControl>
      </div>

      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
            onClick={() => notificationFetch("push", selectedValue)}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppNotification;
