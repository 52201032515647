// tools
import axios from "axios";
import User from "../tools/userInfo";
import { generateReportPageToPdf } from "../tools/pdf";
import { useState, useEffect, useRef } from "react";

// component
import ErrorPage from "./errorPage";

// ui
import { CircularProgress } from "@mui/material";

// img
import TicketMega from "../img/common/ticket-mega.png";
import TicketPower from "../img/common/ticket-power.png";
import createAxiosInstance from "../api/axiosConfig";
// import FavIcoChk from "../img/sub/favorite-ico-chk.svg";

interface Ticket {
  cost: string;
  count: number;
  drawnum: number;
  game1: string | null;
  game2: string | null;
  game3: string | null;
  game4: string | null;
  game5: string | null;
  orderType: string;
  idx: number;
  playdate: string;
  purchased: string;
  serial: string;
  type: string;
}

const Ticket = () => {
  const { api, token } = User();
  const { search } = location;
  const ticketRef = useRef<HTMLDivElement | null>(null);
  const [allow, setAllow] = useState("");
  const [data, setData] = useState<Ticket>({
    cost: "",
    count: 0,
    drawnum: 0,
    game1: null,
    game2: null,
    game3: null,
    game4: null,
    game5: null,
    orderType: "",
    idx: 0,
    playdate: "",
    purchased: "",
    serial: "",
    type: "",
  });

  const axiosInstance = createAxiosInstance(api);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const isTouchDevice =
    navigator.maxTouchPoints || "ontouchstart" in document.documentElement;

  useEffect(() => {
    if (search) {
      const idx = search.split("=")[1];

      axiosInstance
        .get(`/orders/${idx}`)
        .then(({ data }) => setData(data.data[0]))
        .catch(({ response }) => {});

      setTimeout(() => {
        setAllow("true");
      }, 1000);
    } else {
      setAllow("false");
    }
  }, []);

  const numbers = [data.game1, data.game2, data.game3, data.game4, data.game5];

  const indexMaker = (i: number) => {
    switch (i) {
      case 0:
        return "A";
        break;
      case 1:
        return "B";
        break;
      case 2:
        return "C";
        break;
      case 3:
        return "D";
        break;
      case 4:
        return "E";
        break;
    }
  };

  const markMaker = (i: number) => {
    const typeArray = data.orderType.split(",");

    switch (typeArray[i]) {
      case "A":
        return <span className="qp">QP</span>;
        break;
      case "F":
        return (
          <div className="ico-wrap">
            {/* <img src={FavIcoChk} alt="favorite" /> */}
          </div>
        );
        break;
      default:
        return null;
    }
  };

  const noSaveButton =
    !isTouchDevice && !navigator.userAgent.toLowerCase().includes("safari");

  return (
    <div className="sub ticket">
      <div className="inner">
        {allow === "true" ? (
          <div className="ticket-wrap">
            <div className="btn-wrap">
              {noSaveButton && (
                <div
                  className="btn style02"
                  onClick={() =>
                    generateReportPageToPdf(ticketRef.current, data.serial)
                  }
                >
                  <span className="material-symbols-outlined">download</span>{" "}
                  Save
                </div>
              )}
            </div>
            <div className="ticket-img-wrap" ref={ticketRef}>
              {data.type === "PB" ? (
                <img src={TicketPower} alt="Powerball" />
              ) : (
                <img src={TicketMega} alt="Mega Millions" />
              )}

              <div className="ticket-info">
                {/* 구매 복권 정보: 파워볼 or 메가 밀리언 */}
                <p className="f40 name">
                  {data.type === "PB" ? "Powerball" : "Mega Millions"}
                </p>

                {/** 추첨 회차 */}
                <p className="f18 draw-num">Draw Number #{data.drawnum}</p>
                {/** 복권 구매 번호 */}
                <div className="number-wrap">
                  {numbers
                    .filter((x) => x !== null)
                    .map((game, index) => {
                      const gameNum = game !== null ? game.split(",") : [];
                      return (
                        <p className="f18 numbers" key={`${game}_${index}`}>
                          <span className="count">{indexMaker(index)}</span>
                          {gameNum.map((el) => (
                            <span className="number" key={`${index}_${el}`}>
                              {Number(el) < 10 ? `0${el}` : el}&nbsp;
                            </span>
                          ))}
                          {markMaker(index)}
                        </p>
                      );
                    })}
                </div>

                {/** 추첨일 */}
                <p className="f18 play-date">Draw Date : {data.playdate}</p>
                <div className="bottom-area">
                  <p className="f18 multi">MULTIPLIER - NO</p>
                  {/** 티켓 serial number */}
                  <p className="f15 serial">{data.serial}</p>

                  {/** 구매 일시 */}
                  <p className="f15 purchased">
                    <span className="tit">Purchased : </span>
                    <span className="txt">{data.purchased}</span>
                  </p>

                  {/** 가격 */}
                  <p className="f24 cost">
                    <span className="tit">COST : </span>
                    <span className="txt">$ {data.cost}</span>
                  </p>
                </div>
                {/* <img src={image.front} alt="front" /> */}
              </div>
            </div>
          </div>
        ) : allow === "false" ? (
          <ErrorPage />
        ) : (
          <div
            style={{
              minHeight: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default Ticket;
