// tools
import { useEffect, useState } from "react";
import User from "../tools/userInfo";

// comp
import SynItem from "../components/syndicates/synItem";
import NoData from "../components/common/noData";
import createAxiosInstance from "../api/axiosConfig";
import Swal from "../tools/customAlert";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Select } from "@mui/material";
import MySynItem from "../components/syndicates/mySynItem";
import MetaTag from "../tools/SEOMetaTag";
import { useNavigate } from "react-router-dom";

// type
export interface Nums {
  idx: number;
  num: string;
}
export interface SyndicateInfo {
  idx: number;
  name: string;
  systemCode: string | null;
  auDrawTime: string;
  syndicateName: string;
  description: string;
  drawnum: number;
  games: number;
  jackpot: number;
  multiplier: string;
  price: string;
  productCode: string;
  ticketIssued: number;
  ticketSold: number;
  nums: Nums[];
  status: string;
  isOwner?: boolean | null;
}

interface SystemMenus {
  code: string;
  title: string;
}

const SystemMenu: SystemMenus[] = [
  { code: "All", title: "All" },

  { code: "STD", title: "Standard" },
  {
    code: "ST6",
    title: "System 6",
  },
  {
    code: "ST7",
    title: "System 7",
  },
  {
    code: "ST8",
    title: "System 8",
  },
  {
    code: "ST9",
    title: "System 9",
  },
  {
    code: "STX",
    title: "System 10",
  },
  {
    code: "PIC",
    title: "Pick 1 less",
  },
  {
    code: "GUA",
    title: "Guaranteed",
  },
  { code: "ONE", title: "$ 1 Syndicate" },
];

const Syndicates = () => {
  // tools
  const { api, token, setToken, setTotalInfo } = User();
  const axiosInstance = createAxiosInstance(api);
  const navigate = useNavigate();

  // state
  const [data, setData] = useState<SyndicateInfo[]>([]);
  const [owner, setOwner] = useState<SyndicateInfo | null>(null);
  const [remain, setRemain] = useState<SyndicateInfo[]>([]);
  const [oneGame, setOneGame] = useState<SyndicateInfo[]>([]);

  // filter
  const [selectFilter, setSelectFilter] = useState<string>("All");
  const [drawFilter, setDrawFilter] = useState(false);
  const [prizeFilter, setPrizeFilter] = useState(false);

  const path = window.location.search.split("?")[1];

  // 필터링된 데이터
  const filteredData =
    selectFilter === "All"
      ? remain
      : selectFilter === "ONE"
      ? oneGame
      : remain.filter((item) => item.systemCode === selectFilter);

  // 정렬
  const sortedData = [...filteredData].sort((a, b) => {
    const drawTimeA = new Date(a.auDrawTime).getTime();
    const drawTimeB = new Date(b.auDrawTime).getTime();
    const priceA = parseFloat(a.price);
    const priceB = parseFloat(b.price);

    // drawFilter === true → drawTime 내림차순(높은순)
    // drawFilter === false → drawTime 오름차순(낮은순)
    const drawTimeDiff = drawFilter
      ? drawTimeB - drawTimeA // desc
      : drawTimeA - drawTimeB; // asc

    if (drawTimeDiff !== 0) {
      return drawTimeDiff;
    }

    // prizeFilter === true → price 내림차순(높은순)
    // prizeFilter === false → price 오름차순(낮은순)
    const priceDiff = prizeFilter
      ? priceB - priceA // desc
      : priceA - priceB; // asc

    return priceDiff;
  });

  const getSynData = () => {
    axiosInstance
      .get(`/syndicate`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => console.error(error));
  };

  const linkToCreate = () => {
    if (owner !== null) {
      return;
    }

    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        allowOutsideClick: false,
      });
    } else {
      location.href = "/syndicates/create";
    }
  };

  useEffect(() => {
    getSynData();

    if (path === "one") {
      setSelectFilter("ONE");
      navigate("/syndicates");
    }
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      // 본인 생성 찾기
      const ownerData = data.find((item) => item.isOwner === true) || null;
      setOwner(ownerData);

      // 본인 제외 데이터
      const remainingData = data.filter((item) => item !== ownerData);
      setRemain(remainingData);
    }

    const oneGames = data.filter((item) => item.price === "1.00");
    setOneGame(oneGames);
  }, [data]);

  return (
    <>
      <MetaTag title="Syndicates" path="/syndicates" />

      <div className="sub syndicate">
        <div className="sub-top-area">
          <h3 className="tit">Syndicates</h3>
          <p className="txt">Play with a group and get more chances to win</p>

          {token && (
            <div
              className={`btn style01 md ${owner !== null && "disabled"}`}
              onClick={() => linkToCreate()}
            >
              {owner !== null ? "Already exists" : "Create Your Syndicate"}
            </div>
          )}
        </div>
        <div className="syn-filter-wrap">
          <div className="tit">
            <span className="material-symbols-rounded">tune</span>
            <p>Filter</p>
          </div>
          <div
            className={`updown filter ${drawFilter && "up"}`}
            onClick={() => {
              setDrawFilter(!drawFilter);
            }}
          >
            <p>Draw</p>
            <span className="material-symbols-rounded">arrow_drop_down</span>
          </div>
          <div
            className={`updown filter ${prizeFilter && "up"}`}
            onClick={() => {
              setPrizeFilter(!prizeFilter);
            }}
          >
            <p>Prize</p>
            <span className="material-symbols-rounded">arrow_drop_down</span>
          </div>
          <div className="flex items-center gap-5">
            <FormControl className="filter" sx={{ m: 1, minWidth: 150 }}>
              {/* <InputLabel id=""></InputLabel> */}
              <Select
                labelId=""
                id="System"
                value={selectFilter}
                // label="System"
              >
                {SystemMenu.map((it, idx) => (
                  <MenuItem
                    key={idx}
                    value={it.code}
                    onClick={() => setSelectFilter(it.code)}
                  >
                    {it.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="syndicate-wrap">
          {owner && <MySynItem data={owner} getSynData={getSynData} />}
          {sortedData.map((it) => (
            <SynItem key={it.idx} data={it} getSynData={getSynData} />
          ))}
        </div>
        {((data.length === 0 && sortedData.length === 0) ||
          (owner === null && sortedData.length === 0)) && (
          // 해당 게임이 1개라도 존재하지 않을 경우
          <NoData />
        )}
      </div>
    </>
  );
};

export default Syndicates;
