// SEOMetaTag.js
import React from "react";
import { Helmet } from "react-helmet-async";
import OGImg from "../img/common/lc_og.png";

interface MetaTagProps {
  title: string;
  path: string;
}

// for Meta
const MetaTag = ({ title, path }: MetaTagProps) => {
  // console.log(`Setting MetaTag - Title: ${title}, Path: ${path}`);
  return (
    <Helmet>
      <title>Lottery Cluster - {title}</title>
      <meta name="description" content="Well Come to Lottery Cluster" />
      <meta property="og:title" content={`Lottery Cluster - ${title}`} />
      <meta
        property="og:description"
        content={`Lottery Cluster ${title} page.`}
      />
      <meta
        property="og:image"
        content={`https://www.lotterycluster.com/lc_og.png`}
      />
      <meta
        property="og:url"
        content={`https://www.lotterycluster.com/${path}`}
      />
      <link rel="canonical" href={`https://www.lotterycluster.com/${path}`} />
    </Helmet>
  );
};

export default MetaTag;
