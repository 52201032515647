// tools

import { useLotto } from "../../App";
import amountMaker from "../../tools/amountMaker";
import axios from "axios";
import User from "../../tools/userInfo";

// ui
import { Skeleton } from "@mui/material";

// comp
import CountDown from "../common/countdown";
import BallSection from "./ballSection";
import SyndicateGame from "./syndicateGame";

// img
import PowerBlurBg from "../../img/main/power-blur-bg.svg";
import MegaBlurBg from "../../img/main/mega-blur-bg.svg";
// import PowerWhiteLogo from "../../img/common/power-white-logo.svg";
// import MegaWhiteLogo from "../../img/common/mega-white-logo.svg";
import PowerWhiteLogo from "../../img/common/USA_Power_lottery.png";
import MegaWhiteLogo from "../../img/common/USA_Mega_lottery.png";
import convertToUserTime from "../../tools/userTimeZone";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import megaLogo from "../../img/main/lca_mega.gif";
import bgVideoMega from "../../img/main/lcm02_1m.mp4";
import videoPosterMega from "../../img/main/lcm02_1m.jpg";
import powerLogo from "../../img/main/lca_power.gif";
import bgVideoPower from "../../img/main/lcm01_1m.mp4";
import videoPosterPower from "../../img/main/lcm01_1m.jpg";

const Game = () => {
  const { loading, lottery } = useLotto();
  const { api } = User();
  const navigate = useNavigate();
  const [megaImg, setMegaImg] = useState(1);
  const [powerImg, setPowerImg] = useState(1);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className="game-area-new">
      <SyndicateGame />
      {lottery.map((item) => {
        const type =
          item.type === "MM" ? "mega" : item.type === "PB" ? "power" : "";
        const reward = amountMaker(item.jackpot * Number(item.exchange));

        return (
          <div className="video-background" key={item.type}>
            <video
              playsInline
              // disablePictureInPicture
              // controlsList="nodownload nofullscreen"
              autoPlay
              muted
              loop
              id="background-video"
              poster={type === "mega" ? videoPosterMega : videoPosterPower}
              src={type === "mega" ? bgVideoMega : bgVideoPower}
            >
              <source src={bgVideoMega} type="video/mp4" /> Your browser does
              not support the video tag.
            </video>
            <div className="content-overlay">
              <div className="content-wrap">
                <div className="txt-wrap">
                  {loading ? (
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      style={{
                        width: "90%",
                        height: 60,
                        margin: "0 auto",
                      }}
                    />
                  ) : (
                    <p className="prize">
                      {item.isDrawdate ? (
                        ""
                      ) : (
                        <span className="unit">{reward.mark} </span>
                      )}
                      <span className="num">
                        {item.isDrawdate ? "Pending" : `${reward.amount}`}
                      </span>
                      <span className="unit">
                        {item.isDrawdate ? "" : reward.unit}
                      </span>
                    </p>
                  )}
                  <p className="time">
                    <CountDown
                      date={convertToUserTime(item.auOrderTime)}
                      pending={item.isDrawdate}
                    />
                  </p>
                </div>
                <div className="logo-wrap">
                  <img
                    className="item-logo"
                    src={type === "mega" ? megaLogo : powerLogo}
                    alt="USA MEGA LOTTERY"
                  />
                </div>
              </div>
              <div
                className="button2"
                onClick={() => {
                  navigate(`${type}?Q`);
                  handleScrollToTop();
                }}
              >
                PLAY NOW
              </div>
            </div>
          </div>
        );
      })}
      {/* results */}
      {/* <BallSection type={type} original={item.type} loading={loading} /> */}
      {/* <TestPrizeStatBar type={type} /> */}
    </section>
  );
};

export default Game;
