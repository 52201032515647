// tools
import { useEffect, useState } from "react";
import Swal from "../tools/customAlert";
import User from "../tools/userInfo";

// ui
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import FloatingGame from "../components/common/FloatingGame";
import createAxiosInstance from "../api/axiosConfig";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// img
import Livechat from "../img/icon/livechat-icon.svg";
import Facebook from "../img/icon/facebook-icon.svg";
import Instagram from "../img/icon/instagram-icon.svg";
import MetaTag from "../tools/SEOMetaTag";

const EmailUs = () => {
  const [method, setMethod] = useState("email");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [info, setInfo] = useState({
    name: "",
    email: "",
    phone: "",
    day: "",
    time: "",
    country: "",
    state: "",
    topic: "",
    content: "",
  });

  const [focused, setFocused] = useState({
    name: false,
    email: false,
    phone: false,
    day: false,
    time: false,
    country: false,
    state: false,
    topic: false,
    content: false,
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    day: false,
    time: false,
    country: false,
    state: false,
    topic: false,
    content: false,
  });

  const { api } = User();

  const axiosInstance = createAxiosInstance(api);

  const handleData = (key: string, value: string) => {
    setInfo((prev) => ({ ...prev, [key]: value }));
  };

  const matches = useMediaQuery("(max-width:480px)");

  const emailAxios = () => {
    // Callback
    if (method === "callback") {
      const requiredFields: (keyof typeof info)[] =
        info.country === "AU"
          ? [
              "name",
              "email",
              "phone",
              "day",
              "time",
              "country",
              "state",
              "topic",
              "content",
            ]
          : ["name", "phone", "day", "time", "country", "topic", "content"];

      const emptyField = requiredFields.find((field) => !info[field]);

      if (!emptyField) {
        if (emailError) {
          return;
        }

        axiosInstance
          .post(`/support/contact/callback`, info)
          .then((_) => {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Email has been sent successfully. We will respond as soon as possible.",
              allowOutsideClick: false,
              didClose: () => (location.href = "/"),
            });
          })
          .catch(({ response }) => {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
              allowOutsideClick: false,
              didClose: () => window.location.reload(),
            });
          });
      } else {
        Swal.fire({
          title: "Error",
          text: `You haven't filled out a required field: ${emptyField}`,
          icon: "error",
          allowOutsideClick: false,
          didClose: () => validateField(emptyField),
        });
      }
      // Email
    } else {
      const requiredFields: (keyof typeof info)[] = [
        "name",
        "email",
        "phone",
        "topic",
        "content",
      ];
      const emptyField = requiredFields.find((field) => !info[field]);

      if (!emptyField) {
        if (emailError) {
          return;
        }

        axiosInstance
          .post(`/support/contact/email`, info)
          .then((_) => {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Email has been sent successfully. We will respond as soon as possible.",
              allowOutsideClick: false,
              didClose: () => (location.href = "/"),
            });
          })
          .catch(({ response }) => {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
              allowOutsideClick: false,
              // didClose: () => window.location.reload(),
            });
          });
      } else {
        Swal.fire({
          title: "Error",
          text: `You haven't filled out a required field [ ${emptyField} ]`,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethod((event.target as HTMLInputElement).value);
    setInfo((prev) => ({
      ...prev,
      day: "",
      time: "",
      country: "",
      state: "",
      topic: "",
      content: "",
    }));
  };

  const validateField = (key: keyof typeof info) => {
    if (key === "email") {
      // 이메일 유효성 검사
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(info.email);
      setErrors((prev) => ({ ...prev, [key]: !isValidEmail }));
    } else {
      // 일반 필드 유효성 검사
      setErrors((prev) => ({ ...prev, [key]: info[key].trim() === "" }));
    }
  };

  const handleFocus = (key: keyof typeof focused) => {
    setFocused((prev) => ({ ...prev, [key]: true }));
  };

  const handleBlur = (key: keyof typeof focused) => {
    validateField(key);
    setFocused((prev) => ({ ...prev, [key]: false }));
  };

  useEffect(() => {
    if (info.email.length === 0) {
      setEmailError(false);
    }

    if (info.phone.length !== 0 && info.phone.length !== 10) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }, [info.email, info.phone]);

  return (
    <>
      <MetaTag title="Contact Us" path="/email" />

      <div className="sub email-us">
        <div className="mainWrap">
          <div className="call-wrap">
            <div className="sub-top-area">
              <h4 className="tit">
                <span className="material-symbols-rounded">call</span>
                Call Lottery Cluster{" "}
                <a href="tel:0000000000">&#40;00&#41; 1234 5678</a>
              </h4>
              <p className="txt">
                Our friendly Customer Support team are available to take your
                call Monday to Friday from 9am to 5pm AEST.
              </p>
            </div>
          </div>
          <div className="email-us-wrap">
            <div className="sub-top-area">
              <h4 className="tit">
                <span className="material-symbols-rounded">mail</span>Email Us
                or Request a Callback
              </h4>
              <p className="txt">
                Out team of lottery experts is prepared to provide a quick and
                thorough response to all your questions and concerns via email
              </p>
            </div>

            <form>
              <div className="input-wrap">
                <TextField
                  label="Name"
                  sx={{ width: "calc(50% - 6px)" }}
                  value={info.name}
                  onChange={({ target }) => handleData("name", target.value)}
                  onFocus={() => handleFocus("name")}
                  onBlur={() => handleBlur("name")}
                  error={errors.name}
                  helperText={errors.name && "Name is required."}
                />
              </div>
              <div className="input-wrap">
                <TextField
                  label="Email"
                  sx={{ width: "50%" }}
                  value={info.email}
                  onChange={({ target }) => {
                    handleData("email", target.value);
                    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                      target.value
                    );
                    setEmailError(!isValidEmail);
                  }}
                  error={emailError || (!info.email && errors.email)}
                  helperText={
                    !info.email && errors.email
                      ? "Email is required."
                      : emailError
                      ? "Please enter a valid email address."
                      : ""
                  }
                  onFocus={() => handleFocus("email")}
                  onBlur={() => handleBlur("email")}
                />
                <TextField
                  label="Phone"
                  sx={{ width: "50%" }}
                  value={info.phone}
                  onChange={({ target }) => {
                    const numericValue = target.value.replace(/[^0-9]/g, "");
                    handleData("phone", numericValue);
                  }}
                  onFocus={() => handleFocus("phone")}
                  onBlur={() => handleBlur("phone")}
                  error={phoneError || (!info.phone && errors.phone)}
                  helperText={
                    !info.phone && errors.phone
                      ? "Phone is required."
                      : phoneError
                      ? "Please enter a valid phone numbers."
                      : ""
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </div>

              <RadioGroup
                defaultValue="email"
                name="radio-buttons-group"
                onChange={handleChange}
              >
                <div className="input-wrap">
                  <FormControlLabel
                    value="email"
                    control={<Radio />}
                    label="Email"
                    sx={{ width: "50%" }}
                  />
                  <FormControlLabel
                    value="callback"
                    control={<Radio />}
                    label="Request Callback"
                    sx={{ width: "50%" }}
                  />
                </div>
              </RadioGroup>

              {method === "email" && (
                <>
                  <div className="input-wrap">
                    <TextField
                      label="Topic"
                      fullWidth
                      value={info.topic}
                      onChange={({ target }) =>
                        handleData("topic", target.value)
                      }
                      onFocus={() => handleFocus("topic")}
                      onBlur={() => handleBlur("topic")}
                      error={errors.topic}
                      helperText={errors.topic && "Topic is required."}
                    />
                  </div>
                  <TextField
                    label="Content"
                    fullWidth
                    multiline
                    rows={10}
                    defaultValue=""
                    onChange={({ target }) =>
                      handleData("content", target.value)
                    }
                    onFocus={() => handleFocus("content")}
                    onBlur={() => handleBlur("content")}
                    error={errors.content}
                    helperText={errors.content && "Content is required."}
                    inputProps={{ maxLength: 2000 }}
                  />
                </>
              )}

              {method === "callback" && (
                <>
                  <div className="input-wrap">
                    <FormControl sx={{ width: "50%" }}>
                      <InputLabel id="call-day">Preferred Call Day</InputLabel>
                      <Select
                        labelId="call-day"
                        id=""
                        value={info.day}
                        label="Preferred Call Day"
                        onChange={({ target }) =>
                          handleData("day", target.value)
                        }
                        error={errors.day}
                        onBlur={() => handleBlur("day")}
                        onFocus={() => handleFocus("day")}
                      >
                        <MenuItem value="MON">Monday</MenuItem>
                        <MenuItem value="TUE">Tuesday</MenuItem>
                        <MenuItem value="WED">Wednesday</MenuItem>
                        <MenuItem value="THU">Thursday</MenuItem>
                        <MenuItem value="FRI">Friday</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ width: "50%" }}>
                      <InputLabel id="call-time">Select Time</InputLabel>
                      <Select
                        labelId="call-time"
                        id=""
                        value={info.time}
                        label="Select Time"
                        onChange={({ target }) =>
                          handleData("time", target.value)
                        }
                        error={errors.time}
                        onBlur={() => handleBlur("time")}
                        onFocus={() => handleFocus("time")}
                      >
                        <MenuItem value="09:00-13:00">09:00 - 13:00</MenuItem>
                        <MenuItem value="13:00-17:00">13:00 - 17:00</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="input-wrap">
                    <FormControl sx={{ width: "calc(50% - 6px)" }}>
                      <InputLabel id="country">Select a Country</InputLabel>
                      <Select
                        labelId="country"
                        id=""
                        value={info.country}
                        label="Select a Country"
                        onChange={({ target }) =>
                          handleData("country", target.value)
                        }
                        error={errors.country}
                        onBlur={() => handleBlur("country")}
                        onFocus={() => handleFocus("country")}
                      >
                        <MenuItem value="AU">Australia</MenuItem>
                        <MenuItem value="NZ">New Zealand</MenuItem>
                      </Select>
                    </FormControl>

                    {info.country === "AU" && (
                      <FormControl sx={{ width: "50%" }}>
                        <InputLabel id="state">Select a State</InputLabel>
                        <Select
                          labelId="state"
                          id=""
                          value={info.state}
                          label="Select a State"
                          onChange={({ target }) =>
                            handleData("state", target.value)
                          }
                          error={errors.state}
                          onBlur={() => handleBlur("state")}
                          onFocus={() => handleFocus("state")}
                        >
                          <MenuItem value="ACT">ACT</MenuItem>
                          <MenuItem value="NSW">NSW</MenuItem>
                          <MenuItem value="NT">NT</MenuItem>
                          <MenuItem value="QLD">QLD</MenuItem>
                          <MenuItem value="SA">SA</MenuItem>
                          <MenuItem value="VIC">VIC</MenuItem>
                          <MenuItem value="WA">WA</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </div>

                  <div className="input-wrap">
                    <FormControl fullWidth>
                      <InputLabel id="call-reason">
                        Select Callback Reason
                      </InputLabel>
                      <Select
                        labelId="call-reason"
                        id=""
                        value={info.topic}
                        label="Select Callback Reason"
                        onChange={({ target }) =>
                          handleData("topic", target.value)
                        }
                        error={errors.topic}
                        onBlur={() => handleBlur("topic")}
                        onFocus={() => handleFocus("topic")}
                      >
                        <MenuItem value="1">
                          Assistance with creating an account or resetting
                          passwords
                        </MenuItem>
                        <MenuItem value="2">
                          Instructions on how to play our lotteries
                        </MenuItem>
                        <MenuItem value="3">
                          Support for responsible gambling resources
                        </MenuItem>
                        <MenuItem value="4">
                          Report an issue with website or app
                        </MenuItem>
                        <MenuItem value="5">
                          Reopen or close an account{" "}
                        </MenuItem>
                        <MenuItem value="6">Payment enquiry </MenuItem>
                        <MenuItem value="7">Other </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <TextField
                    label="Content"
                    fullWidth
                    multiline
                    rows={10}
                    value={info.content}
                    onChange={({ target }) =>
                      handleData("content", target.value)
                    }
                    onFocus={() => handleFocus("content")}
                    onBlur={() => handleBlur("content")}
                    error={errors.content}
                    helperText={errors.content && "Content is required."}
                    inputProps={{ maxLength: 2000 }}
                  />
                </>
              )}

              <div className="btn-wrap">
                <div
                  className="btn style01 md white"
                  onClick={() => emailAxios()}
                >
                  Send
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* 우측 플로팅  */}
        <div className="right-side">
          <FloatingGame type={""} />
          <div className="contact-chat-wrap">
            <div className="item livechat">
              <div className="txt-wrap">
                <p className="tit">
                  <img src={Livechat} alt="Live Chat" />
                  Live Chat
                </p>
                <p className="sum">
                  Our Live Chat hours are Monday to Friday from 9am to 5pm AEST
                </p>
                <div
                  className="btn style01 xs white"
                  onClick={() => {
                    window.open(
                      "https://tawk.to/chat/60e1c4d7d6e7610a49b1e5e6/1f9v7j3q1"
                    );
                  }}
                >
                  Chat now
                </div>
              </div>
            </div>
            <div className="item facebook">
              <div className="txt-wrap">
                <p className="tit">
                  <img src={Facebook} alt="Facebook" />
                  <span>Contact Us on Messenger</span>
                </p>
                <p className="sum">
                  Get in touch via Facebook Messenger. Our staff are always
                  happy to help.
                </p>
                <div
                  className="btn style01 xs white"
                  onClick={() => {
                    window.open(
                      "https://tawk.to/chat/60e1c4d7d6e7610a49b1e5e6/1f9v7j3q1"
                    );
                  }}
                >
                  Send message
                </div>
              </div>
            </div>
            <div className="item instagram">
              <div className="txt-wrap">
                <p className="tit">
                  <img src={Instagram} alt="Instagram" />
                  <span>Contact Us on Instagram</span>
                </p>
                <p className="sum">
                  Get in touch via Instagram. Our staff are always happy to
                  help.
                </p>
                <div
                  className="btn style01 xs white"
                  onClick={() => {
                    window.open(
                      "https://tawk.to/chat/60e1c4d7d6e7610a49b1e5e6/1f9v7j3q1"
                    );
                  }}
                >
                  Send message
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailUs;
