// tools
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { NumberGenerator } from "../../tools/numberGenerator";
import { RandomNumber } from "../../tools/random";
import { useLotto } from "../../App";
import { customAlphabet } from "nanoid";
import { BallSet } from "../../types/ball";
import Swal from "../../tools/customAlert";

// ui
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Switch from "@mui/material/Switch";
import Lottie from "lottie-react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";

// img
import FavIco from "../../img/sub/favorite-ico.svg";
import Lightning from "../../img/sub/lightning.json";

// comp
import User from "../../tools/userInfo";
import { update } from "../../tools/zustandUpdate";
import Favorites from "./favourites";
import createAxiosInstance from "../../api/axiosConfig";
import { useNavigate } from "react-router-dom";
import StdTicket from "./stdTicket";
import SysTicket from "./sysTicket";

// type
interface GameInfo {
  drawnum: number;
  jackpot: number;
  playdate: string;
  orderTime: string;
}

interface QuickProps {
  type: string;
  setLogin: Dispatch<SetStateAction<boolean>>;
  gameInfo: GameInfo;
}

interface GameMode {
  title: string;
  mode: string;
}

interface Nums {
  num: number[];
  method: string;
  q: boolean;
}

export interface SystemMenu {
  idx: number;
  name: string;
  code: string;
  description: string;
  regular: number;
  special: number;
  games: number;
}

export interface SystemNums {
  num: number[];
  special: number[];
  method: string;
  systemInfo: SystemMenu;
  q: boolean;
}

const gameMode: GameMode[] = [
  { title: "Quick Picks", mode: "Q" },
  { title: "Choose Numbers", mode: "C" },
  { title: "Systems", mode: "S" },
  { title: "Favourites", mode: "F" },
];
const gameSets: number[] = [1, 2, 3, 5, 10, 20];
const gameWeeks: number[] = [1, 5, 10, 20, 100];

const MyNumber = ({ type, setLogin, gameInfo }: QuickProps) => {
  const searchMode = location.search.slice(1) || "Q";
  const initialCnt = searchMode === "Q" || searchMode === "C" ? 4 : 0;

  const navigate = useNavigate();

  useEffect(() => {
    if (!location.search) {
      navigate("?Q", { replace: true });
    }
  }, [location.search]);

  // User custom
  const { info, api, token, setTotalInfo } = User();
  const { lottery, limitCart, checkNoLoginCart, noLoginCart } = useLotto();
  const axiosInstance = createAxiosInstance(api);

  // stat
  const [mode, setMode] = useState<string>(searchMode);
  const [multi, setMulti] = useState<boolean>(false);
  const [weeks, setWeeks] = useState<number>(1);
  const [drawOption, setDrawOption] = useState<boolean>(false);

  // standard
  const [cnt, setCnt] = useState<number>(initialCnt);
  const [game, setGame] = useState<Nums[]>([]);
  const [lastGame, setLastGame] = useState<boolean>(false);

  // system
  const [sysMenu, setSysMenu] = useState<SystemMenu[]>([]);
  const [selectMenu, setSelectMenu] = useState<SystemMenu | null>(null);
  const [sysGame, setSysGame] = useState<SystemNums[]>([]);

  // noLogin Cart
  const existingData = localStorage.getItem("noLgCart");
  const noLgCartArray = existingData ? JSON.parse(existingData) : [];

  // Limit Price
  const [limit, setLimit] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  // 중복 확인
  const usedLinesRef = useRef<Set<string>>(new Set());

  /** 중복 검사 */
  function generateUniqueLine(): number[] {
    const setInfo = BallSet[type];

    while (true) {
      const white = NumberGenerator(setInfo.white, []); // 내부에서 5개 중복 없이 뽑음
      const special = RandomNumber(setInfo.special);

      // 흰공(5개) 정렬
      const sortedWhite = [...white].sort((a, b) => a - b);

      // 문자열로 만들기. 구분자 이용 예) "1,2,3,4,5/6"
      const comboStr = sortedWhite.join(",") + "/" + special;

      // 중복시 다시 뽑기
      if (usedLinesRef.current.has(comboStr)) {
        continue;
      }

      // 없으면 등록 후 반환
      usedLinesRef.current.add(comboStr);
      return [...white, special];
    }
  }

  /** 스크롤 업 */
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const search = location.search;
    const tab = search.replace("?", "");

    if (tab) {
      setMode(tab);
    } else {
      setMode("Q");
    }
  }, [location.search, mode]);

  // useEffect : Mode Change
  useEffect(() => {
    const set = BallSet[type];

    if (mode === "C") {
      usedLinesRef.current.clear();
      const clearedGameArray = game.map(() => ({
        num: [0, 0, 0, 0, 0, 0],
        method: "M",
        q: false,
      }));
      setGame(clearedGameArray);
    } else if (mode === "Q") {
      usedLinesRef.current.clear();
      const randomGameArray = game.map(() => {
        const white = NumberGenerator(set.white, []);
        const special = RandomNumber(set.special);
        return {
          // num: [...white.sort((a, b) => a - b), special],
          num: [...white, special],
          method: "A",
          q: false,
        };
      });

      randomGameArray.forEach((item) => {
        const sortedWhite = [...item.num.slice(0, 5)].sort((a, b) => a - b);
        const sp = item.num[5];
        const comboStr = sortedWhite.join(",") + "/" + sp;
        usedLinesRef.current.add(comboStr);
      });

      setGame(randomGameArray);
    }
  }, [mode]);

  // useEffect : Cnt Change
  useEffect(() => {
    // 시스템 모드가 아니라면(예: STD/Q/C)만 해당
    if (mode === "S") return;

    if (mode === "C") {
      // 수동 모드(C) -> [0,0,0,0,0,0] 같은 빈 라인 (중복 고려X)
      const needed = cnt - game.length;
      if (needed > 0) {
        const newManualGames = Array(needed).fill({
          num: [0, 0, 0, 0, 0, 0],
          method: "M",
          q: false,
        });
        setGame((prev) => [...prev, ...newManualGames]);
      }
    } else if (mode === "Q") {
      // Q 모드(빠른 자동)
      const needed = cnt - game.length;
      if (needed > 0) {
        const newGames: any[] = [];
        for (let i = 0; i < needed; i++) {
          // “중복 없이” 한 줄 생성
          const line = generateUniqueLine();
          newGames.push({
            num: line,
            method: "A",
            q: false,
          });
        }
        // state 업데이트
        setGame((prev) => [...prev, ...newGames]);
      }
    }

    if (cnt === 1) {
      setLastGame(true);
    } else {
      setLastGame(false);
    }
  }, [cnt]);

  // gameMode
  useEffect(() => {
    if (mode === "S") {
      // usedLinesRef.current.clear();
      // setGame([]);

      const isType = type === "mega" ? "mm" : "pb";
      axiosInstance
        .get(`/systems/${isType}`)
        .then((res) => {
          setSysMenu(res.data.data);
          setSelectMenu(res.data.data[0]);
        })
        .catch((error) => console.log(error));
    } else {
      return;
    }
  }, [mode]);

  useEffect(() => {
    setSysGame([]);
    addSysGame();
  }, [selectMenu]);

  // func
  const countChange = (value: number) => {
    if (cnt > 100 - value) {
      Swal.fire({
        title: "Error",
        text: "You cannot exceed 100 games per purchase.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }
    if (cnt < 101) {
      setCnt((prev) => prev + value);
    }
  };

  /** 게임 추가 */
  const addSysGame = () => {
    if (selectMenu) {
      const newSys = Array(selectMenu.regular).fill(0);
      const newSpecial =
        selectMenu.code === "GUA" ? [0] : Array(selectMenu.special).fill(0);

      setSysGame((prev) => [
        ...prev,
        {
          num: newSys,
          special: newSpecial,
          method: "M",
          q: false,
          systemInfo: { ...selectMenu },
        },
      ]);
    }
  };

  /** 회차 추가 */
  const handleWeeks = (type: string) => {
    if (type === "plus") {
      if (weeks === 100) {
        return;
      } else {
        setWeeks((prev) => prev + 1);
        return;
      }
    } else if (type === "minus") {
      if (weeks === 1) {
        return;
      } else {
        setWeeks((prev) => prev - 1);
      }
    }
  };

  const handleChangeWeeks = (value: number) => {
    const numericValue = Number(value);

    if (isNaN(numericValue)) return;

    if (numericValue > 100) {
      setWeeks(100);
    } else if (numericValue < 0) {
      setWeeks(1);
    } else {
      setWeeks(numericValue);
    }
  };

  const handleClear = (
    target: number,
    isSystem: boolean,
    isPlaying: boolean
  ) => {
    if (isPlaying) {
      return;
    } else {
      if (!isSystem) {
        setGame((prev) =>
          prev.map((item, idx) =>
            idx === target ? { ...item, num: [0, 0, 0, 0, 0, 0] } : item
          )
        );
      } else {
        setSysGame((prev) =>
          prev.map((item, idx) =>
            idx === target
              ? {
                  ...item,
                  num: Array(item.systemInfo.regular).fill(0),
                  special: Array(item.systemInfo.special).fill(0),
                }
              : item
          )
        );
      }
    }
  };

  /** 모든 공에서 숫자 비우기 */
  const handleAllClear = () => {
    if (isPlaying) {
      return;
    }

    Swal.fire({
      icon: "question",
      title: "Clear all game numbers?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (mode === "S") {
          setSysGame((prev) =>
            prev.map((it) => ({
              ...it,
              num: Array(it.systemInfo.regular).fill(0),
              special: [0],
            }))
          );
        } else {
          setGame((prev) =>
            prev.map((it) => ({
              ...it,
              num: [0, 0, 0, 0, 0, 0],
            }))
          );
        }
      } else {
        return;
      }
    });
  };

  /** 게임 삭제 */
  const handleDelete = (
    target: number,
    isSystem: boolean,
    isPlaying: boolean
  ) => {
    if (isPlaying) return;

    if (!isSystem) {
      if (target >= 0 && target < game.length && cnt > 1) {
        // 삭제될 게임
        const toRemove = game[target];

        // [1] 흰공(앞 5개) 정렬
        const sortedWhite = [...toRemove.num.slice(0, 5)].sort((a, b) => a - b);
        // [2] 스페셜공(마지막)
        const special = toRemove.num[5];
        // [3] 문자열로
        const comboStr = sortedWhite.join(",") + "/" + special;

        // Set 에서 제거
        usedLinesRef.current.delete(comboStr);

        // game state 업데이트
        setGame((prev) => prev.filter((_, idx) => idx !== target));
        setCnt((prev) => prev - 1);
      }
    } else {
      if (target >= 0 && target < sysGame.length) {
        setSysGame((prev) => prev.filter((_, idx) => idx !== target));
      } else {
        console.error(`Invalid target index: ${target}`);
      }
    }
  };

  const NumberGenerator2 = (
    maxNumber: number,
    usedNumbers: number[] = [],
    count: number = 5
  ) => {
    const numbers: number[] = [];
    while (numbers.length < count) {
      const rand = Math.floor(Math.random() * maxNumber) + 1;
      if (!numbers.includes(rand) && !usedNumbers.includes(rand)) {
        numbers.push(rand);
      }
    }
    return numbers;
  };

  /** 빈값 채우기 */
  const fillEmptySlots = (
    whiteBalls: number[],
    specialBall: number | number[],
    type: keyof BallSet,
    isSystem: boolean = false,
    regularCount: number = 5,
    specialCount: number = 1
  ) => {
    const set = BallSet[type];

    // 이미 선택된 공 제외 (0)
    const usedWhiteBalls = whiteBalls.filter((num) => num !== 0);

    // 남은자리 랜덤생성 (이미 선택된 공은 제외)
    let newWhiteBalls = NumberGenerator2(
      set.white,
      usedWhiteBalls,
      regularCount
    ).slice(0, regularCount - usedWhiteBalls.length);

    // 비어있는 공만 채움
    const filledWhiteBalls = whiteBalls.map((num) =>
      num === 0 ? newWhiteBalls.pop()! : num
    );

    // 스페셜 공 처리
    let filledSpecialBall;
    if (isSystem && Array.isArray(specialBall)) {
      const usedSpecialBalls = specialBall.filter((num) => num !== 0);
      let newSpecialBalls = NumberGenerator2(
        set.special,
        usedSpecialBalls,
        specialCount
      ).slice(0, specialCount - usedSpecialBalls.length);
      filledSpecialBall = specialBall.map((num) =>
        num === 0 ? newSpecialBalls.pop()! : num
      );
    } else {
      filledSpecialBall =
        specialBall === 0 ? RandomNumber(set.special) : specialBall;
    }

    return {
      // whiteBalls: filledWhiteBalls.sort((a, b) => a - b),
      whiteBalls: filledWhiteBalls,
      specialBalls: filledSpecialBall,
    };
  };

  /** 자동 선택 */
  const handleQuickPick = (
    target: number,
    isSystem: boolean = false,
    isPlay: boolean
  ) => {
    if (isPlay) {
      return;
    } else {
      if (isSystem) {
        const allSelected =
          sysGame[target].num.every((num) => num !== 0) &&
          sysGame[target].special[0] !== 0;
        const noSelected =
          sysGame[target].num.every((num) => num === 0) &&
          sysGame[target].special[0] === 0;

        if (!noSelected && !allSelected) {
          setSysGame((prevGame) =>
            prevGame.map((gameItem, idx) => {
              if (idx === target) {
                const { whiteBalls, specialBalls } = fillEmptySlots(
                  gameItem.num,
                  gameItem.special,
                  type,
                  true,
                  gameItem.systemInfo.regular,
                  gameItem.systemInfo.special
                );
                return {
                  ...gameItem,
                  num: whiteBalls,
                  special: specialBalls as number[],
                  method: "A",
                };
              }
              return gameItem;
            })
          );
        } else {
          const intervalId = setInterval(() => {
            setSysGame((prevGame) =>
              prevGame.map((gameItem, idx) => {
                if (idx === target) {
                  const white = NumberGenerator2(
                    BallSet[type].white,
                    [],
                    gameItem.systemInfo.regular
                  );
                  const special = RandomNumber(BallSet[type].special);
                  return {
                    ...gameItem,
                    // num: [...white.sort((a, b) => a - b)],
                    num: [...white],
                    special: [special],
                    method: "A",
                    q: true,
                  };
                }
                return gameItem;
              })
            );
          }, 100);

          setTimeout(() => {
            clearInterval(intervalId);
            setSysGame((prevGame) =>
              prevGame.map((gameItem, idx) => {
                if (idx === target) {
                  return {
                    ...gameItem,
                    q: false,
                  };
                }
                return gameItem;
              })
            );
          }, 1000);
        }
      } else {
        const allSelected = game[target].num.every((num) => num !== 0);
        const noSelected = game[target].num.every((num) => num === 0);

        if (!noSelected && !allSelected) {
          // 선택된 공이 일부만 있을 때 -> 비어있는 공만 채움
          setGame((prevGame) =>
            prevGame.map((gameItem, idx) => {
              if (idx === target) {
                const { whiteBalls, specialBalls } = fillEmptySlots(
                  gameItem.num.slice(0, 5),
                  gameItem.num[5],
                  type
                );
                return {
                  ...gameItem,
                  num: [...whiteBalls, specialBalls as number],
                  method: "A",
                };
              }
              return gameItem;
            })
          );
        } else {
          // 선택된 공이 없거나 모든 공이 이미 선택된 경우 -> 랜덤으로 반복해서 채움
          const intervalId = setInterval(() => {
            setGame((prevGame) =>
              prevGame.map((gameItem, idx) => {
                if (idx === target) {
                  const white = NumberGenerator(BallSet[type].white, []);
                  const special = RandomNumber(BallSet[type].special);
                  return {
                    ...gameItem,
                    // num: [...white.sort((a, b) => a - b), special],
                    num: [...white, special],
                    method: "A",
                    q: true,
                  };
                }
                return gameItem;
              })
            );
          }, 100);

          setTimeout(() => {
            clearInterval(intervalId);
            setGame((prevGame) =>
              prevGame.map((gameItem, idx) => {
                if (idx === target) {
                  return {
                    ...gameItem,
                    q: false,
                  };
                }
                return gameItem;
              })
            );
          }, 1000);
        }
      }
    }
  };

  // api tools
  const gameType = () => {
    if (type === "mega") {
      return lottery[1];
    } else {
      return lottery[0];
    }
  };
  const isMode = mode === "Q" ? "Q" : mode === "C" ? "C" : "S";
  const isOption = mode === "Q" || mode === "C" ? "STD" : "SYS";
  const total = mode !== "S" ? game.length : sysGame.length;

  const fullSysGame = sysGame.filter((it) => !it.num.includes(0));
  const sysTotal = fullSysGame.reduce(
    (total, it) => total + it.systemInfo.games,
    0
  );

  const generalMultiplier = multi ? 10.45 : 7;
  const systemMultiplier = multi ? 10.45 : 7;

  const totalAmount = total * generalMultiplier * weeks;
  const sysTotalAmount = sysTotal * systemMultiplier * weeks;

  // api
  const addCart = () => {
    if (limit) return;

    let incompleteGame = false;
    if (mode === "S") {
      incompleteGame = sysGame.some(
        (g) =>
          g.num.includes(0) ||
          (g.special.includes(0) && g.systemInfo.special > 0)
      );
    } else {
      incompleteGame = game.some((g) => g.num.includes(0));
    }

    const data = {
      productCode: type === "mega" ? "MM" : "PB",
      productName: type === "mega" ? "US Mega Millions" : "US Powerball",
      methodCode: isOption,
      systemCode:
        isMode === "Q"
          ? "QPK"
          : isMode === "C"
          ? "CHN"
          : isMode === "S"
          ? selectMenu?.code
          : "",
      multiplier: multi ? "Y" : "N",
      draws: weeks,
      ticketQty: isMode === "S" ? sysTotal : total,
      subscription: drawOption ? "Y" : "N",
      totalAmount: isMode === "S" ? sysTotalAmount : totalAmount,
      nums:
        mode === "S" && selectMenu?.code === "GUA"
          ? sysGame.map((it) => {
              return {
                num: [...it.num.sort((a, b) => a - b), "G"],
                method: it.method,
              };
            })
          : mode === "S" && selectMenu?.code === "PIC"
          ? sysGame.map((it) => {
              const sortNums = [it.num[0], it.num[1], it.num[2], it.num[3]];
              return {
                num: [...sortNums.sort((a, b) => a - b), "G", it.special[0]],
                method: it.method,
              };
            })
          : mode === "S"
          ? sysGame.map((it) => {
              return {
                num: [...it.num.sort((a, b) => a - b), it.special[0]],
                method: it.method,
              };
            })
          : game.map((it) => {
              const { q, ...rest } = it;
              const special = it.num[it.num.length - 1];
              const sortedNums = [...it.num.slice(0, -1)].sort((a, b) => a - b);
              return {
                ...rest,
                num: [...sortedNums, special],
              };
            }),
    };

    const dataArray = [];
    dataArray.push(data);

    if (info.cart >= limitCart || noLoginCart >= limitCart) {
      Swal.fire({
        icon: "warning",
        title: `Cannot purchase\n more than ${limitCart} games\n in the cart.`,
      });
    } else if (total === 0) {
      Swal.fire({
        icon: "warning",
        title: "Has no games.",
      });
    } else {
      if (incompleteGame) {
        Swal.fire({
          title: "There is an\n incomplete game.",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      } else if (token.length > 0) {
        axiosInstance
          .post(`/carts`, dataArray)
          .then((res) => {})
          .catch((err) => {
            console.error(
              "Error:",
              err.response ? err.response.status : err.message
            );
          });

        Swal.fire({
          icon: "success",
          title: "Completed\n Add to Cart!",
          text: "If you want to buy again, add a game",
          allowOutsideClick: false,
          didClose: () => {
            if (mode === "S") {
              setSysGame([]);
              scrollUp();
              // window.location.reload();
            } else {
              // window.location.reload();
              setGame([]);
              setCnt(0);
              scrollUp();
            }
            update(api, token, setTotalInfo);
          },
        });

        // 비로그인 카트
      } else if (token.length === 0) {
        const randomKeyNum = customAlphabet("1234567890", 12);

        if (mode === "Q" || mode === "C") {
          const data = {
            idx: Number(randomKeyNum()),
            productCode: type === "mega" ? "MM" : "PB",
            productName: type === "mega" ? "US Mega Millions" : "US Powerball",
            methodCode: isOption,
            systemCode: isMode === "Q" ? "QPK" : "CHN",
            syndicateIdx: null,
            jackpot: gameType().jackpot,
            playdate: [gameType().playdate],
            multiplier: multi ? "Y" : "N",
            draws: weeks,
            ticketQty: total,
            subscription: drawOption ? "Y" : "N",
            totalAmount: totalAmount,
            nums: game.map((it, index) => {
              const { q, num, method } = it;

              const special = it.num[it.num.length - 1];
              const sortedNums = [...it.num.slice(0, -1)].sort((a, b) => a - b);

              return {
                idx: index + 1,
                num: [...sortedNums, special].join(","),
                method: method,
              };
            }),
          };

          noLgCartArray.push(data);

          localStorage.setItem("noLgCart", JSON.stringify(noLgCartArray));

          Swal.fire({
            icon: "success",
            title: "Completed\n Add to Cart!",
            text: "If you want to buy again, add a game",
            allowOutsideClick: false,
            didClose: () => {
              // window.location.reload();
              setGame([]);
              setCnt(0);
              checkNoLoginCart();
              scrollUp();
            },
          });
        } else if (mode === "S") {
          const data = {
            idx: Number(randomKeyNum()),
            productCode: type === "mega" ? "MM" : "PB",
            productName: type === "mega" ? "US Mega Millions" : "US Powerball",
            methodCode: isOption,
            syndicateIdx: null,
            systemCode: selectMenu?.code,
            multiplier: multi ? "Y" : "N",
            draws: weeks,
            jackpot: gameType().jackpot,
            playdate: [gameType().playdate],
            ticketQty: sysTotal,
            subscription: drawOption ? "Y" : "N",
            totalAmount: sysTotalAmount,
            nums:
              mode === "S" && selectMenu?.code === "GUA"
                ? sysGame.map((it, index) => {
                    return {
                      idx: index + 1,
                      num: [...it.num, "G"].join(","),
                      method: it.method,
                    };
                  })
                : mode === "S" && selectMenu?.code === "PIC"
                ? sysGame.map((it, index) => {
                    return {
                      idx: index + 1,
                      method: it.method,
                      num: [
                        it.num[0],
                        it.num[1],
                        it.num[2],
                        it.num[3],
                        "G",
                        it.special[0],
                      ].join(","),
                    };
                  })
                : mode === "S"
                ? sysGame.map((it, index) => {
                    return {
                      idx: index + 1,
                      num: [...it.num, it.special[0]].join(","),
                      method: it.method,
                    };
                  })
                : "",
          };
          noLgCartArray.push(data);
          localStorage.setItem("noLgCart", JSON.stringify(noLgCartArray));

          Swal.fire({
            icon: "success",
            title: "Completed\n Add to Cart!",
            text: "If you want to buy again, add a game",
            allowOutsideClick: false,
            didClose: () => {
              // window.location.reload();
              setSysGame([]);
              setCnt(0);
              checkNoLoginCart();
              scrollUp();
            },
          });
        }
      }
    }
  };

  const addFavoriteApi = (data: any) => {
    if (limit) {
      Swal.fire({
        icon: "error",
        title: "Maximum amount per single game capped at $3,000",
        timer: 2000,
      });
      return;
    }

    axiosInstance
      .post(`/favorites`, data)
      .then((res) =>
        Swal.fire({
          icon: "success",
          title: "Sucess to add Favorites",

          timer: 2000,
        })
      )
      .catch((error) => {
        if (error.response && error.response.status !== 200) {
          const errorMessage = error.response.data?.message || "Error";
          Swal.fire({
            icon: "error",
            text: errorMessage,
          });
        } else {
          console.log(error);
        }
      });
  };

  const addFavorite = () => {
    if (isPlaying) {
      return;
    }

    let incompleteGame = false;
    if (mode === "S") {
      incompleteGame = sysGame.some(
        (g) =>
          g.num.includes(0) ||
          (g.special.includes(0) && g.systemInfo.special > 0)
      );
    } else {
      incompleteGame = game.some((g) => g.num.includes(0));
    }

    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        allowOutsideClick: false,
      });
    } else if (incompleteGame) {
      Swal.fire({
        title: "There is an incomplete game.",
        icon: "warning",
        allowOutsideClick: false,
      });
    } else if (isOption === "STD") {
      const data = {
        productCode: type === "mega" ? "MM" : "PB",
        methodCode: "STD",
        systemCode: isMode === "Q" ? "QPK" : "CHN",
        ticketQty: total,
        nums: game.map((it) => {
          return {
            ...it,
          };
        }),
      };
      addFavoriteApi(data);
    } else if (isOption === "SYS" && selectMenu?.code.includes("ST")) {
      const data = {
        productCode: type === "mega" ? "MM" : "PB",
        methodCode: "SYS",
        systemCode: selectMenu?.code,
        multiplier: multi,
        ticketQty: sysTotal,
        nums: sysGame.map((it) => {
          return {
            ...it,
            num: [...it.num, it.special[0]],
          };
        }),
      };
      addFavoriteApi(data);
    } else if (isOption === "SYS" && selectMenu?.code === "GUA") {
      const data = {
        productCode: type === "mega" ? "MM" : "PB",
        methodCode: "SYS",
        systemCode: selectMenu?.code,
        ticketQty: sysTotal,
        nums: sysGame.map((it) => {
          return {
            ...it,
            num: [...it.num, "G"],
          };
        }),
      };
      addFavoriteApi(data);
    } else if (isOption === "SYS" && selectMenu?.code === "PIC") {
      const data = {
        productCode: type === "mega" ? "MM" : "PB",
        methodCode: "SYS",
        systemCode: selectMenu?.code,
        ticketQty: sysTotal,
        nums: sysGame.map((it) => {
          return {
            ...it,
            num: [...it.num, "G", it.special[0]],
          };
        }),
      };
      addFavoriteApi(data);
    }
  };

  const isTablet = useMediaQuery("(max-width:1080px)");
  const [cartInfoOpen, setCartInfoOpen] = useState(true);

  useEffect(() => {
    if (isTablet) {
      setCartInfoOpen(false);
    }
  }, [isTablet]);

  useEffect(() => {
    if (totalAmount > 3000 || sysTotalAmount > 3000) {
      if (weeks === 1 || drawOption) {
        setLimit(true);
      } else {
        setLimit(false);
      }
    } else {
      setLimit(false);
    }
  }, [totalAmount, sysTotalAmount, drawOption, weeks]);

  // tootip
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const checkPlaying = () => {
    const a = sysGame.filter((it) => it.q === true);
    const b = game.filter((it) => it.q === true);

    if (a.length > 0 || b.length > 0) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    checkPlaying();
  }, [game, sysGame]);

  return (
    <div className="game-wrap">
      <div className={`standard ${mode === "F" ? "fav" : ""}`}>
        <div className="step method">
          <h4 className="tit">Select Mode</h4>
          <div className="btn-wrap">
            {gameMode.map((it, idx) => (
              <div
                key={idx}
                className={`btn ${it.mode === mode ? "picked" : ""}`}
                onClick={() => {
                  setMode(it.mode);
                  navigate(`/${type}?${it.mode}`);
                }}
              >
                {it.title}
              </div>
            ))}
          </div>
        </div>

        {mode === "Q" || mode === "C" ? (
          <div className="step count">
            <h4 className="tit">Add Quantity</h4>
            <div className="btn-wrap">
              {gameSets.map((it, idx) => (
                <div key={idx} className="btn" onClick={() => countChange(it)}>
                  {it}
                </div>
              ))}
            </div>
          </div>
        ) : mode === "S" ? (
          <div className="step sys-type">
            <h4 className="tit">Select Lottery Systems</h4>
            <FormControl fullWidth>
              <InputLabel id="lottery-system">Lottery Systems</InputLabel>
              <Select
                labelId="lottery-system"
                label="Lottery Systems"
                defaultValue={0}
              >
                {sysMenu.map((it, idx) => (
                  <MenuItem
                    key={it.idx}
                    value={idx}
                    onClick={() => setSelectMenu(it)}
                  >
                    {it.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className="exp" style={{ color: "#4f5253", marginTop: "1rem" }}>
              {selectMenu?.description}
            </p>
          </div>
        ) : (
          <Favorites type={type} gameInfo={gameInfo} setLogin={setLogin} />
        )}

        {mode === "Q" || mode === "S" || mode === "C" ? (
          <>
            <div className="step ticket">
              <div className="tit-wrap">
                {mode === "S" && <h4 className="tit">Choose your numbers</h4>}
                <div className="btn-wrap">
                  <div className="btn fav" onClick={() => addFavorite()}>
                    Add favourite
                    <img src={FavIco} alt="Favorite numbers" />
                  </div>
                  <div className="btn" onClick={handleAllClear}>
                    Clear all
                    <span className="material-symbols-rounded">mop</span>
                  </div>
                </div>
              </div>
              {/* gameList */}
              <div className={`ticket-wrap ${mode === "S" ? "sys" : ""}`}>
                {((mode !== "S" && total === 0) ||
                  (mode === "S" && sysGame.length === 0)) && (
                  <div className="ticket-wrap-empty">
                    No games added.
                    <span>If you want more, please add more games.</span>
                  </div>
                )}
                {mode === "Q" || mode === "C" ? (
                  game.map((it, idx) => (
                    <StdTicket
                      key={idx}
                      id={Number(idx) + 1}
                      type={type}
                      mode={mode}
                      Nums={it}
                      game={game}
                      setGame={setGame}
                      handleClear={() => handleClear(idx, false, it.q)}
                      handleDelete={() => handleDelete(idx, false, it.q)}
                      handleQuickPick={() => handleQuickPick(idx, false, it.q)}
                      lastGame={lastGame}
                      playing={it.q}
                    />
                  ))
                ) : mode === "S" ? (
                  <>
                    {sysGame.map((it, idx) => (
                      <SysTicket
                        key={idx}
                        nums={it}
                        id={Number(idx) + 1}
                        type={type}
                        token={token}
                        selectMenu={selectMenu}
                        game={sysGame}
                        setGame={setSysGame}
                        handleClear={() => handleClear(idx, true, it.q)}
                        handleDelete={() => handleDelete(idx, true, it.q)}
                        handleQuickPick={() => handleQuickPick(idx, true, it.q)}
                        playing={it.q}
                      />
                    ))}

                    {/* Add a game 버튼을 map 바깥에 렌더링 */}
                    <div
                      className="btn style01 md spc add-game"
                      onClick={() => addSysGame()}
                    >
                      <span className="material-symbols-rounded">
                        add_circle
                      </span>
                      Add a game
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={`step multiplier ${multi ? "on" : ""}`}>
              {multi && (
                <Lottie
                  animationData={Lightning}
                  className="lightning"
                  loop={false}
                />
              )}
              <h4 className="tit">Multiplier</h4>
              <div className="layout">
                <FormControlLabel
                  control={
                    <Switch onChange={() => setMulti((prev) => !prev)} />
                  }
                  label="Multiplier added"
                />

                <div className="txt-wrap">
                  <p className="txt">$ 3.45 per combination</p>
                  <p className="exp">
                    Boost the potential winnings up to 5 times on all
                    non-jackpot prizes!
                  </p>
                </div>
              </div>
            </div>
            <div className="step subscribe">
              <div className="tit-wrap">
                <h4 className="tit">
                  How many draws
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <p>
                          How many draws would you like to use these numbers
                          for?
                        </p>
                      }
                    >
                      <span
                        className="material-symbols-rounded"
                        onClick={handleTooltipOpen}
                      >
                        info
                      </span>
                    </Tooltip>
                  </ClickAwayListener>
                </h4>
              </div>
              <div className={`btn-wrap ${drawOption && "disabled"}`}>
                {gameWeeks.map((it, idx) => (
                  <div
                    key={idx}
                    className={`btn num ${
                      it === weeks && !drawOption ? "picked" : ""
                    }`}
                    onClick={() => {
                      drawOption ? null : setWeeks(it);
                    }}
                  >
                    {it}
                  </div>
                ))}

                <div className="quantity-control">
                  <div
                    className="btn quantity"
                    onClick={() => (drawOption ? null : handleWeeks("minus"))}
                  >
                    -
                  </div>
                  <TextField
                    value={weeks}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        drawOption ? null : handleChangeWeeks(Number(value));
                      }
                    }}
                    onBlur={() => {
                      if (weeks === 0) {
                        setWeeks(1);
                      }
                    }}
                  />
                  <div
                    className="btn quantity"
                    onClick={() => (drawOption ? null : handleWeeks("plus"))}
                  >
                    +
                  </div>
                </div>
              </div>

              <FormControlLabel
                control={
                  <Switch
                    onChange={() => {
                      setDrawOption((prev) => !prev);
                      setWeeks(1);
                    }}
                  />
                }
                sx={{ marginTop: "12px" }}
                label="Rolling Subscription/ Stop at Jackpot"
              />
              {!drawOption ? (
                <p className="exp">
                  Your ticket will automatically enter every draw of the series
                  that you’ve purchased.
                </p>
              ) : (
                <p className="exp">
                  Subscribe until a Jackpot winner is announced.
                </p>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      {mode === "Q" || mode === "S" || mode === "C" ? (
        <div className="pick-info-wrap">
          {cartInfoOpen && (
            <div className="pick-info">
              <span
                className="btn material-symbols-rounded"
                onClick={() => setCartInfoOpen(!cartInfoOpen)}
              >
                keyboard_arrow_down
              </span>
              <ul>
                <li>
                  <span className="tit">Total Count</span>
                  {mode === "S" ? sysTotal : total}
                </li>
                <li>
                  <span className="tit">Option</span>
                  <span className="txt">
                    {mode !== "S" ? "Standard" : "System"}
                  </span>
                </li>
                <li>
                  <span className="tit">Multiplier</span>
                  <span className="txt">{multi ? "O" : "X"}</span>
                </li>
                <li>
                  <span className="tit">Draw</span>
                  <span className="txt">
                    {drawOption ? "Stop at Jackpot" : weeks}
                  </span>
                </li>
              </ul>
            </div>
          )}
          <div className="total-wrap">
            <div
              className="total"
              onClick={
                isTablet ? () => setCartInfoOpen(!cartInfoOpen) : undefined
              }
            >
              <p className="tit">Total</p>
              <p className="txt">
                {drawOption ? (
                  multi ? (
                    <>
                      {mode === "S" && selectMenu ? (
                        <>
                          $ {(selectMenu?.games * total * 10.45).toFixed(2)}{" "}
                          <span>/ draw</span>
                        </>
                      ) : (
                        <>
                          $ {(10.45 * total).toFixed(2)} <span>/ draw</span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {mode === "S" && selectMenu ? (
                        <>
                          $ {(selectMenu?.games * total * 7).toFixed(2)}{" "}
                          <span>/ draw</span>
                        </>
                      ) : (
                        <>
                          $ {(7 * total).toFixed(2)} <span>/ draw</span>
                        </>
                      )}
                    </>
                  )
                ) : (
                  <>
                    {weeks !== 1 ? (
                      !multi ? (
                        <>
                          {mode === "S" && selectMenu ? (
                            <>
                              $ {(selectMenu?.games * total * 7).toFixed(2)}{" "}
                              <span>/ draw</span>
                            </>
                          ) : (
                            <>
                              $ {(7 * total).toFixed(2)} <span>/ draw</span>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {mode === "S" && selectMenu ? (
                            <>
                              $ {(selectMenu?.games * total * 10.45).toFixed(2)}
                              <span>/ draw</span>
                            </>
                          ) : (
                            <>
                              $ {(10.45 * total).toFixed(2)} <span>/ draw</span>
                            </>
                          )}
                        </>
                      )
                    ) : (
                      (() => {
                        if (multi && mode !== "S") {
                          // 멀티 && 일반 게임
                          return `$ ${totalAmount.toFixed(2)}`;
                        } else if (!multi && mode !== "S") {
                          // 싱글 && 일반 게임
                          return `$ ${totalAmount.toFixed(2)}`;
                        } else if (multi && mode === "S") {
                          // 멀티 && 시스템 게임
                          return `$ ${sysTotalAmount.toFixed(2)}`;
                        } else if (!multi && mode === "S") {
                          // 싱글 && 시스템 게임
                          return `$ ${sysTotalAmount.toFixed(2)}`;
                        }
                      })()
                    )}
                  </>
                )}
              </p>
            </div>
            {limit && (
              <>
                <p className="notice" style={{ color: "red" }}>
                  Maximum amount per game
                  <br />
                  $3,000
                </p>
                <br />
              </>
            )}
            {!isPlaying ? (
              <div
                className={`btn style01 md spc ${limit && "disabled"}`}
                onClick={addCart}
              >
                <span>Add To Cart</span>

                <span className="material-symbols-rounded">shopping_cart</span>
              </div>
            ) : (
              <div className={`btn style01 md spc ${limit && "disabled"}`}>
                <div className="loader"></div>

                <span>Playing</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyNumber;
