import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Swal from "../../tools/customAlert";
import User from "../../tools/userInfo";
import createAxiosInstance from "../../api/axiosConfig";

// ui
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FavItem from "../../components/fav/favItem";
import { useNavigate } from "react-router-dom";
import { customAlphabet } from "nanoid";

// type
interface GameInfo {
  drawnum: number;
  jackpot: number;
  playdate: string;
  orderTime: string;
}

interface FavNums {
  idx: number;
  method: string;
  num: string;
}

export interface FavData {
  idx: number;
  productCode: string;
  methodCode: string;
  systemCode: string;
  ticketQty: number;
  nums: FavNums[];
  isSaved?: string;
  isNew?: boolean;
}

export interface FavMenuInfo {
  idx: number;
  name: string;
  code: string;
  description: string;
  regular: number;
  special: number;
  games: number;
  price: string;
  optionPrice: string;
}

interface FavProps {
  type: string;
  setLogin: Dispatch<SetStateAction<boolean>>;
  gameInfo: GameInfo;
}

const MyFav = () => {
  // tools
  const { api, token, info, setTotalInfo } = User();
  // stat
  const [favList, setFavList] = useState<FavData[]>();
  const [compare, setCompare] = useState<FavData[] | undefined>();
  const [selectMenu, setSelectMenu] = useState<FavMenuInfo[]>();
  const [activeEdit, setActiveEdit] = useState<number | null>(null);

  const axiosInstance = createAxiosInstance(api);
  const navigate = useNavigate();

  /** get Fav */
  const getFav = () => {
    axiosInstance
      .get(`/favorites`)
      .then((res) => {
        setFavList(res.data.data);
        setCompare(res.data.data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getFav();
  }, []);

  /** remove Fav */
  const removeFav = (idx: string) => {
    Swal.fire({
      icon: "question",
      title: "Remove from favorites?",
      showCancelButton: true,
      confirmButtonText: " Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const targetItem = favList?.find((it) => it.idx === Number(idx));

        if (targetItem?.isSaved === "N") {
          const updatedFavList = favList?.filter(
            (it) => it.idx !== Number(idx)
          );
          setFavList(updatedFavList);
          setActiveEdit(null);
        } else {
          axiosInstance
            .delete(`/favorites/${idx}`)
            .then((res) => {
              setActiveEdit(null);
              getFav();
            })
            .catch((error) => console.error(error));
        }
      }
    });
  };

  //   useEffect(() => {
  //     // TODO: 구매 페이지 전체에서 token 값을 확인해서 favorite에서만 체크하도록 임시 조치함
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const hasFParam = urlParams.has("F");

  //     if (hasFParam && !token) {
  //       Swal.fire({
  //         icon: "warning",
  //         title: "Members Only",
  //         text: "This method is only for Lottery Cluster members",
  //         confirmButtonColor: "#af7af1",
  //         cancelButtonColor: "#876aab",
  //         allowOutsideClick: false,
  //       });
  //       setTimeout(() => {
  //         window.location.href = "/";
  //       }, 1000);
  //     } else {
  //       getFav();
  //       axiosInstance
  //         .get(`/product/favorites/`)
  //         .then((res) => setSelectMenu(res.data.data))
  //         .catch((error) => console.error(error));
  //     }
  //   }, []);

  return (
    <div className="game-wrap">
      <div className="favourite">
        <div className="fav-add-wrap">
          <h4 className="tit">
            <span className="material-symbols-rounded">add</span>
            Add to Favourites
          </h4>
          <FormControl fullWidth>
            <InputLabel id="lottery">Select Lottery</InputLabel>
            <Select labelId="lottery" id="lottery" label="Select Lottery">
              <MenuItem onClick={() => navigate("/power?F")}>
                USA Power Lottery
              </MenuItem>
              <MenuItem onClick={() => navigate("/mega?F")}>
                USA Mega Lottery
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        {favList?.map((it, idx) => (
          <FavItem
            key={it.idx}
            idx={idx}
            type={it.productCode === "PB" ? "power" : "mega"}
            data={it}
            removeFav={removeFav}
            getFav={getFav}
            setFavList={setFavList}
            compare={compare}
            selectMenu={selectMenu}
            activeEdit={activeEdit}
            setActiveEdit={setActiveEdit}
            isMy={true}
          />
        ))}
      </div>
    </div>
  );
};

export default MyFav;
