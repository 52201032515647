// tools
import { useEffect, useState } from "react";

// type
interface Nums {
  num: number[];
  method: string;
  q: boolean;
}

interface ItemProps {
  type: string;
  mode: string;
  id: number;
  Nums: {
    num: number[];
    method: string;
  };
  game: Nums[];
  lastGame: boolean;
  playing: boolean;
  setGame: React.Dispatch<React.SetStateAction<Nums[]>>;
  handleClear: () => void;
  handleDelete: () => void;
  handleQuickPick: () => void;
}

const StdTicket = ({
  type,
  id,
  mode,
  Nums,
  game,
  lastGame,
  playing,
  setGame,
  handleClear,
  handleDelete,
  handleQuickPick,
}: ItemProps) => {
  // stat
  const [ballEditOpen, setBallEditOpen] = useState<boolean>(false);
  const [autoClose, setAutoClose] = useState<boolean>(true);

  // constant
  const white = Nums.num.slice(0, 5);
  const special = Nums.num[5];

  // useEffect
  useEffect(() => {
    if (mode === "C") {
      setBallEditOpen(true);
      setAutoClose(true);
    } else {
      setBallEditOpen(false);
      setAutoClose(false);
    }
  }, [mode]);

  useEffect(() => {
    const whiteBallSelected = white.filter((n) => n !== 0).length === 5;
    const specialBallSelected = special !== 0;

    if (autoClose && whiteBallSelected && specialBallSelected) {
      setBallEditOpen(false);
    }
  }, [white, special, autoClose]);

  // func
  const ballMaker = () => {
    return Array.from({ length: type === "mega" ? 70 : 69 }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => handlePick(id - 1, ballNum, false, game, setGame)}
          className={`ball ${white.includes(ballNum) ? "picked" : ""}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  const specialMaker = () => {
    return Array.from({ length: type === "mega" ? 25 : 26 }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => handlePick(id - 1, ballNum, true, game, setGame)}
          className={`ball ${special === ballNum ? "picked" : ""}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  const handleDelChk = (lastGame: boolean, playing: boolean) => {
    if (lastGame) {
      return;
    }
    if (playing) {
      return;
    } else {
      handleDelete();
    }
  };

  const handlePick = (
    gameIndex: number,
    ballNum: number,
    isSpecial: boolean,
    game: Nums[],
    setGame: React.Dispatch<React.SetStateAction<Nums[]>>
  ) => {
    setGame((prevGame) => {
      const updatedGame = [...prevGame].map((g, idx) =>
        idx === gameIndex ? { ...g, num: [...g.num], method: "M" } : g
      );

      const currentGame = updatedGame[gameIndex];
      const { num } = currentGame;

      if (isSpecial) {
        const whiteBalls = num.slice(0, 5).filter((n) => n !== 0);

        currentGame.num[5] = currentGame.num[5] === ballNum ? 0 : ballNum;
      } else {
        const index = currentGame.num.slice(0, 5).indexOf(ballNum);
        if (index !== -1) {
          currentGame.num[index] = 0;
        } else {
          const emptyIndex = currentGame.num.slice(0, 5).indexOf(0);
          if (emptyIndex !== -1) {
            currentGame.num[emptyIndex] = ballNum;
          }
        }
      }

      const sortedWhiteBalls = currentGame.num
        .slice(0, 5)
        .filter((n) => n !== 0);
      // .sort((a, b) => a - b);
      const specialBallFinal =
        currentGame.num[5] !== 0 ? [currentGame.num[5]] : [0];

      currentGame.num = [
        ...sortedWhiteBalls,
        ...Array(5 - sortedWhiteBalls.length).fill(0),
        ...specialBallFinal,
      ];

      return updatedGame;
    });
  };

  return (
    <div className="ticket">
      <div className="ticket-tit-wrap">
        <p>Game #{id}</p>
        <div className="btn-wrap">
          <span
            className={`btn material-symbols-rounded ${
              playing ? "disabled" : ""
            }`}
            onClick={() => {
              handleQuickPick();
            }}
          >
            bolt
          </span>
          <span
            className={`btn material-symbols-rounded ${
              playing ? "disabled" : ""
            }`}
            onClick={handleClear}
          >
            mop
          </span>

          <span
            className={`btn material-symbols-rounded ${
              lastGame || playing ? "disabled" : ""
            }`}
            onClick={() => handleDelChk(lastGame, playing)}
          >
            delete
          </span>
        </div>
      </div>

      <div className="picked-ball-wrap">
        <div
          className="ball-wrap"
          onClick={() => setBallEditOpen(!ballEditOpen)}
        >
          {white.map((it, idx) => (
            <div key={idx} className="ball">
              {it === 0 ? "" : it}
            </div>
          ))}
          <div className={`ball ${type === "mega" ? "mega" : "power"}`}>
            {special === 0 ? "" : special}
          </div>
          <span
            className={`material-symbols-rounded ${ballEditOpen ? "open" : ""}`}
            onClick={() => {
              setBallEditOpen(true);
              setAutoClose(false);
            }}
          >
            keyboard_arrow_down
          </span>
        </div>

        <div className={`vertical-trans ${ballEditOpen ? "open" : "close"}`}>
          <div className="edit-ball-wrap">
            <div className="ball-count">
              <div className="tit-count-wrap">
                <p className="tit">Ball</p>
                <p className="count">
                  <span>{white.filter((it) => it !== 0).length}</span> of 5
                </p>
              </div>
            </div>

            <div className="ball-wrap">{ballMaker()}</div>

            <div className="tit-count-wrap" style={{ marginTop: "12px" }}>
              <p className="mega">{type === "mega" ? "Mega" : "Power"} ball</p>
              <p className="count">
                <span>{special !== 0 ? "1" : "0"}</span> of 1
              </p>
            </div>

            <div className={`ball-wrap ${type === "mega" ? "mega" : "power"}`}>
              {specialMaker()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StdTicket;
