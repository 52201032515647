// components
import MagazineBar from "../components/main/magazine";
import News from "../components/main/news";
import Faq from "../components/main/faq";
import Event from "../components/main/event";
import Game from "../components/main/game";
import MetaTag from "../tools/SEOMetaTag";

const Main = () => {
  return (
    <>
      <MetaTag title="Home" path="" />
      <div className="home">
        {/* Event area */}
        <Event />
        {/* Game area */}
        <Game />
        {/* Magazine area */}
        <MagazineBar />
        {/* Faq area */}
        <Faq />
        {/* News area */}
        <News />
      </div>
    </>
  );
};

export default Main;
