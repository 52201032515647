import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { List } from "./draws";

//  img
import PowerBallLogo from "../../img/common/USA_Power_lottery.png";
import MegaBallLogo from "../../img/common/USA_Mega_lottery.png";
import { BallSet } from "../../types/ball";

interface ReceiptProps {
  setOnReceipt: Dispatch<SetStateAction<boolean>>;
  data: List;
}

type MenuItem = {
  idx: number;
  name: string;
  code: string;
  regular: number;
  special: number;
  games: number;
  price: string;
};

const ReceiptModal = ({ setOnReceipt, data }: ReceiptProps) => {
  // const printReceipt = () => {
  //   const printSection = document.querySelector(".print-section");
  //   if (printSection) {
  //     window.print();
  //   }
  // };

  const matchingType = (methodCode: string, systemCode: string | null) => {
    if (methodCode === "STD") {
      return "Standard";
    }

    if (methodCode === "SYS" && systemCode === "STX") {
      return "System 10";
    }
    if (methodCode === "SYS" && systemCode === "ST9") {
      return "System 9";
    }
    if (methodCode === "SYS" && systemCode === "ST8") {
      return "System 8";
    }
    if (methodCode === "SYS" && systemCode === "ST7") {
      return "System 7";
    }
    if (methodCode === "SYS" && systemCode === "ST6") {
      return "System 6";
    }
    if (methodCode === "SYS" && systemCode === "GUA") {
      return "Guaranteed";
    }
    if (methodCode === "SYS" && systemCode === "PIC") {
      return "Pick 1 Less";
    }

    if (methodCode === "SYN" && systemCode === "STX") {
      return "Syndicate System 10";
    }

    if (methodCode === "SYN" && systemCode === "ST9") {
      return "Syndicate System 9";
    }

    if (methodCode === "SYN" && systemCode === "ST8") {
      return "Syndicate System 8";
    }

    if (methodCode === "SYN" && systemCode === "ST7") {
      return "Syndicate System 7";
    }

    if (methodCode === "SYN" && systemCode === "ST6") {
      return "Syndicate System 6";
    }

    if (methodCode === "SYN" && systemCode === "GUA") {
      return "Syndicate Guaranteed";
    }

    if (methodCode === "SYN" && systemCode === "PIC") {
      return "Syndicate Pick 1 Less";
    }
  };

  return (
    <div className="rcpm">
      <div className="rcpm_wrap">
        <div className="rcpm_head">
          <h3>Receipt</h3>
          {/* <span onClick={printReceipt} className="btn material-symbols-rounded">
            print
          </span> */}
          <span
            className="btn material-symbols-rounded"
            onClick={() => setOnReceipt(false)}
          >
            close
          </span>
        </div>
        <div className="rcpm_body print-section">
          <div className="rcpm_side"></div>
          <div className="rcpm_main">
            <div className="rcpm_main_h">
              <div className="rcpm_logo">
                <img
                  src={data.productCode === "PB" ? PowerBallLogo : MegaBallLogo}
                  alt={
                    data.productCode === "PB"
                      ? "USA Power Lottery"
                      : "USA Mega Lottery"
                  }
                  className="game-logo"
                />
              </div>
              <div className="rcpm_title">
                <p>{data.productName}</p>
                <p>
                  {matchingType(data.methodCode, data.systemCode)} (
                  <span style={{ margin: "0px 2px" }}>{data.gameCnt}</span>
                  Game )
                </p>
              </div>
            </div>
            <div className="rcpm_main_c">
              <ul style={{ display: "block" }}>
                {data.nums.map((it, idx) => (
                  <div key={idx}>
                    {/* <span>Game {counter()}</span> */}
                    <ViewNums
                      nums={it.num}
                      code={data.productCode}
                      sysCode={data.systemCode}
                      methodCode={data.methodCode}
                      groupIdx={idx}
                      gameCnt={data.gameCnt}
                    />
                  </div>
                ))}
              </ul>
            </div>
            <div className="rcpm_main_f">
              <p>Draw Date: {new Date(data.playdate).toDateString()}</p>
              <p>Purchased: {data.purchased}</p>
              <p>Multiplier: {data.multiplier === "N" ? "N" : "Y"}</p>
              <p>Cost: $ {data.cost}</p>
              <p>{data.serial}</p>
            </div>
          </div>
        </div>
        {/* <div className="rcpm_foot">
          <Pagination count={10} variant="outlined" shape="rounded" />
        </div> */}
      </div>
      <div className="dimmed" onClick={() => setOnReceipt(false)}></div>
    </div>
  );
};

export default ReceiptModal;

interface ViewNumsProps {
  nums: string;
  code: string;
  sysCode: string;
  groupIdx: number;
  gameCnt: number;
  methodCode: string;
}

let globalStartIdx = 1;

const ViewNums = ({
  nums,
  code,
  sysCode,
  gameCnt,
  methodCode,
}: ViewNumsProps) => {
  const [list, setList] = useState<number[][]>([]);

  const counter = () => {
    return globalStartIdx++;
  };

  const num = nums.split(",");
  const special = num.pop();

  const typeChk = (target: string) => {
    if (target === "MM") {
      return "mega";
    } else {
      return "power";
    }
  };

  /** 시스템 숫자 생성기 */
  const getCombinations = (arr: string[], selectNumber: number) => {
    const results: any[] = [];
    if (selectNumber === 1) return arr.map((value) => [value]);

    arr.forEach((fixed, index, origin) => {
      const rest = origin.slice(index + 1);
      const combinations = getCombinations(rest, selectNumber - 1);
      const attached = combinations.map((combination) => [
        fixed,
        ...combination,
      ]);
      results.push(...attached);
    });

    return results;
  };

  const settingNums = (type: string) => {
    if (type === "GUA" || type === "PIC") {
      return;
    } else {
      setList(getCombinations(num, 5));
    }
  };

  useEffect(() => {
    settingNums(code);
  }, []);

  useEffect(() => {
    settingNums(code);
    globalStartIdx = 1;

    return () => {
      globalStartIdx = 1;
    };
  }, [code]);

  if (sysCode === "PIC") {
    const excludedNumbers = num.slice(0, 4).map(Number);
    const totalWhiteBalls = BallSet[typeChk(code)].white;
    const remainingBalls = Array.from(
      { length: totalWhiteBalls },
      (_, i) => i + 1
    ).filter((ball) => !excludedNumbers.includes(ball));

    return (
      <div className="number-wrap">
        {remainingBalls.map((currentBall) => (
          <div className="number" key={currentBall}>
            <p>#{counter()}</p>
            <div className="ball-wrap">
              {excludedNumbers.map((it, idx) => (
                <div className="ball" key={`fixed-${idx}`}>
                  {it}
                </div>
              ))}
              <div className="ball">{currentBall}</div>
              <div
                className={`ball ${
                  typeChk(code) === "mega" ? "mega" : "power"
                }`}
              >
                {special}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else if (sysCode !== "GUA") {
    return (
      <div className="number-wrap">
        {list.map((it, idx) => (
          <div className="number" key={idx}>
            <p>#{counter()}</p>
            <div className="ball-wrap">
              {it.slice(0, 5).map((num, idx) => (
                <div className="ball" key={idx}>
                  {num}
                </div>
              ))}
              <div
                className={`ball ${
                  typeChk(code) === "mega" ? "mega" : "power"
                }`}
              >
                {special}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="number-wrap">
        {Array.from(
          { length: BallSet[typeChk(code)].special },
          (_, specialIdx) => (
            <div className="number" key={specialIdx}>
              <p>#{counter()}</p>
              <div className="ball-wrap">
                {num.map((it, idx) => (
                  <div className="ball" key={idx}>
                    {it}
                  </div>
                ))}
                <div
                  className={`ball ${
                    typeChk(code) === "mega" ? "mega" : "power"
                  }`}
                >
                  {specialIdx + 1}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
};
