import { toDate, toZonedTime, format } from "date-fns-tz";

export default function convertToUserTime(serverTime: string) {
  try {
    // 1. serverTime 유효성 확인
    if (!serverTime || typeof serverTime !== "string") {
      // console.error("Invalid serverTime value:", serverTime);
      return "null";
    }

    // console.log("Converting serverTime:", serverTime);

    // 2. 서버 시간을 UTC 기준으로 변환
    const utcDate = toDate(serverTime, { timeZone: "Australia/Sydney" });

    // 3. 사용자 타임존 확인
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // 4. 사용자 타임존으로 변환
    const userDate = toZonedTime(utcDate, userTimeZone);

    // 5. 변환된 시간 포맷팅
    return format(userDate, "yyyy-MM-dd HH:mm:ss", {
      timeZone: userTimeZone,
    });
  } catch (error) {
    console.error(
      "Error in convertToUserTime:",
      error,
      "serverTime:",
      serverTime
    );
    return "Invalid Date";
  }
}
