// tools
import { useEffect, useState } from "react";
import User from "../../tools/userInfo";

// components
import Limit from "./limit";
import PersonalInfo from "./personalInfo";
import ChangePassword from "./changePassword";
import PayMethod from "./payMethod";
import WithdrwalMethod from "./withdrawalMethod";
import Delete from "./delete";
import { CustModal } from "../common/commonElements";
import EmailNotification from "./emailNotification";
import SmsNotification from "./smsNotification";
import AppNotification from "./appNotification";
import AccountStatus from "./accountStatus";
import createAxiosInstance from "../../api/axiosConfig";
import Swal from "../../tools/customAlert";
import { reset, update } from "../../tools/zustandUpdate";
import { useLotto } from "../../App";

const Settings = () => {
  const { api, token, info, setToken, setTotalInfo } = User();
  const { isPc, logoutAxios } = useLotto();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");

  const axiosInstance = createAxiosInstance(api);

  const handleClickOpen = (type: string) => {
    setContent(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    update(api, token, setTotalInfo);
  };

  const notificationFetch = (type: string, status: string) => {
    const data = {
      type: type,
      status: status,
    };
    axiosInstance
      .patch(`/auth/notification`, data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: res.data.message,
          didClose: () => {
            setOpen(false);
            handleUpdate();
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  const changeView = (type: string) => {
    switch (type) {
      case "personal":
        return <PersonalInfo setOpen={setOpen} open={open} />;
        break;

      case "password":
        return <ChangePassword setOpen={setOpen} open={open} />;
        break;

      case "paymethod":
        return <PayMethod setOpen={setOpen} open={open} />;
        break;

      case "withdrawalmethod":
        return <WithdrwalMethod setOpen={setOpen} />;
        break;

      case "limit":
        return <Limit setOpen={setOpen} />;
        break;

      case "email":
        return (
          <EmailNotification
            setOpen={setOpen}
            notificationFetch={notificationFetch}
          />
        );
        break;

      case "sms":
        return (
          <SmsNotification
            setOpen={setOpen}
            notificationFetch={notificationFetch}
          />
        );
        break;

      case "app":
        return (
          <AppNotification
            setOpen={setOpen}
            notificationFetch={notificationFetch}
          />
        );
        break;

      case "status":
        return (
          <AccountStatus
            setOpen={setOpen}
            open={open}
            logoutAxios={logoutAxios}
          />
        );
        break;
    }
  };

  return (
    <div className="setting">
      <div className="item">
        <h4 className="tit">
          <span className="material-symbols-rounded">person</span>Personal
          Information
        </h4>
        <div className="edit-wrap">
          <div
            className="btn style01 md"
            onClick={() => handleClickOpen("personal")}
          >
            Personal Information
          </div>
          <div
            className="btn style01 md"
            onClick={() => handleClickOpen("password")}
          >
            Change Password
          </div>
        </div>
      </div>

      <div className="item">
        <h4 className="tit">
          <span className="material-symbols-rounded">
            account_balance_wallet
          </span>
          My Wallet
        </h4>
        {/* 미등록시 버튼에 .unverified 추가 */}
        <div
          className="btn style01 md unverified"
          onClick={() => {
            handleClickOpen("paymethod");
          }}
        >
          Payment Method
          <span className="material-symbols-rounded unverified">error</span>
        </div>
        <div
          className="btn style01 md"
          onClick={() => handleClickOpen("limit")}
        >
          Spend Limit
        </div>
      </div>

      <div className="item">
        <h4 className="tit">
          <span className="material-symbols-rounded">notifications</span>
          Notification
        </h4>

        <div
          className="btn style01 md"
          onClick={() => handleClickOpen("email")}
        >
          Email Option
        </div>

        <div className="btn style01 md" onClick={() => handleClickOpen("sms")}>
          SMS Option
        </div>

        {/* <div className="btn style01 md" onClick={() => handleClickOpen("app")}>
          App Push Notification
        </div> */}
        {!isPc && (
          <div
            className="btn style01 md"
            onClick={() => handleClickOpen("app")}
          >
            App Push Notification
          </div>
        )}

        {/* <div
          className="btn"
          onClick={() => {
            handleClickOpen("withdrawalmethod");
          }}
        >
          Edit
        </div> */}
      </div>

      {/* <div className="item">
        <h4 className="tit">Weekly Spend Limit</h4>
        <p className="txt">
          Your current weekly spend limit is{" "}
          <span>${info.weeklySpendLimit.toLocaleString("au")}</span>{" "}
          <span>(Remaining: ${info.remaining.toLocaleString("au")} )</span>
        </p>
      </div> */}

      <div className="item">
        <h4 className="tit">
          <span className="material-symbols-rounded">settings</span>Account
          Setting
        </h4>

        <div
          className="btn style01 md"
          onClick={() => {
            handleClickOpen("status");
          }}
        >
          Account Status
        </div>
        <div
          className="btn style01 md"
          onClick={() => (location.href = "/email")}
        >
          Contact Us
        </div>
      </div>

      <CustModal open={open} setOpen={setOpen} onClose={handleClose}>
        {changeView(content)}
      </CustModal>
      {/* <Delete /> */}

      <div className="btn style01 sm logout" onClick={() => logoutAxios()}>
        Logout
      </div>
    </div>
  );
};

export default Settings;
