import { useState, useEffect, startTransition } from "react";
import User from "../../tools/userInfo";

import useMediaQuery from "@mui/material/useMediaQuery";
import Pagination from "@mui/material/Pagination";
import { CircularProgress, Skeleton } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import NoData from "../common/noData";
import createAxiosInstance from "../../api/axiosConfig";
import { CustomDatePickerProps } from "./draws";
import Swal from "../../tools/customAlert";

interface List {
  balance: number;
  cash: number;
  createdAt: string;
  description: string;
  idx: number;
  status: string;
  prize?: string;
  isPending?: boolean;
}

const CustomDay = (props: PickersDayProps<Dayjs>) => {
  const { day, ...other } = props;
  const isToday = day.isSame(dayjs(), "day");
  return <PickersDay {...other} day={day} />;
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      shouldDisableDate={() => false}
      slots={{
        day: (dayProps) => <CustomDay {...dayProps} />,
      }}
      slotProps={{
        textField: {
          error: false,
        },
      }}
    />
  );
};

const SelectTypeFilter = [
  { code: "cash", title: "Deposit" },
  { code: "prize", title: "Winning" },
];

const History = () => {
  const { api, token } = User();
  const [page, setPage] = useState({
    max: 1,
    current: 1,
  });
  const [list, setList] = useState<List[]>([]);
  const [select, setSelect] = useState<string>("cash");
  const [loading, setLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(30, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const axiosInstance = createAxiosInstance(api);

  const handleStartDateChange = (date: Dayjs | null) => {
    if (!date || (endDate && date.isAfter(endDate, "day"))) {
      Swal.fire({
        icon: "warning",
        title: "The start date cannot be later than the end date.",
        didClose: () => setStartDate(dayjs().subtract(30, "day")),
      });
      return;
    }

    if (endDate && date.add(1, "year").isBefore(endDate, "day")) {
      Swal.fire({
        icon: "warning",
        title: "The date range cannot exceed one year.",
        didClose: () => setStartDate(dayjs().subtract(30, "day")),
      });
      return;
    }

    startTransition(() => {
      setStartDate(date);
    });
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    if (!date || (startDate && date.isBefore(startDate, "day"))) {
      Swal.fire({
        icon: "warning",
        title: "The end date cannot be earlier than the start date.",
        didClose: () => setEndDate(dayjs()),
      });
      return;
    }

    if (startDate && startDate.add(1, "year").isBefore(date, "day")) {
      Swal.fire({
        icon: "warning",
        title: "The date range cannot exceed one year.",
        didClose: () => setEndDate(dayjs()),
      });
      return;
    }

    startTransition(() => {
      setEndDate(date);
    });
  };

  const fetchHistory = async (page: number) => {
    const sDate = startDate?.format("YYYY-MM-DD");
    const eDate = endDate?.format("YYYY-MM-DD");

    try {
      setIsPending(true);
      const { data } = await axiosInstance.get(
        `funds?type=${select}&sDate=${sDate}&eDate=${eDate}&perPage=20&page=${page}`
      );

      startTransition(() => {
        setPage({
          max: data.data.last_page,
          current: data.data.current_page,
        });
        setList(data.data.data);
      });
    } catch (error) {
      console.error("Failed to fetch history:", error);
    } finally {
      setLoading(false);
      setIsPending(false);
    }
  };

  useEffect(() => {
    startTransition(() => {
      setPage((prev) => ({ ...prev, current: 1 }));
    });
    fetchHistory(1);
  }, [select, startDate, endDate]);

  const isFold = useMediaQuery("(max-width: 840px)");

  const renderTableRows = () => {
    if (loading) {
      return (
        <tr>
          <td
            colSpan={4}
            className="h-[400px] flex items-center justify-center"
          >
            <CircularProgress />
          </td>
        </tr>
      );
    }

    if (!list.length) {
      return (
        <tr>
          <td colSpan={4}>
            <NoData />
          </td>
        </tr>
      );
    }

    return list.map((item) => (
      <tr key={item.idx} style={{ opacity: isPending ? 0.7 : 1 }}>
        <td>{new Date(item.createdAt.split(" ")[0]).toDateString()}</td>
        <td>
          {item.isPending && (
            <span style={{ color: "#debf38", marginRight: "4px" }}>
              (Pending)
            </span>
          )}
          {item.description}
        </td>
        <td>
          {item.status === "U" ? "-" : "+"}${" "}
          {select === "cash" ? item.cash : item.prize}
        </td>
        <td>$ {item.balance}</td>
      </tr>
    ));
  };

  return (
    <div className="history">
      <div className="filter-wrap">
        <div className="filter">
          <h4 className="tit">Filter by Balance History</h4>
          <FormControl fullWidth>
            <InputLabel id="balance">Select Balance</InputLabel>
            <Select
              labelId="balance"
              id=""
              label="Select Balance"
              value={select}
            >
              {SelectTypeFilter.map((it, idx) => (
                <MenuItem
                  key={idx}
                  value={it.code}
                  onClick={() => {
                    startTransition(() => {
                      setSelect(it.code);
                    });
                  }}
                >
                  {it.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div id="anc_history" className="filter">
          <h4 className="tit">Search by Date Range</h4>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="datepicker-wrap">
              <CustomDatePicker
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
              />
              <CustomDatePicker
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>

      <div className="table-wrap">
        <table>
          <tbody>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Balance</th>
            </tr>
            {renderTableRows()}
          </tbody>
        </table>
      </div>

      <Pagination
        count={page.max}
        page={page.current}
        siblingCount={isFold ? 0 : 1}
        size={isFold ? "small" : "medium"}
        className="pagination"
        onChange={(_, value) => {
          startTransition(() => {
            setPage((prev) => ({ ...prev, current: value }));
          });
          fetchHistory(value);
          // 앵커 이동 추가
          document.getElementById("anc_history")?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
      />
    </div>
  );
};

export default History;
