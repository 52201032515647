// tools
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { reset } from "../../tools/zustandUpdate";
import User from "../../tools/userInfo";

import axios from "axios";

// comp
import Login from "./login";

// img
import NewLogo from "../../img/common/LC_logo_ani_h.svg";

import { useLotto } from "../../App";
import createAxiosInstance from "../../api/axiosConfig";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../tools/useAuthStore";

const Header = ({ isScrolled }: any) => {
  const { api, token, info, setToken, setTotalInfo } = User();
  const { open, setOpen, noLoginCart, checkNoLoginCart } = useLotto();
  const { accessToken, setAccessToken, clearAccessToken } = useAuthStore();
  const axiosInstance = createAxiosInstance(api);

  const navigate = useNavigate();

  const isSuspendUser = info.suspendEndDate !== null ? true : false;

  useEffect(() => {
    if (token.length === 0) {
      // const payload = JSON.parse(atob(token.split(".")[1]));
      // const origin = payload.iss;

      // if (origin.includes("/login") || origin.includes("/refresh")) {
      //   const expire = payload.exp;
      //   const diff = new Date().getTimezoneOffset() + 540;
      //   const time = new Date().getTime();
      //   const now = (time - (time % 1000)) / 1000;
      //   const converted = now + diff * 60;

      //   if (converted > expire) {
      //     axios
      //       .post(`${api}/refresh`, {}, { withCredentials: true })
      //       .then(({ data }) => {
      //         setToken(data.token);
      //         setAccessToken(data.token);
      //         return axios.get(`${api}/auth/me`, {
      //           headers: { Authorization: `Bearer ${data.token}` },
      //         });
      //       })
      //       .then((res) => console.log(res))
      //       .catch((error) => {
      //         console.log(error, "LogOut");
      //       });
      //   }
      // }
      checkNoLoginCart();
    }
  }, []);

  // scroll event
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const headerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!isTablet) return;

    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (headerRef.current) {
        if (currentScrollTop > lastScrollTop) {
          headerRef.current.classList.add("hide");
        } else {
          headerRef.current.classList.remove("hide");
        }
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTablet, lastScrollTop]);

  return (
    <header className={isScrolled ? "header scroll" : "header"} ref={headerRef}>
      <div className="inner">
        <div className="logo btn" onClick={() => (window.location.href = "/")}>
          <img src={NewLogo} alt="Lottery Cluster" />
        </div>

        <ul className="gnb">
          <li onClick={() => navigate("/power?Q")}>USA Power Lottery</li>
          <li onClick={() => navigate("/mega?Q")}>USA Mega Lottery</li>
          <li onClick={() => navigate("/syndicates")}>Syndicates</li>
          <li onClick={() => navigate("/result?tab=power")}>Results</li>
          <li onClick={() => navigate("/stat?type=power&tab=lastdrawn")}>
            Statistics
          </li>
          <li onClick={() => navigate("/faq")}>FAQ</li>
        </ul>

        <div className="user-nav">
          {token === "" ? (
            <div className="btn style01 sm" onClick={() => setOpen(true)}>
              Login
            </div>
          ) : isSuspendUser ? (
            <div className="btn inactive" onClick={() => navigate("/mypage")}>
              {/* Reactivate at {info.suspendEndDate} */}
              <span className="material-symbols-rounded">lock_clock</span>
              Inactive
            </div>
          ) : (
            <>
              <div className="btn mypage" onClick={() => navigate("/mypage")}>
                <span className="material-symbols-rounded">
                  account_balance_wallet
                </span>
                $ {(Number(info.cash) + Number(info.prize)).toFixed(2)}
              </div>
              {/* <div className="btn style01 sm" onClick={() => logoutAxios()}>
        <span className="material-symbols-rounded">logout</span>
        Logout
      </div> */}
            </>
          )}
        </div>

        <div className="btn cart" onClick={() => (location.href = "/cart")}>
          <span className="material-symbols-rounded">shopping_cart</span>

          {!token
            ? noLoginCart > 0 && (
                <span className="badge">
                  {noLoginCart > 100 ? "99" : noLoginCart}
                </span>
              )
            : info.cart > 0 && (
                <span className="badge">
                  {info.cart > 100 ? "99" : info.cart}
                </span>
              )}
        </div>
      </div>

      <Login open={open} setOpen={setOpen} />
    </header>
  );
};

export default Header;
