// tools
import { Dispatch, SetStateAction, useEffect, useState } from "react";

// ui
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import User from "../../tools/userInfo";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
  notificationFetch: (type: string, status: string) => void;
}

const EmailNotification = ({ setOpen, notificationFetch }: ModalContent) => {
  const [selectedValue, setSelectedValue] = useState<string>("");
  const { info } = User();

  useEffect(() => {
    setSelectedValue(info.emailAlarm);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="notification email">
      <div className="modal-tit-wrap">
        <h3 className="tit">Email Option</h3>
      </div>

      <div className="modal-cont-wrap">
        <div className="notice-area">
          <p>
            Please note: You cannot unsubscribe from account related emails.e.g.
            Order confirmation, Win notification, etc.
          </p>
        </div>

        <FormControl>
          <RadioGroup
            aria-labelledby="email-option"
            name="email-option"
            value={selectedValue}
            onChange={handleChange}
            sx={{ width: "fit-contents", margin: "0 auto" }}
          >
            <div className="input-wrap">
              <FormControlLabel
                value="S"
                control={<Radio />}
                label="Subscribe to all newsletters"
              />
              {selectedValue === "S" && (
                <p className="subtxt">
                  Get all our updates, tips, and news delivered to your inbox.
                </p>
              )}
            </div>
            <div className="input-wrap">
              <FormControlLabel
                value="R"
                control={<Radio />}
                label="Receive benefits and promotions"
              />
              {selectedValue === "R" && (
                <p className="subtxt">
                  Stay informed about exclusive offers and promotions.
                </p>
              )}
            </div>
          </RadioGroup>
        </FormControl>
      </div>

      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
            onClick={() => notificationFetch("email", selectedValue)}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailNotification;
