// tools
import { Dispatch, SetStateAction, useEffect, useState } from "react";

// ui
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Swal from "../../tools/customAlert";

//API
import createAxiosInstance from "../../api/axiosConfig";
import User from "../../tools/userInfo";
import { wait } from "@testing-library/user-event/dist/utils";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  logoutAxios: () => void;
}

interface FetchDataProps {
  duration?: string;
  reason: string;
}

const AccountStatus = ({ setOpen, open, logoutAxios }: ModalContent) => {
  const [chgPage, setChgPage] = useState(""); //현재 페이지 상태 관리
  const [selectedValue, setSelectedValue] = useState("Too many  notifications"); //셀렉트 이유 상태 관리
  const [selectedValue2, setSelectedValue2] = useState(
    "I have bee receiving too many emails"
  ); //셀렉트 이유 상태 관리
  const [reason, setReason] = useState(""); // 일시 정지 이유 상태 관리
  const [selectMonth, setSelectMonth] = useState("1M");

  // 사용자 정보 및 API 인스턴스 생성
  const { api, token, info, setToken, setTotalInfo } = User();
  const axiosInstance = createAxiosInstance(api);

  // 라디오 버튼 선택 변경 핸들러
  const handleChange = (value: string, type: string) => {
    if (type === "close") {
      setSelectedValue(value);
    } else if (type === "break") {
      setSelectedValue2(value);
    }

    if (value !== "other") {
      setReason(value);
    } else {
      setReason("");
    }
  };

  // 'Other' 선택 시 텍스트 필드 변경 핸들러
  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  // API 요청 함수:Suspend, Close My Account
  const accountSuspendFetch = (type: string) => {
    const path = type === "close" ? "/suspend" : "/rest";
    if (type === "close") {
      const data = {
        reason: selectedValue === "other" ? reason : selectedValue,
      };

      fetchAxios(path, data);
    }

    if (type === "break") {
      const data = {
        duration: selectMonth,
        reason: selectedValue2 === "other" ? reason : selectedValue2,
      };
      fetchAxios(path, data);
    }
  };

  useEffect(() => {
    setReason("");
  }, [chgPage]);

  const fetchAxios = (path: string, data: FetchDataProps) => {
    axiosInstance
      .patch(`/auth/${path}`, data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: path === "/rest" ? "Take a break" : "My Account Closed",
          html:
            path === "/rest"
              ? `<div>
          <p style="margin-bottom: 16px; font-size: 16px; font-weight: 500; line-height: 1.3; text-align: left;">You’ve chosen to take a break from the Lottery Cluster until ${
            res.data.data.end_date.split(" ")[0]
          }. During your break, you won’t be able to purchase any games or withdraw any winnings.
          </p>
          <p style="margin-bottom: 16px; font-size: 16px; font-weight: 100; line-height: 1.3; text-align: left;">Please note that under the conditions of our lottery licence we are required to keep a record of customer details for 7 years. These records will be retained for any required audits conducted by the Government Regulator in accordance with our licence, and will not be used for any other purpose.
          </p>
          </div>`
              : `
          <div>
          <p style="margin-bottom: 16px; font-size: 16px; font-weight: 500; line-height: 1.3; text-align: left;">Your well-being is important. We’re here to support you.If you selected this option by accident or have any inquires, please contact us at lotterycluster@gmail.com.</p>
          <p style="margin-bottom: 16px; font-size: 16px; font-weight: 100; line-height: 1.3; text-align: left;">Please note that under the conditions of our lottery licence we are required to keep a record of customer details for 7 years. These records will be retained for any required audits conducted by the Government Regulator in accordance with our licence, and will not be used for any other purpose.</p>
          </div>
          `,
          didClose: () => {
            setOpen(false);
            setChgPage("");
            setTimeout(() => {
              logoutAxios();
            }, 400);
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  // 모달 열림 상태가 변경될 때 상태 초기화
  useEffect(() => {
    if (open === false) {
      setOpen(false);
      setChgPage("");
    }
  }, [open]);

  return (
    <div className="account-status">
      <div className="modal-tit-wrap">
        <h3 className="tit">Account Status</h3>
        <span
          className="btn material-symbols-rounded"
          onClick={() => {
            setOpen(false);
            setChgPage("");
          }}
        >
          close
        </span>
      </div>

      <div className="modal-cont-wrap">
        {chgPage === "" && (
          <div className="status-type-wrap">
            <p className="subtit">I would like to...</p>
            <div className="btnGroup">
              <div
                className="btn style01 md"
                onClick={() => {
                  setChgPage("break");
                }}
              >
                Take a break
              </div>
              <div
                className="btn style01 md"
                onClick={() => {
                  setChgPage("close");
                }}
              >
                Close My Account
              </div>
            </div>
          </div>
        )}

        {chgPage === "close" && (
          <div className="break-wrap">
            <p className="tit">Close My Account</p>
            <p className="subtxt">
              Your well-being is important. We're here to support you. Please
              let us know why you're closing your account so we can better
              assist you.
            </p>

            <p className="subtit">Select your reason</p>

            <RadioGroup
              aria-labelledby=""
              value={selectedValue}
              name=""
              sx={{ marginBottom: "24px" }}
              onChange={(e) => handleChange(e.target.value, "close")}
            >
              <FormControlLabel
                value="Too many  notifications"
                control={<Radio />}
                label="Too many  notifications"
              />
              <FormControlLabel
                value="Concerns about gambling addiction"
                control={<Radio />}
                label="Concerns about gambling addiction"
              />
              <FormControlLabel
                value="Subscribe cancellation process is difficult"
                control={<Radio />}
                label="Subscribe cancellation process is difficult"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />

              {selectedValue === "other" && (
                <TextField
                  id="closeReason"
                  multiline
                  rows={3}
                  value={reason} // 상태와 연결된 value
                  sx={{ marginLeft: "32px" }}
                  onChange={handleReasonChange}
                />
              )}
            </RadioGroup>

            <div className="notice-area">
              <p>
                Please note that under the conditions of our lottery licence we
                are required to keep a record of customer details for 7 years.
                These records will be retained for any required audits conducted
                by the Government Regulator in accordance with our licence, and
                will not be used for any other purpose.
              </p>
            </div>
          </div>
        )}

        {chgPage === "break" && (
          <div className="break-wrap">
            <p className="tit">Take a break</p>
            <p className="subtxt">
              Your well-being is important. Take a break, and we'll be here when
              you're ready.
            </p>
            <FormControl fullWidth sx={{ margin: "24px 0 12px" }}>
              <InputLabel id="period">Time Period</InputLabel>
              <Select
                labelId="period"
                id="demo-simple-select"
                value={selectMonth}
                label="Time Period"
                onChange={(e) => setSelectMonth(e.target.value)}
              >
                <MenuItem value={"1M"}>1Month</MenuItem>
                <MenuItem value={"2M"}>2Months</MenuItem>
                <MenuItem value={"3M"}>3Months</MenuItem>
              </Select>
            </FormControl>
            <p className="subtxt">
              Select the time that feels right for you. You can resume anytime
              during or after the selected period.
            </p>

            <p className="subtit">Select your reason</p>

            <RadioGroup
              aria-labelledby=""
              value={selectedValue2}
              name=""
              sx={{ marginBottom: "24px" }}
              onChange={(e) => handleChange(e.target.value, "break")}
            >
              <FormControlLabel
                value="I have bee receiving too many emails"
                control={<Radio />}
                label="I have bee receiving too many emails"
              />
              <FormControlLabel
                value="Taking a break from lottery purchases"
                control={<Radio />}
                label="Taking a break from lottery purchases"
              />
              <FormControlLabel
                value="Jackpot amounts are too low"
                control={<Radio />}
                label="Jackpot amounts are too low"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />

              {selectedValue2 === "other" && (
                <TextField
                  id="breakReason"
                  multiline
                  rows={3}
                  value={reason} // 상태와 연결된 value
                  sx={{ marginLeft: "32px" }}
                  onChange={handleReasonChange}
                />
              )}
            </RadioGroup>

            <div className="notice-area">
              <p>During your break, you won't be able to purchase any games.</p>
            </div>
          </div>
        )}
      </div>

      {chgPage === "break" && (
        <div className="modal-bottom-wrap">
          <div className="btn-wrap">
            <div
              className="btn style01 sm"
              onClick={() => {
                setChgPage("");
              }}
            >
              Cancel
            </div>
            <div
              className="btn style01 sm white"
              style={{ padding: "12px 36px" }}
              onClick={() => accountSuspendFetch("break")}
            >
              Confirm
            </div>
          </div>
        </div>
      )}

      {chgPage === "close" && (
        <div className="modal-bottom-wrap">
          <div className="btn-wrap">
            <div
              className="btn style01 sm"
              onClick={() => {
                setChgPage("");
              }}
            >
              Cancel
            </div>
            <div
              className="btn style01 sm white"
              style={{ padding: "12px 36px" }}
              onClick={() => accountSuspendFetch("close")}
            >
              Confirm
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountStatus;
