import ReactDOM from "react-dom/client";
import "./index.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { HelmetProvider } from "react-helmet-async";
import "./../src/styles/lc.scss";
import "./../src/styles/lc-responsive.css";
import "./../src/fonts/font.css";
import routes from "./router/routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(255,255,255,0.7)", // 좀 더 부드러운 라벨 색상
          "&.Mui-focused": {
            color: "#fff",
            fontWeight: 500,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // 모서리 둥글기 추가
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(255,255,255,0.7);", // MUI 기본 포커스 블루
            borderWidth: 1,
          },
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px rgba(0,0,0,0) inset !important",
            WebkitTextFillColor: "#fff !important",
            transition: "background-color 5000s ease-in-out 0s !important",
            caretColor: "#fff !important",
            WebkitTextSizeAdjust: "100%", // 폰트 크기 고정
            fontSize: "1rem", // 원래 폰트 크기 유지
          },
        },
        input: {
          spellCheck: "false", // 스펠 체크 비활성화
          autoComplete: "off", // 자동완성 비활성화
          "&::placeholder": {
            color: "rgba(255,255,255,0.5)", // 플레이스홀더 색상 조정
          },
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px rgba(0,0,0,0) inset !important",
            WebkitTextFillColor: "#fff !important",
            transition: "background-color 5000s ease-in-out 0s !important",
            caretColor: "#fff !important",
            WebkitTextSizeAdjust: "100%", // 폰트 크기 고정
            fontSize: "1rem", // 원래 폰트 크기 유지
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          spellCheck: "false",
          autoComplete: "off",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "0", // 원하는 마진 값
        },
      },
    },
  },
  typography: {
    fontFamily: '"Ubuntu", sans-serif',
  },
  shape: {
    borderRadius: 8, // 전역 border radius
  },
});

root.render(
  <AnimatePresence mode="sync">
    <HelmetProvider>
      <ThemeProvider theme={darkTheme}>
        <RouterProvider router={routes} />
      </ThemeProvider>
    </HelmetProvider>
  </AnimatePresence>
);
reportWebVitals();
