import React, { useEffect, useState } from "react";
import { generateNumbers } from "../../tools/makeMatches";
import { BallSet } from "../../types/ball";

interface ShowNumProps {
  nums: string;
  code: string;
  sysCode: string;
  winningNums?: string;
}

const ShowNumMatch = ({ nums, code, sysCode, winningNums }: ShowNumProps) => {
  const [showNums, setShowNums] = useState<boolean>(false);
  const [list, setList] = useState<number[][]>([]);
  const winning = winningNums?.split(",");
  const winningSp = winning?.pop();

  const num = nums.split(",");
  const special = num.pop();
  const typeChk = (target: string) => {
    if (target === "MM") {
      return "mega";
    } else {
      return "power";
    }
  };

  const combi = generateNumbers(nums, typeChk(code), sysCode);

  /** 시스템 숫자 생성기 */
  const getCombinations = (arr: string[], selectNumber: number) => {
    const results: any[] = [];

    if (selectNumber === 1) return arr.map((value) => [value]);

    arr.forEach((fixed, index, origin) => {
      const rest = origin.slice(index + 1);
      const combinations = getCombinations(rest, selectNumber - 1);
      const attached = combinations.map((combination) => [
        fixed,
        ...combination,
      ]);
      results.push(...attached);
    });

    return results;
  };

  const settingNums = (type: string) => {
    if (type === "GUA" || type === "PIC") {
      return;
    } else {
      setList(getCombinations(num, 5));
    }
  };

  useEffect(() => {
    settingNums(code);
  }, []);

  const pickSpball = nums.split(",").pop();

  const rankMatcher = (wBall: number, sBall: number) => {
    if (wBall === 0 && sBall === 1) {
      return "Tier 9";
    } else if (wBall === 1 && sBall === 1) {
      return "Tier 8";
    } else if (wBall === 2 && sBall === 1) {
      return "Tier 7";
    } else if (wBall === 3 && sBall === 0) {
      return "Tier 6";
    } else if (wBall === 3 && sBall === 1) {
      return "Tier 5";
    } else if (wBall === 4 && sBall === 0) {
      return "Tier 4";
    } else if (wBall === 4 && sBall === 1) {
      return "Tier 3";
    } else if (wBall === 5 && sBall === 0) {
      return "Tier 2";
    } else if (wBall === 5 && sBall === 1) {
      return "Tier 1";
    } else {
      return;
    }
  };

  return (
    <div className="show-number">
      <div
        className={`btn style02 show ${showNums ? "open " : ""}`}
        onClick={() => {
          setShowNums(!showNums);
        }}
      >
        <span>{showNums ? "Hide your numbers" : "Show your numbers"}</span>
        <span className="material-symbols-rounded">keyboard_arrow_down</span>
      </div>
      {showNums ? (
        sysCode === "PIC" ? (
          <div className="number-wrap">
            {Array.from(
              {
                length: BallSet[typeChk(code)].white - num.slice(0, 4).length,
              },
              (_, idx) => {
                const excludedNumbers = num.slice(0, 4).map(Number);
                const availableNumber = idx + 1;

                if (excludedNumbers.includes(availableNumber)) {
                  return null;
                }

                const matchedRegular = [
                  ...num.slice(0, 4).map(Number),
                  availableNumber,
                ].filter((fixedNum) =>
                  winning?.includes(String(fixedNum))
                ).length;

                const matchedSpecial = winningSp === pickSpball ? 1 : 0;

                return (
                  <div className="number" key={availableNumber}>
                    <p># {availableNumber}</p>
                    <div className="ball-wrap">
                      {num.slice(0, 4).map((fixedNum, idx) => (
                        <div
                          className={`ball ${
                            winning && winning.includes(fixedNum) && "matching"
                          }`}
                          key={`fixed-${idx}`}
                        >
                          {fixedNum}
                        </div>
                      ))}
                      <div
                        className={`ball ${
                          winning &&
                          winning.includes(String(availableNumber)) &&
                          "matching"
                        }`}
                      >
                        {availableNumber}
                      </div>
                      <div
                        className={`ball ${
                          typeChk(code) === "mega" ? "mega" : "power"
                        } ${
                          winningSp && winningSp === pickSpball && "matching"
                        }`}
                      >
                        {pickSpball}
                      </div>
                      <ul className="match-info">
                        <li>{rankMatcher(matchedRegular, matchedSpecial)}</li>
                      </ul>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        ) : sysCode !== "GUA" ? (
          <div className="number-wrap">
            {list.map((it, idx) => {
              const matchedRegular = it.filter((num) =>
                winning?.includes(String(num))
              ).length;
              const matchedSpecial = winningSp === special ? 1 : 0;

              return (
                <div className="number" key={idx}>
                  <p className="">#{idx + 1}</p>
                  <div className="ball-wrap">
                    {it.slice(0, 5).map((num: any, idx: any) => (
                      <div
                        className={`ball ${
                          winning && winning.includes(String(num)) && "matching"
                        }`}
                        key={idx}
                      >
                        {num}
                      </div>
                    ))}

                    <div
                      className={`ball ${
                        typeChk(code) === "mega" ? "mega" : "power"
                      } ${winningSp && winningSp === special && "matching"}`}
                    >
                      {special}
                    </div>

                    <ul className="match-info">
                      <li>{rankMatcher(matchedRegular, matchedSpecial)}</li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="number-wrap">
            {Array.from(
              { length: BallSet[typeChk(code)].special },
              (_, specialIdx) => {
                const matchedRegular = num.filter((it) =>
                  winning?.includes(it)
                ).length;
                const matchedSpecial =
                  winningSp === String(specialIdx + 1) ? 1 : 0;

                return (
                  <div className="number" key={specialIdx}>
                    <p className="">#{specialIdx + 1}</p>
                    <div className="ball-wrap">
                      {num.map((it, idx) => (
                        <div
                          className={`ball ${
                            winning && winning.includes(it) && "matching"
                          }`}
                          key={idx}
                        >
                          {it}
                        </div>
                      ))}
                      <div
                        className={`ball ${
                          typeChk(code) === "mega" ? "mega" : "power"
                        } ${
                          winningSp &&
                          winningSp === String(specialIdx + 1) &&
                          "matching"
                        }`}
                      >
                        {specialIdx + 1}
                      </div>
                      <ul className="match-info">
                        <li>{rankMatcher(matchedRegular, matchedSpecial)}</li>
                      </ul>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default ShowNumMatch;
