// tools
import { Link } from "react-router-dom";

// ui
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import createAxiosInstance from "../../api/axiosConfig";
import { useEffect, useState } from "react";
import { NewsProps } from "../../pages/news";
import User from "../../tools/userInfo";

const News = () => {
  const { api } = User();
  const axiosInstance = createAxiosInstance(api);

  const [news, setNews] = useState<NewsProps[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  // Slick slide
  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr next" onClick={onClick}>
        <span className="material-symbols-rounded">arrow_forward_ios</span>
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="btn arr prev" onClick={onClick}>
        <span
          className="material-symbols-rounded"
          style={{ marginRight: "-6px" }}
        >
          arrow_back_ios
        </span>
      </div>
    );
  };
  const SliderSettings = {
    autoplay: true,
    speed: 500,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    draggable: true,
    swipeToSlide: true,
    className: "news-slide",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const fetchNews = (page: number) => {
    axiosInstance
      .get(`/support/news`, {
        params: {
          page: page,
        },
      })
      .then((res) => {
        setNews(res.data.data.data);
        setCurrentPage(res.data.data.current_page);
        setLastPage(res.data.data.last_page);
      });
  };

  useEffect(() => {
    fetchNews(1);
  }, []);

  return (
    <section className="news-area">
      <Link className="tit-wrap btn" to="/news">
        <h3 className="tit">CLUSTER NEWS</h3>
        <span className="material-symbols-rounded">chevron_right</span>
      </Link>

      <Slider {...SliderSettings}>
        {news.map((it, idx) => (
          <a
            className="item"
            key={idx}
            href={it.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="img-wrap">
              <img src={it.thumbnail} alt="" />
            </div>
            <div className="txt-wrap">
              <h4 className="tit">{it.subject}</h4>
              <p className="date">{it.publishedAt}</p>
            </div>
          </a>
        ))}
      </Slider>
    </section>
  );
};

export default News;
