import React, { useState } from "react";
import { GameCard } from "../../pages/syndicateCreate";

// type

interface CardProps {
  data: GameCard;
  idx: number;
  type: string;
  handleClear: (target: number) => void;
  handlePick: (id: number, number: number | string, isSpecial: boolean) => void;
  handleQuick: (id: number) => void;
}

const CreateCard = ({
  data,
  idx,
  type,
  handleClear,
  handlePick,
  handleQuick,
}: CardProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPlay, setIsPlay] = useState<boolean>(false);

  const ballMaker = () => {
    return Array.from({ length: type === "mega" ? 70 : 69 }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => handlePick(data.id, ballNum, false)}
          className={`ball ${data.wBall.includes(ballNum) ? "picked" : ""}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  const specialMaker = () => {
    return Array.from({ length: type === "mega" ? 25 : 26 }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => handlePick(data.id, ballNum, true)}
          className={`ball ${data.sBall === ballNum ? "picked" : ""}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  const playingQ = () => {
    setIsPlay(true);
    setTimeout(() => {
      setIsPlay(false);
    }, 1000);
  };

  return (
    <div className="ticket">
      <div className="ticket-tit-wrap">
        <p>Game #{idx + 1}</p>
        <div className="btn-wrap">
          <span
            className={`btn material-symbols-rounded ${isPlay && "disabled"}`}
            onClick={() => {
              if (isPlay) {
                return;
              } else {
                handleQuick(data.id);
              }
              playingQ();
            }}
          >
            bolt
          </span>
          <span
            className={`btn material-symbols-rounded ${isPlay && "disabled"}`}
            onClick={() => {
              if (isPlay) {
                return;
              } else {
                handleClear(data.id);
              }
            }}
          >
            mop
          </span>
          {/* <span className="btn material-symbols-rounded">delete</span> */}
        </div>
      </div>

      <div className="picked-ball-wrap">
        <div className="ball-wrap" onClick={() => setIsOpen((prev) => !prev)}>
          {data.wBall.map((it, idx) => (
            <div key={idx} className="ball">
              {it === 0 ? "" : it}
            </div>
          ))}
          <div className={`ball ${type}`}>
            {data.sBall === 0 ? "" : data.sBall}
          </div>
          <span className="material-symbols-rounded">
            {isOpen ? "keyboard_arrow_up " : "keyboard_arrow_down"}
            {/* keyboard_arrow_up  {isOpen ? "up" : "down"} */}
          </span>
        </div>

        <div className={`vertical-trans ${isOpen ? "open" : "close"}`}>
          <div className="edit-ball-wrap">
            <div className="ball-count">
              <div className="tit-count-wrap">
                <p className="tit">Ball</p>
                <p className="count">
                  <span>{data.wBall.filter((it) => it !== "0").length} </span>
                  of {data.wBall.length}
                </p>
              </div>
            </div>

            <div className="ball-wrap">{ballMaker()}</div>
            {data.sBall !== "G" && (
              <>
                <div
                  className={`tit-count-wrap ${type}`}
                  style={{ marginTop: "12px" }}
                >
                  <p className="power">Power ball</p>
                  <p className="count">
                    <span>{data.sBall !== "0" ? "1" : "0"} </span> of 1
                  </p>
                </div>

                <div className={`ball-wrap ${type}`}>{specialMaker()}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCard;
