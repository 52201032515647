// tools
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// component
import Category from "../components/magazine/category";
import MagazineSlider from "../components/magazine/magazineSlider";
import MetaTag from "../tools/SEOMetaTag";

const Magazine = () => {
  const [tab, setTab] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const search = location.search.slice(1, location.search.length);

    if (search) {
      const split = search.split("&")[0];
      const menu = split.split("=")[1];

      setTab(menu);
    } else {
      setTab("home");
    }
  }, [location.search]);

  const viewChange = (about: string) => {
    return <Category menu={about} />;
    // switch (about) {
    //   case "home":
    //     return <Category menu={about} />;
    //     break;
    //   case "estate":
    //     return <Category menu={about} />;
    //     break;
    //   case "car":
    //     return <Category menu={about} />;
    //     break;
    //   case "watch":
    //     return <Category menu={about} />;
    //     break;
    //   case "yacht":
    //     return <Category menu={about} />;
    //     break;
    //   default:
    //     return;
    // }
  };

  return (
    <>
      <MetaTag title="Magazine" path="magazine" />

      <div className="sub magazine">
        <MagazineSlider />
        {viewChange(tab)}
      </div>
    </>
  );
};

export default Magazine;
