// tools
import Swal from "../tools/customAlert";
import User from "../tools/userInfo";
import { update } from "../tools/zustandUpdate";
import { useEffect, useState } from "react";
import { reset } from "../tools/zustandUpdate";

// ui
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { Checkbox } from "@mui/material";

// componemts
import Draws from "../components/mypage/draws";
import History from "../components/mypage/history";
import Settings from "../components/mypage/settings";
import { CustModal } from "../components/common/commonElements";
import MyStat from "../components/mypage/myStat";
import createAxiosInstance from "../api/axiosConfig";
import MyFav from "../components/mypage/myFav";

// img
import Mastercard from "../img/sub/mastercard-ico.svg";
import Visa from "../img/sub/visa-ico.svg";
import PayPal from "../img/sub/paypal-ico.svg";
import Paysafe from "../img/sub/paysafe-ico.svg";
import Verify from "../components/mypage/verify";
import { Helmet } from "react-helmet-async";
import MetaTag from "../tools/SEOMetaTag";
import { useNavigate } from "react-router-dom";

interface Category {
  menu: string;
  title: string;
  href: string;
}

export interface NewCard {
  type: string;
  number: number | null;
  name: string;
  exp: string;
  cvv: number | null;
  check: string;
}

const Mypage = () => {
  const { api, token, info, setToken, setTotalInfo } = User();
  const [menu, setMenu] = useState("");
  const [open, setOpen] = useState(false);
  const [openWithdraw, setOpenWithDraw] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedValue, setSelectedValue] = useState("total");

  // deposit
  const methods = ["card", "addCard", "paypal", "paysafe"];
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [newCard, setNewCard] = useState<NewCard>({
    type: "",
    number: null,
    name: "",
    exp: "",
    cvv: null,
    check: "N",
  });

  const handleCardData = (key: string, value: string | number) => {
    setNewCard((prev) => ({ ...prev, [key]: value }));
  };

  // withdraw
  const withdrawMethods = ["bank", "paypal"];
  const [drawIndex, setDrawIndex] = useState(0);

  const axiosInstance = createAxiosInstance(api);
  const navigate = useNavigate();

  const changeView = (type: string) => {
    switch (type) {
      case "settings":
        return <Settings />;
        break;
      case "draw":
        return <Draws />;
        break;

      case "history":
        return <History />;
        break;

      case "myfav":
        return <MyFav />;
        break;

      case "mystat":
        return <MyStat />;
    }
  };

  const cate: Category[] = [
    {
      menu: "settings",
      title: "Settings",
      href: "/mypage?tab=settings",
    },
    {
      menu: "draw",
      title: "Draws",
      href: "/mypage?tab=draw",
    },
    {
      menu: "history",
      title: "History",
      href: "/mypage?tab=history",
    },
    // { menu: "mystat", title: "My stat", href: "/mypage?tab=mystat" },
    { menu: "myfav", title: "Favourites", href: "/mypage?tab=myfav" },
  ];

  if (!token) {
    Swal.fire({
      title: "Members Only",
      text: "This page is only for Lottery Cluster members",
      icon: "error",
      allowOutsideClick: false,
      didClose: () => location.replace("/"),
    });
  }

  useEffect(() => {
    update(api, token, setTotalInfo);
    const { search } = location;
    const [menu] = search.replace("?", "").split("&");
    setMenu(menu.split("=")[1] ? menu.split("=")[1] : "settings");
  }, []);

  useEffect(() => {
    if (!open) {
      setExpandedIndex(0);
      setNewCard({
        type: "",
        number: null,
        name: "",
        exp: "",
        cvv: null,
        check: "N",
      });
    }
  }, [open]);

  const chargeCash = () => {
    if (amount) {
      axiosInstance
        .post(`/cash/charge/bank`, {
          cash: amount,
          price: amount,
          item_name: `$ ${amount}`,
        })
        .then(({ data }) => {
          if (data.message) {
            Swal.fire({
              title: "Success",
              icon: "success",
              allowOutsideClick: false,
              didClose: () => location.reload(),
            });
          }
        })
        .catch(({ response }) => {
          const status = response.status;

          if (status === 400) {
            Swal.fire({
              title: "Error",
              text: "You have a charge request that hasn't been completed.",
              icon: "error",
              allowOutsideClick: false,
            });
            setOpen(false);
          }
        });
    } else {
      Swal.fire({
        title: "Error",
        text: "You cannot charge $0. Please select other option.",
        icon: "error",
        allowOutsideClick: false,
      });
      setOpen(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  // tootip
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const isSuspendUser = info.suspendEndDate !== null ? true : false;

  return (
    <>
      <MetaTag title="MyPage" path="mypage" />
      <div className="sub mypage">
        <div className="user-info-area unverified">
          {/* Verify 유무 */}
          {info.verifiedAt === null && (
            <div className="unverified" onClick={() => setOpenVerify(true)}>
              <span className="material-symbols-rounded">error</span>
              NOT VERIFIED - Click here for more information
            </div>
          )}
          <div className="user-info">
            <p className="name">
              Welcome, &nbsp;
              <span className="id">
                {info.firstName} {info.lastName}
              </span>
              {info.verifiedAt === null && (
                <Tooltip
                  title={
                    <p>
                      Verify your identity with a valid ID and proof of address
                      to access full features and protect your account. Your
                      data is securely encrypted.
                    </p>
                  }
                >
                  <span className="material-symbols-rounded icon">error</span>
                </Tooltip>
              )}
              <br />
              {/* 계정 활동 상태에 따른 안내말 */}
              {isSuspendUser ? (
                <div className="statMsg">
                  <span>
                    Your account is currently suspended until{" "}
                    {info.suspendEndDate}.
                  </span>
                  <span>
                    If you wish to have it reinstated, please contact us via the
                    "Contact Us" page.
                  </span>
                </div>
              ) : (
                "We're glad to have you here!"
              )}
            </p>

            <p className="email">
              <span className="tit">Email</span>
              <span className="txt">{info.email}</span>

              {/* <div className="item">
                <p className="tit">Phone number</p>
                <p className="txt">
                  {info.phone.slice(0, 2)}-{info.phone.slice(2, 6)}-
                  {info.phone.slice(6, 11)}
                </p>
              </div> */}
            </p>
          </div>
          <div className={`balance-wrap ${isSuspendUser && "disabled"}`}>
            <ul>
              <li>
                <span className="tit">Deposit</span>
                <span className="txt">$ {info.cash}</span>
              </li>
              <li>
                <span className="tit">Winnings</span>
                <span className="txt">$ {info.prize}</span>
              </li>
              <li>
                <span className="tit">Available balance</span>
                <span className="txt">
                  $ {(Number(info.cash) + Number(info.prize)).toFixed(2)}
                </span>
              </li>

              {/* pending 이 있을때 보이게 */}
              {Number(info.pending) > 1 && (
                <li className="pending">
                  <span className="tit">Pending</span>
                  <span className="txt">$ {info.pending}</span>
                </li>
              )}
            </ul>

            <div className="balance-edit btn-wrap">
              <div className="btn style01 sm spc" onClick={() => setOpen(true)}>
                Add Deposit
              </div>
              <div
                className="btn style01 sm"
                onClick={() => setOpenWithDraw(true)}
              >
                Withdraw
              </div>
            </div>
          </div>
        </div>

        <div className="tab-wrap">
          <ul className="tab style01">
            {cate.map((it, idx) => (
              <li
                key={idx}
                className={menu === it.menu ? "current" : ""}
                onClick={() => {
                  setMenu(it.menu);
                  navigate(`${it.href}`);
                }}
                // onClick={() => (location.href = it.href)}
              >
                {it.title}
              </li>
            ))}
          </ul>
        </div>

        {/* Tab - 페이지 이동 */}
        {changeView(menu)}
      </div>

      {/* Add Deposit 모달창 */}
      {/* 결제(충전) -> 카드 선택, 무통장 입금 처리 방식 상의 */}
      <CustModal open={open} setOpen={setOpen} onClose={() => setOpen(false)}>
        <div className="add-deposit">
          <div className="modal-tit-wrap">
            <h3 className="tit">Add Deposit</h3>
            <span
              className="btn close material-symbols-rounded"
              onClick={() => setOpen(false)}
            >
              close
            </span>
          </div>
          <div className="modal-cont-wrap">
            <div className="payment-method">
              <FormControl fullWidth className="amount">
                <InputLabel id="demo-simple-select-label">Deposit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={amount}
                  label="Deposit"
                  onChange={({ target }) => {
                    const value = target.value as number;
                    setAmount(value);
                  }}
                >
                  {new Array(10).fill(1).map((_, index) => (
                    <MenuItem
                      value={7 * (index + 1)}
                      key={`money_${index * 7}_A$`}
                    >
                      $ {7 * (index + 1)}.00
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* 등록된 카드가 있을때 */}
              {methods.map((method, index) => {
                return (
                  <div
                    key={index}
                    className={`item  ${expandedIndex === index ? "open" : ""}`}
                  >
                    {/* 등록된 카드 있을 때 */}
                    {method === "card" && (
                      <div className="card">
                        <div className="top-area">
                          <Radio
                            value="a"
                            name="radio-buttons"
                            checked={expandedIndex === index}
                            onClick={() => setExpandedIndex(0)}
                            readOnly
                          />
                          <img src={Visa} alt="Visa" className="icon" />
                          <p className="txt">5217 29xx xxxx x084</p>
                          <p className="expired">EXPIRED</p>
                        </div>
                        {expandedIndex === index && (
                          <div className="bottom-area">
                            <div className="card-info">
                              <div className="info">
                                <p className="tit">CARD HOLDER</p>
                                <p className="txt">KIM GU SONG</p>
                              </div>
                              <div className="info">
                                <p className="tit">Expries</p>
                                <p className="txt">11/24</p>
                              </div>
                            </div>
                            <div className="btn style01 md spc">
                              Add $ 1163.00
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/* 신규카드추가 */}
                    {method === "addCard" && (
                      <div className="add-card">
                        <div className="top-area">
                          <Radio
                            value="a"
                            name="radio-buttons"
                            checked={expandedIndex === index}
                            onClick={() => setExpandedIndex(1)}
                            readOnly
                          />
                          <span className="material-symbols-rounded icon">
                            add_card
                          </span>
                          <p className="txt">Add New Card</p>
                        </div>
                        {expandedIndex === index && (
                          <div className="bottom-area">
                            <div className="provider-wrap">
                              <div
                                className={`provider ${
                                  newCard.type === "VISA" && "picked"
                                }`}
                                onClick={() => handleCardData("type", "VISA")}
                              >
                                <img src={Visa} alt="Visa" />
                              </div>
                              <div
                                className={`provider ${
                                  newCard.type === "MASTER" && "picked"
                                }`}
                                onClick={() => handleCardData("type", "MASTER")}
                              >
                                <img src={Mastercard} alt="Mastercard" />
                              </div>
                            </div>
                            <form>
                              <div className="input-wrap">
                                <TextField
                                  label="Card Number"
                                  fullWidth
                                  name="number"
                                  value={newCard.number}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    const formattedValue = value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    handleCardData("number", formattedValue);
                                  }}
                                />
                              </div>

                              <div className="input-wrap">
                                <TextField
                                  label="Card Holder Name"
                                  placeholder="Name as it appears on your card"
                                  fullWidth
                                  name="name"
                                  value={newCard.name}
                                  onChange={({ target }) =>
                                    handleCardData("name", target.value)
                                  }
                                />
                              </div>

                              <div className="input-wrap">
                                <TextField
                                  label="Expiration Date"
                                  placeholder="MM/YYYY"
                                  fullWidth
                                  name="exp"
                                  value={newCard.exp}
                                  onChange={({ target }) => {
                                    let { value } = target;
                                    // 숫자와 '/'만 허용
                                    value = value.replace(/[^0-9/]/g, "");

                                    // 길이 제한 (7자리 MM/YYYY)
                                    if (value.length > 7) return;

                                    // 자동으로 '/' 추가
                                    if (
                                      value.length === 2 &&
                                      !value.includes("/")
                                    ) {
                                      value += "/";
                                    }

                                    handleCardData("exp", value);
                                  }}
                                />

                                <TextField
                                  label="CVV"
                                  placeholder="123"
                                  fullWidth
                                  name="cvv"
                                  value={newCard.cvv}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    const formattedValue = value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    handleCardData("cvv", formattedValue);
                                  }}
                                />
                              </div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={newCard.check === "Y"}
                                    onClick={() => {
                                      if (newCard.check === "N") {
                                        setNewCard((prev) => ({
                                          ...prev,
                                          check: "Y",
                                        }));
                                      } else {
                                        setNewCard((prev) => ({
                                          ...prev,
                                          check: "N",
                                        }));
                                      }
                                    }}
                                  />
                                }
                                label="Securely stroe for futrue use"
                              />
                            </form>
                            <div className="btn style01 md spc">
                              Add $ 1163.00
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/* 페이팔 */}
                    {method === "paypal" && (
                      <div className="paypal">
                        <div className="top-area">
                          <Radio
                            value="a"
                            name="radio-buttons"
                            checked={expandedIndex === index}
                            onClick={() => setExpandedIndex(2)}
                            readOnly
                          />
                          <img src={PayPal} alt="PayPal" className="icon" />
                          <p className="txt">Add PayPal</p>
                        </div>
                        {expandedIndex === index && (
                          <div className="bottom-area">
                            <div className="btn style01 md">
                              <img
                                src={PayPal}
                                alt="PayPal"
                                style={{ width: "100px" }}
                              />
                            </div>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="Securely stroe for futrue use"
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {/* 페이세이프티 */}
                    {method === "paysafe" && (
                      <div className="paysafe">
                        <div className="top-area">
                          <Radio
                            value="a"
                            name="radio-buttons"
                            checked={expandedIndex === index}
                            onClick={() => setExpandedIndex(3)}
                            readOnly
                          />
                          <img src={Paysafe} alt="Paysafe" className="icon" />
                          <p className="txt">Add Paysafecard</p>
                        </div>
                        {expandedIndex === index && (
                          <div className="bottom-area">
                            <div className="btn style01 md">
                              <img
                                src={Paysafe}
                                alt="Paysafe"
                                style={{ width: "100px" }}
                              />
                            </div>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="Securely stroe for futrue use"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-bottom-wrap">
            <div className="btn-wrap">
              <div className="btn style01 sm" onClick={() => setOpen(false)}>
                Cancel
              </div>
              <div
                className="btn style01 sm white"
                style={{ padding: "12px 36px" }}
                onClick={() => chargeCash()}
              >
                Add
              </div>
            </div>
          </div>
        </div>
      </CustModal>

      {/* Withdraw 모달창(출금) */}
      <CustModal
        open={openWithdraw}
        setOpen={setOpenWithDraw}
        onClose={() => setOpenWithDraw(false)}
      >
        <div className="withdraw">
          <div className="modal-tit-wrap">
            <h3 className="tit">Withdraw</h3>
            <span
              className="btn close material-symbols-rounded"
              onClick={() => setOpenWithDraw(false)}
            >
              close
            </span>
          </div>
          <div className="modal-cont-wrap">
            <div className="payment-method">
              {withdrawMethods.map((method, index) => {
                return (
                  <div
                    key={index}
                    className={`item  ${drawIndex === index ? "open" : ""}`}
                  >
                    {/* 등록된 은행 있을 때 */}
                    {method === "bank" && (
                      <div className="bank">
                        <div className="top-area">
                          <Radio
                            value="a"
                            name="radio-buttons"
                            checked={drawIndex === index}
                            onClick={() => setDrawIndex(0)}
                            readOnly
                          />
                          <span className="material-symbols-rounded icon">
                            assured_workload
                          </span>
                          <p className="txt">Add Bank Account</p>
                        </div>
                        {drawIndex === index && (
                          <div className="bottom-area">
                            <div className="bank-info">
                              <p className="subtit">Bank Information</p>
                              <form>
                                <div className="input-wrap">
                                  <TextField label="Bank Name" fullWidth />
                                </div>

                                <div className="input-wrap">
                                  <TextField label="Account Number" fullWidth />
                                </div>
                              </form>
                            </div>
                            <RadioGroup
                              value={selectedValue}
                              onChange={handleChange}
                              name="radio-buttons-group"
                              className="amount-wrap"
                            >
                              <FormControlLabel
                                // DOM 에서 isChecked로 className chk 포함 항목 (선택항목) 찾기 isChecked
                                id="isChecked"
                                value="total"
                                control={<Radio />}
                                label={
                                  <div className="amount">
                                    <p className="tit">Winnings</p>
                                    <p className="txt">$ {info.prize}</p>
                                  </div>
                                }
                                className={
                                  selectedValue === "total"
                                    ? "amount-item chk"
                                    : "amount-item"
                                }
                              />
                              <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label={
                                  <div className="amount">
                                    <p className="tit">Other</p>
                                    <TextField
                                      disabled={selectedValue !== "other"}
                                      className="other-textfield"
                                    />
                                  </div>
                                }
                                className={
                                  selectedValue === "other"
                                    ? "amount-item chk"
                                    : "amount-item"
                                }
                              />
                            </RadioGroup>

                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="Would you like to save this bank details?"
                              sx={{ marginTop: "12px" }}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {/* 페이팔 */}
                    {method === "paypal" && (
                      <div className="paypal">
                        <div className="top-area">
                          <Radio
                            value="a"
                            name="radio-buttons"
                            checked={drawIndex === index}
                            onClick={() => setDrawIndex(1)}
                            readOnly
                          />
                          <img src={PayPal} alt="PayPal" className="icon" />
                          <p className="txt">Add PayPal</p>
                        </div>
                        {drawIndex === index && (
                          <div className="bottom-area">
                            <div className="btn style01 md">
                              <img
                                src={PayPal}
                                alt="PayPal"
                                style={{ width: "100px" }}
                              />
                            </div>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="Securely stroe for futrue use"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-bottom-wrap">
            <div className="btn-wrap">
              <div
                className="btn style01 sm"
                onClick={() => setOpenWithDraw(false)}
              >
                Cancel
              </div>
              <div
                className="btn style01 sm white"
                // chk 포함 -> 선택 항목 값 찾기
              >
                Confirm
              </div>
            </div>
          </div>
        </div>
      </CustModal>
      <CustModal
        open={openVerify}
        setOpen={setOpenVerify}
        onClose={() => setOpenVerify(false)}
      >
        <Verify setOpen={setOpenVerify} />
      </CustModal>
    </>
  );
};

export default Mypage;
