import { Dispatch, SetStateAction, useState } from "react";
import { CustModal } from "../common/commonElements";

// ui
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import dayjs, { Dayjs } from "dayjs";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface Info {
  [key: string]: string | number | null;
  type: string;
  pass: string;
  firName: string;
  midName: string;
  surName: string;
  date: string;
}

const Verify = ({ setOpen }: ModalContent) => {
  const [info, setInfo] = useState<Info>({
    type: "",
    pass: "",
    firName: "",
    midName: "",
    surName: "",
    date: "",
  });

  const reset = () => {
    setInfo({
      type: "",
      pass: "",
      firName: "",
      midName: "",
      surName: "",
      date: "",
    });
  };

  const handleChange = (target: string, value: string | number) => {
    setInfo((prev: Info) => ({ ...prev, [target]: value }));
  };

  return (
    <>
      {/* <CustModal open={open} setOpen={setOpen} onClose={handleClose}> */}
      <div className="verify">
        <div className="modal-tit-wrap">
          <h3 className="tit">Verify</h3>
          <span
            className="btn material-symbols-outlined"
            onClick={() => {
              setOpen(false);
              reset();
            }}
          >
            close
          </span>
        </div>

        <div className="modal-cont-wrap">
          <div className="notice-area">
            <p>
              Secure your account by verifying your details with an approved ID.
              Upload your first document to get started.
            </p>
          </div>

          <form>
            <div className="type-wrap">
              <p className="subtit">Select ID type</p>
              <FormControl fullWidth>
                <InputLabel id="type">Choose type of ID to verify</InputLabel>
                <Select
                  labelId="type"
                  id=""
                  value={info.type}
                  onChange={(e) => handleChange("type", e.target.value)}
                  label="Choose type of ID to verify"
                >
                  <MenuItem value="DL">Driver's licence</MenuItem>
                  <MenuItem value="18">+18 Card</MenuItem>
                  <MenuItem value="HC">Healthcare card</MenuItem>
                  <MenuItem value="BS">Bank Statement</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="input-wrap">
              <TextField
                onChange={(e) => handleChange("pass", e.target.value)}
                label="Passport Number"
                fullWidth
                value={info.pass}
              />
            </div>

            <div className="input-wrap">
              <TextField
                onChange={(e) => handleChange("firName", e.target.value)}
                label="First name"
                fullWidth
                value={info.firName}
              />
            </div>

            <div className="input-wrap">
              <TextField
                onChange={(e) => handleChange("midName", e.target.value)}
                label="Middle name"
                fullWidth
                value={info.midName}
              />
            </div>

            <div className="input-wrap">
              <TextField
                onChange={(e) => handleChange("surName", e.target.value)}
                label="Surname"
                fullWidth
                value={info.surName}
              />
            </div>

            <div className="input-wrap">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={(value: Dayjs | null) =>
                    handleChange(
                      "date",
                      value ? value.format("YYYY-MM-DD") : ""
                    )
                  }
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </div>

            {/* <div className="upload-area">
                <div className="or">
                  <p>or</p>
                  <div className="btn style01 md">Upload Document</div>
                </div>
              </div> */}

            <div className="input-wrap agree">
              <FormControlLabel
                value=""
                control={<Checkbox value="" />}
                label="I agree that my above information is checked with the Issuer or Official Record Holder 
                  Verify these details."
              />
            </div>
          </form>
        </div>

        <div className="modal-bottom-wrap">
          <div className="btn-wrap">
            <div className="btn style01 sm white">Confirm to Verify</div>
          </div>
        </div>
      </div>
      {/* </CustModal> */}
    </>
  );
};

export default Verify;
