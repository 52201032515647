// tools
import { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import User from "../tools/userInfo";

// ui
import { SelectChangeEvent, useMediaQuery } from "@mui/material";
import createAxiosInstance from "../api/axiosConfig";
import MetaTag from "../tools/SEOMetaTag";
import { useNavigate } from "react-router-dom";

export interface FaqProps {
  title: string;
  content: string;
}

interface FAQ {
  category: string;
  content: string;
  created_at: string;
  idx: number;
  sort: number;
  subject: string;
  type: string;
  updated_at: string;
}

const Faq = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [expanded, setExpanded] = useState<number>(0);
  const [category, setCateogory] = useState([]);
  const [menu, setMenu] = useState("");
  const [faq, setFaq] = useState<FAQ[]>([]);
  const { api } = User();

  const axiosInstance = createAxiosInstance(api);

  const toggleItem = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const handleChange =
    (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : 0);
    };

  const { search } = location;

  useEffect(() => {
    const menu = search.replace("?", "").split("=")[1]
      ? search.replace("?", "").split("=")[1]
      : "general";

    setMenu(menu);

    axiosInstance
      .get(`/support/faq/categories`)
      .then(({ data }) => setCateogory(data.data.category));

    axiosInstance
      .get(`/support/faq/categories/${menu}`)
      .then(({ data }) => setFaq(data.data.data));
  }, []);

  const TextConvert = (value: string) => {
    switch (value) {
      case "started":
        return "Getting Started";
        break;

      case "works":
        return "How It Works";
        break;

      case "general":
        return "General";
        break;
    }
  };

  const handleCate = (event: SelectChangeEvent) => {
    location.href = `/faq?tab=${event.target.value}`;
  };

  const matches = useMediaQuery("(max-width:768px)");

  return (
    <>
      <MetaTag title="FAQ" path="faq" />

      <div className="sub faq">
        <div className="sub-top-area">
          <h3 className="tit">FAQ</h3>
          <p className="txt">Questions we are often asked.</p>
        </div>

        <div className="faq-wrap">
          <ul className="faq-nav">
            {category.map((it) => (
              <li
                key={it}
                className={menu === it ? "current" : ""}
                onClick={() => (location.href = `/faq/?tab=${it}`)}
              >
                {TextConvert(it)}
              </li>
            ))}
          </ul>

          <div className="accordion">
            {faq.map((it, idx) => (
              <div
                className={openIndex === idx ? "item open" : "item"}
                key={idx}
                onClick={() => toggleItem(idx)}
              >
                <div className="tit-wrap">
                  <h4 className="tit">{it.subject}</h4>
                  <span className="material-symbols-rounded">
                    keyboard_arrow_down
                  </span>
                </div>
                <div
                  className="txt-wrap"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(it.content),
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
