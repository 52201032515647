import { Checkbox, FormControlLabel, Radio, TextField } from "@mui/material";
import { useEffect, useState } from "react";

// img
import Mastercard from "../../img/sub/mastercard-ico.svg";
import Visa from "../../img/sub/visa-ico.svg";
import PayPal from "../../img/sub/paypal-ico.svg";
import Paysafe from "../../img/sub/paysafe-ico.svg";

// type
interface PaymentProps {
  available: number;
  needPay: number;
}

const Payment = ({ available, needPay }: PaymentProps) => {
  const [picked, setPicked] = useState(false);
  const [pay, setPay] = useState<number>(needPay);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const methods = ["card", "addCard", "paypal", "paysafe"];

  const handleExpand = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? prevIndex : index));
  };

  useEffect(() => {
    setPay(needPay);
  }, [needPay]);

  return (
    <div className="payment-method">
      <div className="available-area">
        <p className="num">
          <span className="tit">Available Balance</span>
          <span className="txt"> $ {available}</span>
        </p>
        <div className="fund-wrap">
          <TextField
            label="Add Funds"
            sx={{ margin: "12px 0" }}
            value={pay === 0 ? "" : pay.toString()}
            onChange={(e) => {
              const value = e.target.value;
              // 소수점 포함 숫자 검증
              if (/^\d*\.?\d*$/.test(value)) {
                setPay(value === "" ? 0 : Number(value));
              }
            }}
          />
        </div>
        {pay > 5000 && (
          <p className="notice">The maximum recharge amount is $ 5000.</p>
        )}
      </div>

      {/* 등록된 카드가 있을때 */}
      {methods.map((method, index) => {
        return (
          <div
            key={index}
            className={`item  ${expandedIndex === index ? "open" : ""}`}
            onClick={() => handleExpand(index)}
          >
            {/* 등록된 카드 있을 때 */}
            {method === "card" && (
              <div className="card">
                <div className="top-area">
                  <Radio
                    value="a"
                    name="radio-buttons"
                    checked={expandedIndex === index}
                    readOnly
                  />
                  <img src={Visa} alt="Visa" className="icon" />
                  <p className="txt">5217 29xx xxxx x084</p>
                  <p className="expired">EXPIRED</p>
                </div>
                {expandedIndex === index && (
                  <div className="bottom-area">
                    <div className="card-info">
                      <div className="info">
                        <p className="tit">CARD HOLDER</p>
                        <p className="txt">KIM GU SONG</p>
                      </div>
                      <div className="info">
                        <p className="tit">Expries</p>
                        <p className="txt">11/24</p>
                      </div>
                    </div>
                    <div className="btn style01 md spc">Add $ 1163.00</div>
                  </div>
                )}
              </div>
            )}

            {/* 신규카드추가 */}
            {method === "addCard" && (
              <div className="add-card">
                <div className="top-area">
                  <Radio
                    value="a"
                    name="radio-buttons"
                    checked={expandedIndex === index}
                    readOnly
                  />
                  <span className="material-symbols-rounded icon">
                    add_card
                  </span>
                  <p className="txt">Add New Card</p>
                </div>
                {expandedIndex === index && (
                  <div className="bottom-area">
                    <div className="provider-wrap">
                      <div
                        className={`provider ${picked && "picked"}`}
                        onClick={() => {
                          setPicked(!picked);
                        }}
                      >
                        <img src={Visa} alt="Visa" />
                      </div>
                      <div className={`provider ${picked && "picked"}`}>
                        <img src={Mastercard} alt="Mastercard" />
                      </div>
                    </div>
                    <form>
                      <div className="input-wrap">
                        <TextField label="Card Number" fullWidth />
                      </div>

                      <div className="input-wrap">
                        <TextField
                          label="Card Holder Name"
                          placeholder="Name as it appears on your card"
                          fullWidth
                        />
                      </div>

                      <div className="input-wrap">
                        <TextField
                          label="Expiration Date"
                          placeholder="MM/YYYY"
                          fullWidth
                        />

                        <TextField label="CVV" placeholder="123" fullWidth />
                      </div>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Securely stroe for futrue use"
                      />
                    </form>
                    <div className="btn style01 md spc">Add $ 1163.00</div>
                  </div>
                )}
              </div>
            )}

            {/* 페이팔 */}
            {method === "paypal" && (
              <div className="paypal">
                <div className="top-area">
                  <Radio
                    value="a"
                    name="radio-buttons"
                    checked={expandedIndex === index}
                    readOnly
                  />
                  <img src={PayPal} alt="PayPal" className="icon" />
                  <p className="txt">Add PayPal</p>
                </div>
                {expandedIndex === index && (
                  <div className="bottom-area">
                    <div className="btn style01 md">
                      <img
                        src={PayPal}
                        alt="PayPal"
                        style={{ width: "100px" }}
                      />
                    </div>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Securely stroe for futrue use"
                    />
                  </div>
                )}
              </div>
            )}

            {/* 페이세이프티 */}
            {method === "paysafe" && (
              <div className="paysafe">
                <div className="top-area">
                  <Radio
                    value="a"
                    name="radio-buttons"
                    checked={expandedIndex === index}
                    readOnly
                  />
                  <img src={Paysafe} alt="Paysafe" className="icon" />
                  <p className="txt">Add Paysafecard</p>
                </div>
                {expandedIndex === index && (
                  <div className="bottom-area">
                    <div className="btn style01 md">
                      <img
                        src={Paysafe}
                        alt="Paysafe"
                        style={{ width: "100px" }}
                      />
                    </div>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Securely stroe for futrue use"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Payment;
