import { BallSet } from "../types/ball";

/**
 * Utility function to generate combinations of numbers.
 * @param {string[]} arr - Array of numbers as strings.
 * @param {number} selectNumber - Number of items to select.
 * @returns {string[][]} - Array of combinations.
 */

export const getCombinations = (
  arr: string[],
  selectNumber: number
): string[][] => {
  const results: string[][] = [];
  if (selectNumber === 1) return arr.map((value) => [value]);

  arr.forEach((fixed, index, origin) => {
    const rest = origin.slice(index + 1);
    const combinations = getCombinations(rest, selectNumber - 1);
    const attached = combinations.map((combination) => [fixed, ...combination]);
    results.push(...attached);
  });

  return results;
};

/**
 * Generates number combinations based on input parameters.
 * @param {string} nums - Comma-separated numbers (e.g., "8,10,14,53,64,G").
 * @param {string} code - Game type (e.g., "MM", "PB").
 * @param {string} sysCode - System code (e.g., "PIC", "GUA").
 * @returns {{ w: string[], s: string }[]} - Array of generated number objects.
 */

export const generateNumbers = (
  nums: string,
  code: string,
  sysCode: string
): { w: string[]; s: string }[] => {
  const numArray = nums.split(",");
  const special = numArray.pop()!;
  const typeChk = (target: string): "mega" | "power" =>
    target === "MM" ? "mega" : "power";
  const type = typeChk(code);

  if (sysCode === "GUA") {
    // GUA
    return Array.from({ length: BallSet[type].special }, (_, specialIdx) => ({
      w: numArray,
      s: `${specialIdx + 1}`,
    }));
  } else if (sysCode === "PIC") {
    // PIC
    const fixedNums = numArray.slice(0, 4);
    const excludedNumbers = fixedNums.map(Number);

    return Array.from({ length: BallSet[type].white }, (_, idx) => {
      const availableNumber = idx + 1;

      // EXc
      if (excludedNumbers.includes(availableNumber)) return null;

      return {
        w: [...fixedNums, `${availableNumber}`],
        s: special,
      };
    }).filter(Boolean) as { w: string[]; s: string }[];
  } else {
    // Default
    return getCombinations(numArray, 5).map((combination) => ({
      w: combination,
      s: special,
    }));
  }
};
